import React from 'react'

import { useFormik } from 'formik'

import { Button, Input } from '../../../../ui'
import { canEditProduct } from '../../../../utils/canEditProduct'
import { productQuantityScheme } from '../../../../validation/field-schemes'

import styles from './EditProductDataModal.module.scss'

import { AdminStatusType } from 'app/store'
import ProductType from 'types/product-type'

type EditProductDataModalPropsType = {
  editItem: ProductType
  editProductHandler: (editedProduct: ProductType) => void
  deleteProductHandler: () => void
  orderStatus: AdminStatusType
}
export const EditProductDataModal = ({
  editItem,
  editProductHandler,
  deleteProductHandler,
  orderStatus,
}: EditProductDataModalPropsType) => {
  const formik = useFormik({
    initialValues: {
      productLink: editItem.productLink,
      productSize: editItem.productSize,
      productColor: editItem.productColor,
      productComment: editItem.productComment ? editItem.productComment : '-',
      productQuantity: editItem.productQuantity,
    },
    validationSchema: productQuantityScheme('productQuantity', editItem.productQuantity),
    onSubmit: values => {
      if (formik.dirty) {
        // Только если продукт изменился, создайте новый объект `editedProduct`
        const editedProduct: ProductType = {
          ...editItem, // Копирование остальных полей продукта
          ...values, // Обновление измененных полей
        }

        editProductHandler(editedProduct)
      }
    },
  })

  const disabled = canEditProduct(orderStatus)

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  return (
    <form className={styles.modalBox}>
      <div className={styles.header}>редактирование товара</div>
      <div className={styles.content}>
        <div className={styles.inputList}>
          <Input
            name={'productLink'}
            value={formik.values.productLink}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'ссылка на товар'}
          />
          <Input
            name={'productSize'}
            value={formik.values.productSize}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'размер'}
            disabled={disabled}
          />
          <Input
            name={'productColor'}
            value={formik.values.productColor}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'цвет'}
            disabled={disabled}
          />
          <Input
            name={'productComment'}
            value={formik.values.productComment}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'примечание'}
          />
          <Input
            name={'productQuantity'}
            value={formik.values.productQuantity.toString()}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'количество'}
            hasError={!!formik.errors.productQuantity}
            errorText={formik.errors.productQuantity}
            disabled={disabled}
          />
        </div>

        <div className={styles.actions}>
          <Button
            disabled={!formik.dirty || !formik.isValid}
            mod="fluid"
            size="large"
            variant="default"
            type="submit"
            onClick={handleSubmit}
          >
            сохранить
          </Button>
          <Button
            mod="fluid"
            size="large"
            variant="transparent"
            type="button"
            onClick={deleteProductHandler}
            disabled={disabled}
          >
            удалить товар
          </Button>
        </div>
      </div>
    </form>
  )
}

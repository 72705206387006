import { AxiosError } from 'axios'

import { AppThunkType } from '../types'

import {
  setAnnexDocumentAC,
  setAppStatusAC,
  setFaqAC,
  setOfferAgreementAC,
  setPaymentAndRefundAC,
  setPrivacyPolicyAC,
  setRequisitesAC,
  setUserAgreementAC,
} from './actions'

import { appAPI } from 'api/appAPI/appAPI'
import { errorUtils } from 'common'

export const getPrivacyPolicyTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await appAPI.getPrivacyPolicy()

    if (response.data.statusCode === 0) {
      dispatch(setPrivacyPolicyAC(response.data))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

export const getOfferAgreementTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await appAPI.getOfferAgreement()

    if (response.data.statusCode === 0) {
      dispatch(setOfferAgreementAC(response.data))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

export const getFAQTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await appAPI.getFAQ()

    dispatch(setFaqAC(response.data))

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

export const getRequisitesTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await appAPI.getRequisites()

    if (response.data.statusCode === 0) {
      const requisites = {
        title: response.data.title,
        content: response.data.content,
      }

      dispatch(setRequisitesAC(requisites))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

export const getPaymentAndRefundTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await appAPI.getPaymentAndRefund()

    if (response.data.statusCode === 0) {
      dispatch(setPaymentAndRefundAC(response.data))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

export const getUserAgreementTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await appAPI.getUserAgreement()

    if (response.data.statusCode === 0) {
      dispatch(setUserAgreementAC(response.data))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

export const getAnnexDocumentTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await appAPI.getAnnexDocument()

    if (response.data.statusCode === 0) {
      dispatch(setAnnexDocumentAC(response.data))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

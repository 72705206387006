export enum AvailableBrandEnum {
  Farfetch = 'Farfetch',
  Yoox = 'Yoox',
  Dior = 'Dior',
  MrPorter = 'MrPorter',
  NetAPorter = 'NetAPorter',
  // PhilippPleinOutlet = 'PhilippPleinOutlet',
  Prada = 'Prada',
  // Hermes = 'hermes',
  // LouisVuitton = 'louis-vuitton',
  // Matches = 'matches',
}

export enum ShopNameEnum {
  Farfetch = 'Farfetch',
  Yoox = 'Yoox',
  Dior = 'Dior',
  MrPorter = 'Mr Porter',
  NetAPorter = 'Net-A-Porter',
  // PhilippPleinOutlet = 'Philipp Plein Outlet',
  Prada = 'Prada',
  // Hermes = 'Hermes',
  // LouisVuitton = 'Louis Vuitton',
  // Matches = 'Matches',
}

import React from 'react'

import { Skeleton } from '@mui/material'

import styles from './MobileViewOrderComponent.module.scss'

export const SkeletonMobileView = () => {
  const skeletonArray = [1, 2]

  const skeletons = skeletonArray.map((el, index) => {
    const opacity = 0.4 - index * 0.2

    const borderColor = `1px solid rgba(20, 24, 36, ${opacity * 0.15})`

    return (
      <div key={el} className={styles.orderItem} style={{ border: borderColor }}>
        <div className={styles.orderHeader}>
          <div className={styles.titleBox}>
            <div className={styles.title}>
              <Skeleton
                sx={{ opacity: opacity }}
                animation={false}
                variant="rounded"
                height={16}
                width={200}
              />
            </div>
            <div className={styles.price}>
              <Skeleton
                sx={{ opacity: opacity }}
                animation={false}
                variant="rounded"
                height={16}
                width={100}
              />
            </div>
          </div>
          <div className={styles.orderStatus}>
            <Skeleton
              sx={{ opacity: opacity }}
              animation={false}
              variant="rounded"
              height={36}
              width={120}
            />
          </div>
        </div>
        <div className={styles.orderContacts}>
          <Skeleton
            sx={{ opacity: opacity }}
            animation={false}
            variant="rounded"
            height={26}
            width={120}
          />
          <Skeleton
            sx={{ opacity: opacity }}
            animation={false}
            variant="rounded"
            height={26}
            width={120}
          />

          <Skeleton
            sx={{ opacity: opacity }}
            animation={false}
            variant="rounded"
            height={26}
            width={120}
          />
        </div>
      </div>
    )
  })

  return <>{skeletons}</>
}

export const setBearerTokenToCookie = (token: string, path: string = '/') => {
  document.cookie = `BearerToken=${token}; path=${path};` // Установите желаемые опции куки (например, срок действия).
}

export const setCookieNotification = (name: string, value: string) => {
  document.cookie = `${name}=${value}` // Установите желаемые опции куки (например, срок действия).
}

export const getTokenFromCookie = (cookieName: string) => {
  const cookies = document.cookie.split(';')

  for (const cookie of cookies) {
    const [name, value] = cookie.split('=')

    if (name.trim() === `${cookieName}`) {
      return value
    }
  }

  return null
}

export const checkTokenInCookie = (cookieName: string) => {
  const cookies = document.cookie.split(';')

  for (const cookie of cookies) {
    const [name] = cookie.split('=')

    if (name.trim() === `${cookieName}`) {
      return true
    }
  }

  return false
}

// Функция для удаления токена из куки
export const removeTokenFromCookie = (cookieName: string, path: string = '/') => {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`
}

import {
  addProductAC,
  clearCartAC,
  fetchUserAC,
  fetchUserCartAC,
  removeProductAC,
  setAddressesAC,
  setAllOrderHistoryAC,
  setAllOrdersCountAC,
  setBonusesAC,
  setCurrentOrderAC,
  setDeletedProductAC,
  setDeliveryCostAC,
  setDiscountedCartAC,
  setIsUseDiscountAC,
  setIsVerifiedEmailAC,
  setOrderingResponseAC,
  setPassportDataAC,
  setReturnedOrderAC,
  setReturnedOrdersCountAC,
  setServiceFeeAC,
  setTotalRubSumAC,
  setUserCurrentPageAC,
  setUserPagesCountAC,
  updateUserDateAC,
  userLogoutAC,
} from './actions'

export const FETCH_USER = 'user/FETCH-USER'
export const FETCH_USER_CART = 'user/FETCH-USER-CART'
export const UPDATE_USER_DATA = 'user/UPDATE-USER-DATE'
export const SET_ADDRESSES = 'user/SET-ADDRESSES'
export const ADD_PRODUCT = 'user/ADD-PRODUCT'
export const EDIT_PRODUCT = 'user/EDIT-PRODUCT'
export const REMOVE_PRODUCT = 'user/REMOVE-PRODUCT'
export const SET_ALL_ORDER_HISTORY = 'user/SET-ALL-ORDER-HISTORY'
export const SET_RETURNED_ORDER = 'user/SET-RETURNED-ORDER'
export const SET_CURRENT_PAGE = 'user/SET-CURRENT-PAGE'
export const SET_PAGES_COUNT = 'user/SET-PAGES-COUNT'
export const SET_ALL_ORDERS_COUNT = 'user/SET-ALL-ORDERS-COUNT'
export const SET_RETURNED_ORDERS_COUNT = 'user/SET-RETURNED-ORDERS-COUNT'
export const SET_CURRENT_ORDER = 'user/SET-CURRENT-ORDER'
export const SET_BONUSES = 'user/SET-BONUSES'
export const USER_LOGOUT = 'user/USER-LOGOUT'
export const SET_IS_USE_DISCOUNT = 'user/SET-IS-USE-DISCOUNT'
export const SET_DELETED_PRODUCT = 'user/SET-DELETED-PRODUCT'
export const SET_TOTAL_RUB_SUM = 'user/SET-TOTAL-RUB-SUM'
export const SET_DELIVERY_COST = 'user/SET-DELIVERY-COST'
export const SET_SERVICE_FEE = 'user/SET-SERVICE-FEE'
export const SET_DISCOUNTED_CART = 'user/SET-DISCOUNTED-CART'
export const SET_ORDERING_RESPONSE = 'user/SET-ORDERING-RESPONSE'
export const CLEAR_CART = 'user/CLEAR-CART'
export const SET_PASSPORT_DATA = 'user/SET-PASSPORT-DATA'
export const SET_IS_VERIFIED_EMAIL = 'user/SET-IS-VERIFIED-EMAIL'

export type UserReducerActionType =
  | ReturnType<typeof fetchUserAC>
  | ReturnType<typeof fetchUserCartAC>
  | ReturnType<typeof updateUserDateAC>
  | ReturnType<typeof addProductAC>
  | ReturnType<typeof removeProductAC>
  | ReturnType<typeof setAllOrderHistoryAC>
  | ReturnType<typeof setAllOrdersCountAC>
  | ReturnType<typeof setReturnedOrderAC>
  | ReturnType<typeof setReturnedOrdersCountAC>
  | ReturnType<typeof setUserCurrentPageAC>
  | ReturnType<typeof setUserPagesCountAC>
  | ReturnType<typeof setCurrentOrderAC>
  | ReturnType<typeof setBonusesAC>
  | ReturnType<typeof userLogoutAC>
  | ReturnType<typeof setIsUseDiscountAC>
  | ReturnType<typeof setDeletedProductAC>
  | ReturnType<typeof setAddressesAC>
  | ReturnType<typeof setTotalRubSumAC>
  | ReturnType<typeof setDeliveryCostAC>
  | ReturnType<typeof setServiceFeeAC>
  | ReturnType<typeof setDiscountedCartAC>
  | ReturnType<typeof setOrderingResponseAC>
  | ReturnType<typeof clearCartAC>
  | ReturnType<typeof setPassportDataAC>
  | ReturnType<typeof setIsVerifiedEmailAC>

import React, { useEffect } from 'react'

import { useDispatch } from 'react-redux'

import styles from './RightModal.module.scss'

import { ModalType, RootModalDataType, setRootModalDataAC } from 'app/store'
import { ReactComponent as CloseIcon } from 'assets/icons/svgIcons/closeIcon.svg'

type RootModalPropsType = {
  children: React.ReactNode
  modalData: RootModalDataType
  modalType: ModalType
  className?: string
  iconClassName?: string
  closeHandler?: () => void
}
export const RightModal = (props: RootModalPropsType) => {
  const { children, className, iconClassName, closeHandler, modalData, modalType } = props
  const dispatch = useDispatch()
  const closeModalHandler = () => {
    dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
    closeHandler && closeHandler()
  }

  const isOpen = modalData.modalType !== null && modalData.modalType === modalType

  const overlayClasses = `${styles.overlay} ${isOpen ? styles['overlay--active'] : ''}`

  const closeIconClasses = `${iconClassName || ''} ${
    isOpen ? `${styles.closeBox} ${styles['closeBox--active']}` : styles.closeBox
  } `

  const rightContainerStyles = `${className || ''} ${styles['container-right']} ${
    isOpen && styles['container-right--active']
  }`

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
    }

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  return (
    <>
      <div className={overlayClasses} onClick={closeModalHandler} />
      <div className={rightContainerStyles}>
        <div className={closeIconClasses} onClick={closeModalHandler}>
          <CloseIcon />
        </div>
        {children}
      </div>
    </>
  )
}

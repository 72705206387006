import React from 'react'

import '../../adminPanelComponents/CustomDataPicker/dataPickerModal.css'
// import 'react-datepicker/dist/react-datepicker.css'
import { Button } from '../../../ui'

import styles from './DataPickerModal.module.scss'

import { setRootModalDataAC } from 'app/store'
import { useAppDispatch } from 'common'
import { CustomDataPicker } from 'common/components'

type DataPickerModalPropsType = {
  fromDate: Date | null
  handleFromDateChange: (date: Date | null) => void
  toDate: Date | null
  handleToDateChange: (date: Date | null) => void
}

export const DataPickerModal = (props: DataPickerModalPropsType) => {
  const { fromDate, handleFromDateChange, toDate, handleToDateChange } = props
  const dispatch = useAppDispatch()
  const handleModalClose = () => {
    dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
  }

  return (
    <div className={styles.modalBox}>
      <div className={styles.modalUpBox}>
        <div className={styles.modalHeader}>
          <div className={styles.title}>Поиск по дате</div>
        </div>
        <div className={styles.modalBody}>
          <div className={styles.dataPickerContainer}>
            <div className={styles.text}>От</div>
            <CustomDataPicker
              selectedDate={fromDate}
              setSelectedDate={handleFromDateChange}
              position={'bottom-start'}
              popperStyles={styles.popper}
            />
          </div>
          <div className={styles.dataPickerContainer}>
            <div className={styles.text}>до</div>
            <CustomDataPicker
              selectedDate={toDate}
              setSelectedDate={handleToDateChange}
              position={'bottom-end'}
            />
          </div>
        </div>
      </div>

      <div className={styles.modalFooter}>
        <Button
          size="large"
          variant="default"
          className={styles.button}
          type={'button'}
          onClick={handleModalClose}
        >
          найти заказы
        </Button>
      </div>
    </div>
  )
}

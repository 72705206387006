import React, { useEffect, useState } from 'react'

import { useAppDispatch } from '../../../../hooks/customHooks'

import styles from './FileList.module.scss'

import { deleteFileTC, IFile } from 'app/store'
import { ReactComponent as DeleteIcon } from 'assets/icons/svgIcons/cancelOrderIcon.svg'
import { ReactComponent as ImgPlaceholder } from 'assets/icons/svgIcons/imgPlaceholder.svg'

type FileListPropsType = {
  files: IFile[]
  orderId?: number
  isEdit: boolean
}

export const FileList = (props: FileListPropsType) => {
  const { files, orderId, isEdit } = props
  const dispatch = useAppDispatch()

  const [progress, setProgress] = useState(0)
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null)

  const deleteFileHandler = (fileId: number) => {
    if (orderId) dispatch(deleteFileTC(orderId, fileId))
  }

  const openFile = (fileUrl: string) => {
    window.open(fileUrl, '_blank')
  }

  const progressBarCounter = (file: IFile) => {
    let counter = file.progress ?? 0
    const id = setInterval(
      () => {
        if (counter >= 99) {
          clearInterval(id)
        } else {
          setProgress(counter++)
        }
      },
      counter < 90 ? 30 : 300
    ) // Быстрее до 90, затем замедляется

    setIntervalId(id)
  }

  useEffect(() => {
    if (files) {
      const pendingFile = files.find(el => el.status === 'pending')

      if (pendingFile) {
        progressBarCounter(pendingFile)
      } else {
        setProgress(0) // Reset progress when all files are loaded
      }
    }
  }, [files])

  useEffect(() => {
    const successFile = files.find(el => el.status === 'success')

    if (successFile && intervalId) {
      clearInterval(intervalId)
      setIntervalId(null)
    }
  }, [files])

  return (
    <div className={styles.files}>
      {files &&
        files.map(el => (
          <div key={el.fileId} className={styles.block}>
            {el.status === 'success' ? (
              <div className={styles.item}>
                <div className={styles.description} onClick={() => openFile(el.file)}>
                  <ImgPlaceholder />
                  <span>{el.fileName}</span>
                </div>

                {isEdit ? (
                  <div className={styles.delete} onClick={() => deleteFileHandler(el.fileId)}>
                    <DeleteIcon />
                  </div>
                ) : null}
              </div>
            ) : null}
            {el.status === 'pending' ? (
              <div className={styles.progressBar}>
                <div className={styles.progressBarFill} style={{ width: `${progress}%` }} />
                <p className={styles.title}>Загрузка файла…</p>
                <div className={styles.delete} onClick={() => deleteFileHandler(el.fileId)}>
                  <DeleteIcon />
                </div>
              </div>
            ) : null}
          </div>
        ))}
    </div>
  )
}

import React, { useEffect, useRef, useState } from 'react'

import { Button } from '../../../../ui'

import styles from './SelectWarehouse.module.scss'

import { WarehouseType } from 'app/store'
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-small.svg'

type SelectWarehousePropsType = {
  warehouse: WarehouseType[]
  sendOrderToPaymentHandler: (warehouseId: number) => void
}

export const SelectWarehouse = (props: SelectWarehousePropsType) => {
  const { warehouse, sendOrderToPaymentHandler } = props
  const selectRef = useRef<HTMLDivElement | null>(null)
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseType | null>(warehouse[0])
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const openHandler = () => {
    setIsOpen(!isOpen)
  }

  const onClearHandler = () => {
    if (selectedWarehouse) sendOrderToPaymentHandler(selectedWarehouse?.id)
  }

  const onSelectHandler = (warehouse: WarehouseType) => {
    setSelectedWarehouse(warehouse)
    setIsOpen(false)
  }
  const clickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', clickOutside)

    return () => {
      document.removeEventListener('click', clickOutside)
    }
  }, [])

  return (
    <div className={styles.container} ref={selectRef}>
      <div className={styles.title}>выберете склад</div>
      <div className={styles.content}>
        <div className={styles.label}>адрес склада</div>
        <div className={styles.selectBox} onClick={openHandler}>
          <div className={styles.address}>{selectedWarehouse?.address}</div>
          <ArrowDown className={`${styles.icon} ${isOpen ? styles['is-active'] : ''}`} />
        </div>

        <div className={`${styles.menu} ${isOpen ? styles['is-active'] : ''}`}>
          {warehouse.map(warehouse => (
            <div
              className={styles.item}
              key={warehouse.id}
              onClick={() => onSelectHandler(warehouse)}
            >
              {warehouse.address}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.submit}>
        <Button size="large" variant="default" className={styles.btn} onClick={onClearHandler}>
          выбрать
        </Button>
      </div>
    </div>
  )
}

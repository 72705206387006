import { AppRootState } from '../types'

export const selectedAllOrderHistory = (state: AppRootState) => state.user.history.allOrderHistory
export const selectedAllOrderHistoryCount = (state: AppRootState) =>
  state.user.history.allOrdersCount
export const selectedReturnedOrderHistoryCount = (state: AppRootState) =>
  state.user.history.returnedOrdersCount
export const selectedReturnedOrderHistory = (state: AppRootState) =>
  state.user.history.returnedOrder
export const selectedUserCurrentPage = (state: AppRootState) => state.user.history.currentPage
export const selectedUserPageSize = (state: AppRootState) => state.user.history.pageSize
export const selectedPagesCount = (state: AppRootState) => state.user.history.pagesCount
export const selectedUserCurrentOrder = (state: AppRootState) => state.user.history.currentOrder
export const selectedBonuses = (state: AppRootState) => state.user.bonuses
export const selectedUserId = (state: AppRootState) => state.user.userId
export const selectedUserData = (state: AppRootState) => state.user.userData
export const selectedUserPassport = (state: AppRootState) => state.user.userPassport
export const selectedAddresses = (state: AppRootState) => state.user.addresses
export const selectedCart = (state: AppRootState) => state.user.cart
export const selectedViewCart = (state: AppRootState) => state.user.viewCart
export const selectedDeletedProduct = (state: AppRootState) => state.user.deletedProduct
export const selectedIsUseDiscount = (state: AppRootState) => state.user.isUseDiscount
export const selectedTotalRubSum = (state: AppRootState) => state.user.totalRubSum
export const selectedDeliveryCost = (state: AppRootState) => state.user.deliveryCost
export const selectedServiceFee = (state: AppRootState) => state.user.serviceFee
export const selectedCreatedOrderId = (state: AppRootState) => state.user.createdOrderId
export const selectedIsBlocked = (state: AppRootState) => state.user.blocked
export const selectedPaymentUrl = (state: AppRootState) => state.user.paymentUrl
export const selectedIsVerifiedEmail = (state: AppRootState) => state.user.userData.emailVerified

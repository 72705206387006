import {
  adminLogoutAC,
  changeOrderStatusAC,
  setAdminDataAC,
  setAdminSettingsAC,
  setCardsDataAC,
  setCurrentOrderAC,
  setCurrentPageAC,
  setEmployeeDataAC,
  setInPaymentOrdersAC,
  setIsFetchingAC,
  setOrderDataAC,
  setPagesCountAC,
  setPagesSizeAC,
  setProfitAC,
  setRevenueAC,
  setTotalEmployeeCountAC,
  setTotalInPaymentCountAC,
  setTotalOrdersCountAC,
  setWarehousesAC,
} from './actions'

export const SET_ADMIN_DATA = 'admin/SET-ADMIN-DATA'
export const SET_ALL_ORDER_DATA = 'admin/SET-ALL-ORDER-DATA'
export const SET_EMPLOYEE_DATA = 'admin/SET-EMPLOYEE-DATA'
export const SET_CARDS_DATA = 'admin/SET-CARDS-DATA'
export const SET_IN_PAYMENT_ORDERS = 'admin/SET-IN-PAYMENT-ORDERS'
export const SET_CURRENT_ORDER = 'admin/SET-CURRENT-ORDER'
export const CHANGE_ORDER_STATUS = 'admin/CHANGE-ORDER-STATUS'
export const SET_CURRENT_PAGE = 'admin/SET-CURRENT-PAGE'
export const SET_PAGES_COUNT = 'admin/SET-PAGES-COUNT'
export const SET_PAGE_SIZE = 'admin/SET-PAGE-SIZE'
export const SET_TOTAL_ORDERS_COUNT = 'admin/SET-TOTAL-ORDERS-COUNT'
export const SET_TOTAL_IN_PAYMENT_COUNT = 'admin/SET-TOTAL-IN-PAYMENT-COUNT'
export const SET_TOTAL_EMPLOYEE_COUNT = 'admin/SET-TOTAL-EMPLOYEE-COUNT'
export const SET_IS_FETCHING = 'admin/SET-IS-FETCHING'
export const SET_REVENUE = 'admin/SET-REVENUE'
export const SET_PROFIT = 'admin/SET-PROFIT'
export const ADMIN_LOGOUT = 'admin/ADMIN-LOGOUT'
export const SET_WAREHOUSES = 'admin/SET-WAREHOUSES'
export const SET_ADMIN_SETTINGS = 'admin/SET-ADMIN-SETTINGS'

export type AdminReducerActionType =
  | ReturnType<typeof setAdminDataAC>
  | ReturnType<typeof setOrderDataAC>
  | ReturnType<typeof setCurrentOrderAC>
  | ReturnType<typeof setInPaymentOrdersAC>
  | ReturnType<typeof setEmployeeDataAC>
  | ReturnType<typeof setCardsDataAC>
  | ReturnType<typeof changeOrderStatusAC>
  | ReturnType<typeof setTotalOrdersCountAC>
  | ReturnType<typeof setTotalEmployeeCountAC>
  | ReturnType<typeof setTotalInPaymentCountAC>
  | ReturnType<typeof setCurrentPageAC>
  | ReturnType<typeof setPagesSizeAC>
  | ReturnType<typeof setPagesCountAC>
  | ReturnType<typeof setIsFetchingAC>
  | ReturnType<typeof setRevenueAC>
  | ReturnType<typeof setProfitAC>
  | ReturnType<typeof adminLogoutAC>
  | ReturnType<typeof setWarehousesAC>
  | ReturnType<typeof setAdminSettingsAC>

import React from 'react'

import styles from './EmptyOrdersComponent.module.scss'

import boxIcon from 'assets/icons/svgIcons/boxIcon.svg'

export const EmptyOrdersComponent = ({ activeTab }: EmptyOrdersComponentPropsType) => {
  // const linkHandler = (link: string) => {
  //   window.open(link, '_blank')
  // }

  const title = activeTab === 'orders' ? 'Заказы не найдены' : 'возвраты не найдены'

  return (
    <div className={styles.container}>
      <div className={styles.emptyBox}>
        <img src={boxIcon} alt="boxIcon" />
        <span>{title}</span>
      </div>
      {/*{activeTab !== 'orders' && (*/}
      {/*  <div className={styles.linkBox}>*/}
      {/*    <Button*/}
      {/*      className={styles.button}*/}
      {/*      type={'button'}*/}
      {/*      size={'small'}*/}
      {/*      variant={'transparent'}*/}
      {/*      onClick={() => linkHandler('https://www.farfetch.com/')}*/}
      {/*    >*/}
      {/*      перейти на Farfetch*/}
      {/*    </Button>*/}
      {/*    <Button*/}
      {/*      className={styles.button}*/}
      {/*      type={'button'}*/}
      {/*      size={'small'}*/}
      {/*      variant={'transparent'}*/}
      {/*      onClick={() => linkHandler('https://www.yoox.com')}*/}
      {/*    >*/}
      {/*      перейти на Yoox*/}
      {/*    </Button>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  )
}

// types =======================================================================

type EmptyOrdersComponentPropsType = {
  activeTab: 'orders' | 'returns'
}

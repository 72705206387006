import { AxiosError } from 'axios'

import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import { AppThunkType } from '../types'

import { checkUserTC } from './thanks'

import {
  ChangePasswordRequestType,
  EditUserSettingsRequestType,
  userAccountAPI,
} from 'api/userAPI/userAccountAPI'
import { convertDateFormat, errorUtils, formatPhoneNumber } from 'common'

export const updateUserDataTC =
  (newUserData: EditUserSettingsRequestType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const payload: EditUserSettingsRequestType = {
        ...newUserData,
        userPhone: formatPhoneNumber(newUserData.userPhone, false),
      }

      if (newUserData.birthdate) {
        payload.birthdate = convertDateFormat(newUserData.birthdate, 'yyyy-mm-dd')
      }

      const response = await userAccountAPI.editUserSettings(payload)

      if (response.data.statusCode === 0) {
        dispatch(checkUserTC())
        dispatch(setAppSuccessAC({ type: 'success', title: 'данные успешно обновлены' }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: 'что то пошло не так :(' }))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const changeUserPasswordTC =
  (payload: ChangePasswordRequestType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const response = await userAccountAPI.changeUserPassword(payload)

      if (response.data.statusCode === 0) {
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))

        dispatch(setAppSuccessAC({ type: 'success', title: 'пароль успешно изменен' }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

import { ReactComponent as DiorLogo } from 'assets/icons/shop-names/dior.svg'
import { ReactComponent as FarfetchLogo } from 'assets/icons/shop-names/farfetch.svg'
import { ReactComponent as MrPorterLogo } from 'assets/icons/shop-names/mrPorter.svg'
import { ReactComponent as NetAPorterLogo } from 'assets/icons/shop-names/netAPorter.svg'
import { ReactComponent as PradaLogo } from 'assets/icons/shop-names/prada.svg'
import { ReactComponent as YooxLogo } from 'assets/icons/shop-names/yoox.svg'
import { AvailableBrandEnum } from 'types/available-brand-enum'

type Props = {
  shopName: AvailableBrandEnum
}

export const LogoSelectorRC = (props: Props) => {
  const { shopName } = props

  switch (shopName) {
    case AvailableBrandEnum.Farfetch:
      return <FarfetchLogo />
    case AvailableBrandEnum.Yoox:
      return <YooxLogo />
    case AvailableBrandEnum.Dior:
      return <DiorLogo />
    case AvailableBrandEnum.MrPorter:
      return <MrPorterLogo />
    case AvailableBrandEnum.NetAPorter:
      return <NetAPorterLogo />
    // case AvailableBrandEnum.PhilippPleinOutlet:
    //   return <PhilipPleinLogo />
    case AvailableBrandEnum.Prada:
      return <PradaLogo />
    default:
      return null
  }
}

import React from 'react'

import { useAppSelector } from '../../../hooks/customHooks'
import { Button } from '../../../ui'

import styles from './ConfirmModal.module.scss'

import { selectedAppStatus } from 'app/store/selectors/appSelectors'

type PropsType = {
  cancelHandler: () => void
  deleteHandler: () => void
  title: string
  description?: string
}

export const ConfirmModal = (props: PropsType) => {
  const { cancelHandler, deleteHandler, title, description } = props

  const appStatus = useAppSelector(selectedAppStatus)
  const cancelAction = () => {
    cancelHandler()
  }

  const deleteCard = () => {
    deleteHandler()
  }

  return (
    <div className={styles.mainContainer}>
      <div className={styles.headerBox}>
        <div className={styles.title}>{title}</div>
        {description && <div className={styles.description}>{description}</div>}
      </div>
      <div className={styles.actionsBox}>
        <Button
          size={'base'}
          variant="white"
          type="button"
          className={styles.btn}
          onClick={cancelAction}
          hasLoad={appStatus === 'loading'}
        >
          отменить
        </Button>
        <Button
          size={'base'}
          variant="default"
          type="button"
          className={styles.btn}
          onClick={deleteCard}
          hasLoad={appStatus === 'loading'}
        >
          Удалить
        </Button>
      </div>
    </div>
  )
}

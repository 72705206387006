import React, { useState } from 'react'

import * as Popper from '@popperjs/core'
import ru from 'date-fns/locale/ru'
import DatePicker, { registerLocale } from 'react-datepicker'

import styles from './CustomDataPicker.module.scss'

import 'react-datepicker/dist/react-datepicker.css'
import { ReactComponent as CalendarIcon } from 'assets/icons/svgIcons/calendarIcon.svg'

registerLocale('ru', ru)

type DataPickerModalPropsType = {
  selectedDate: Date | null
  setSelectedDate: (date: Date | null) => void
  position: Popper.Placement
  popperStyles?: string
  minDate?: Date | null
  maxDate?: Date | null
}
export const CustomDataPicker = ({
  selectedDate,
  setSelectedDate,
  position,
  popperStyles,
  minDate,
  maxDate,
}: DataPickerModalPropsType) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const dayClassName = (date: Date) => {
    const currentMonth = new Date().getMonth()
    const currentYear = new Date().getFullYear()
    const dateMonth = date.getMonth()
    const dateYear = date.getFullYear()
    const today = new Date()

    if ((minDate && date < minDate) || (maxDate && date > maxDate)) {
      return styles.disabledDay
    }

    if (!selectedDate && dateMonth === currentMonth && dateYear === currentYear && date <= today) {
      return styles.activeDay
    }

    if (selectedDate) {
      const selectedMonth = new Date(selectedDate).getMonth()
      const selectedYear = new Date(selectedDate).getFullYear()

      if (dateMonth === selectedMonth && dateYear === selectedYear && date <= today) {
        return styles.activeDay
      }
    }

    return ''
  }

  const handleInputClick = () => setIsDatePickerOpen(true)
  const handleInputBlur = () => setIsDatePickerOpen(false)

  const onChangeHandler = (date: Date) => {
    setSelectedDate(date)
    setIsDatePickerOpen(false)
  }

  const onChangeRawHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.currentTarget && e.currentTarget.value) {
      let value = e.currentTarget.value

      // удаляем все символы, кроме цифр
      value = value.replace(/[^0-9]/g, '')

      // ограничиваем длину ввода 8 символами (для формата 'ддммгггг')
      if (value.length > 8) {
        value = value.slice(0, 8)
      }

      // добавляем точки после 2-х и 4-х символов
      if (value.length > 2 && value.length <= 4) {
        value = value.slice(0, 2) + '.' + value.slice(2)
      } else if (value.length > 4) {
        value = value.slice(0, 2) + '.' + value.slice(2, 4) + '.' + value.slice(4)
      }

      e.currentTarget.value = value
    }
  }

  return (
    <div className={`${styles.container} ${isDatePickerOpen ? styles.active : ''}`}>
      <CalendarIcon />
      <DatePicker
        calendarClassName={styles.calendar}
        wrapperClassName={styles.wrapper}
        popperClassName={`${styles.popper} ${popperStyles || ''}`}
        popperPlacement={position}
        dayClassName={dayClassName}
        showPopperArrow={false}
        selected={selectedDate}
        placeholderText={'дд.мм.гггг'}
        onChangeRaw={onChangeRawHandler}
        onChange={(date: Date) => onChangeHandler(date)}
        shouldCloseOnSelect
        closeOnScroll={true}
        locale={ru}
        dateFormat="dd.MM.yyyy"
        showMonthDropdown
        scrollableMonthYearDropdown={true}
        showYearDropdown
        dropdownMode="scroll"
        onInputClick={handleInputClick}
        onCalendarClose={handleInputBlur}
        onBlur={handleInputBlur}
        maxDate={maxDate || new Date()}
        minDate={minDate || new Date(2000, 0, 1)}
      />
    </div>
  )
}

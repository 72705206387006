import { memo, ReactNode, useEffect, useRef, useState } from 'react'

import { AvailableBrandEnum } from '../../../types/available-brand-enum'
import { Badge, Button } from '../../ui'
import { formatSum } from '../../utils/format-sum'
import { LogoSelectorRC } from '../LogoSelectorRC/LogoSelectorRC'

import styles from './CartListHeader.module.scss'

import { ReactComponent as ArrowIcn } from 'assets/icons/arrow-down-small.svg'

type Props = {
  type: AvailableBrandEnum
  sumPosition?: 'default' | 'right'
  quantity: number
  totalSum: {
    rubles: number
    pounds: number
  }
  initialOpen: boolean
  children: ReactNode
  expanded: boolean
}

export const CartListHeader = memo((props: Props) => {
  const { type, quantity, totalSum, sumPosition, initialOpen, children, expanded } = props
  const [isOpen, setIsOpen] = useState(initialOpen)
  const contentEl = useRef<HTMLDivElement>(null)

  useEffect(() => {
    setIsOpen(initialOpen)
  }, [initialOpen])

  const toggleHandler = () => setIsOpen(!isOpen)

  return (
    <>
      {expanded && (
        <div className={`${styles.block} ${styles['price--position-' + sumPosition] || ''}`}>
          <div className={styles.header}>
            <div className={styles.info}>
              <div className={styles.brand}>
                <LogoSelectorRC shopName={type} />
                <Badge value={quantity} isActive={true} />
              </div>

              <div className={styles.price}>
                <span>€ {formatSum(totalSum.pounds)} /&nbsp;</span>
                <span>{formatSum(totalSum.rubles)} ₽</span>
              </div>
            </div>

            <Button
              className={`${styles['toggle-button']}`}
              onClick={toggleHandler}
              variant={'transparent'}
            >
              <ArrowIcn className={`${styles.icon} ${isOpen ? styles['is-active'] : ''}`} />
            </Button>
          </div>

          <div
            ref={contentEl}
            className={styles.content}
            style={{ maxHeight: `${isOpen ? (contentEl.current?.scrollHeight ?? 0) + 100 : 0}px` }}
          >
            {children}
          </div>
        </div>
      )}
    </>
  )
})

import React, { useEffect, useRef, useState } from 'react'

import styles from './SelectRole.module.scss'

import { UserRolesType, UsersByRoleType } from 'app/store'
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-small.svg'
import { roleHandler } from 'common'

type SelectRolePropsType = {
  role: UserRolesType
  setRole: (role: UserRolesType) => void
  userRoles: UsersByRoleType[]
}

export const SelectRole = (props: SelectRolePropsType) => {
  const { role, setRole, userRoles } = props

  const [isOpen, setIsOpen] = useState<boolean>(false)

  const selectRef = useRef<HTMLDivElement | null>(null)
  const openHandler = () => {
    setIsOpen(!isOpen)
  }

  const selectRoleHandler = (role: string) => {
    setRole(role as UserRolesType)
    setIsOpen(false)
  }

  const handleClickOutside = (event: MouseEvent) => {
    if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  return (
    <div className={styles.selectBox} ref={selectRef}>
      <div className={styles.label}>роль</div>
      <div className={styles.roleBox} onClick={openHandler}>
        <div className={styles.role}>{roleHandler(role)}</div>
        <ArrowDown className={`${styles.icon} ${isOpen ? styles['is-active'] : ''}`} />
      </div>

      <div className={`${styles.selectMenu} ${isOpen ? styles['is-active'] : ''}`}>
        {userRoles.map(el => (
          <div className={styles.item} key={el.role} onClick={() => selectRoleHandler(el.role)}>
            {roleHandler(el.role)}
          </div>
        ))}
      </div>
    </div>
  )
}

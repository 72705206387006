import { CardType, UserDataType } from '../adminReducer/types'

import {
  BUYER_LOGOUT,
  SET_CARDS_DATA,
  SET_CURRENT_ORDER,
  SET_CURRENT_PAGE,
  SET_DATA,
  SET_MY_ORDERS,
  SET_MY_ORDERS_COUNT,
  SET_ORDER_LIST,
  SET_ORDER_LIST_COUNT,
  SET_PAGE_SIZE,
  SET_PAGES_COUNT,
  SET_TOTAL_REVENUE,
} from './actions-type'
import { BuyerOrderType, MyOrdersType, OrderListType } from './types'

export const setBuyerDataAC = (payload: UserDataType) => ({ type: SET_DATA, payload } as const)
export const setOrderListAC = (payload: OrderListType[]) =>
  ({ type: SET_ORDER_LIST, payload } as const)
export const setMyOrdersAC = (payload: MyOrdersType[]) =>
  ({ type: SET_MY_ORDERS, payload } as const)
export const setOrderListCountAC = (payload: number) =>
  ({ type: SET_ORDER_LIST_COUNT, payload } as const)
export const setMyOrdersCountAC = (payload: number) =>
  ({ type: SET_MY_ORDERS_COUNT, payload } as const)
export const setBuyerCurrentPageAC = (payload: number) =>
  ({
    type: SET_CURRENT_PAGE,
    payload,
  } as const)
export const setBuyerPageSizeAC = (payload: number) => ({ type: SET_PAGE_SIZE, payload } as const)
export const setBuyerPagesCountAC = (payload: number) =>
  ({ type: SET_PAGES_COUNT, payload } as const)
export const setBuyerRevenueAC = (payload: number) =>
  ({ type: SET_TOTAL_REVENUE, payload } as const)
export const setBuyerCardsDataAC = (payload: CardType[]) =>
  ({ type: SET_CARDS_DATA, payload } as const)
export const buyerLogoutAC = () => ({ type: BUYER_LOGOUT } as const)
export const setBuyerOrderAC = (payload: BuyerOrderType | null) =>
  ({ type: SET_CURRENT_ORDER, payload } as const)

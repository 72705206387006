import React from 'react'

import { Button } from '../../../ui'

import styles from './AdminActions.module.scss'

import { AdminStatusType } from 'app/store'
import { ReactComponent as CloseIcon } from 'assets/icons/svgIcons/crossIcon.svg'

type AdminActionsPropsType = {
  returnOrderHandler: () => void
  orderStatus: AdminStatusType
}

export const AdminActions = (props: AdminActionsPropsType) => {
  const { returnOrderHandler, orderStatus } = props

  const disabled = orderStatus !== 'ForPayment'

  return (
    <Button
      className={styles.returnOrderButton}
      size="small"
      variant="white"
      type="button"
      onClick={returnOrderHandler}
      disabled={disabled}
    >
      <CloseIcon className={`${disabled ? styles.disabled : ''}`} />
      вернуть менеджеру
    </Button>
  )
}

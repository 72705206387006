import { AppRootState } from '../types'

export const selectedSearchValue = (state: AppRootState) => state.filter.searchValue
export const selectedSelectedStatus = (state: AppRootState) => state.filter.selectedStatus
export const selectedSelectedOrderId = (state: AppRootState) => state.filter.selectedOrderId
export const selectedFilterAccountStatus = (state: AppRootState) => state.filter.filterAccountStatus
export const selectedStatusForSearch = (state: AppRootState) => state.filter.statusForSearch
export const selectedSelectedAccountId = (state: AppRootState) => state.filter.selectedAccountId
export const selectedAccountIdToChange = (state: AppRootState) => state.filter.accountIdToChange
export const selectedFromDate = (state: AppRootState) => state.filter.fromDate
export const selectedToDate = (state: AppRootState) => state.filter.toDate
// export const selectedSelectedDateChanged = (state: AppRootState) => state.filter.selectedDateChanged
export const selectedGenders = (state: AppRootState) => state.filter.genders
export const selectedFilteredGender = (state: AppRootState) => state.filter.filteredGender
export const selectedAge = (state: AppRootState) => state.filter.age
export const selectedPrices = (state: AppRootState) => state.filter.prices
export const selectedUsersByCharacteristics = (state: AppRootState) =>
  state.filter.usersByCharacteristics
export const selectedFilteredUsersCharacteristic = (state: AppRootState) =>
  state.filter.filteredUsersCharacteristics
export const selectedUsersByRole = (state: AppRootState) => state.filter.usersByRole
export const selectedFilteredUserRole = (state: AppRootState) => state.filter.filteredUserRole

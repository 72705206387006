import { AxiosError } from 'axios'

import { ordersPC } from '../../../features/AdminPanel/admin/helpers'
import {
  setAdminPanelTabAC,
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import { AppThunkType } from '../types'

import {
  setCurrentOrderAC,
  setCurrentPageAC,
  setInPaymentOrdersAC,
  setOrderDataAC,
  setPagesCountAC,
  setPagesSizeAC,
  setProfitAC,
  setRevenueAC,
  setTotalInPaymentCountAC,
  setTotalOrdersCountAC,
} from './actions'
import { checkAdminAuthTC } from './thanks'
import { ChangeOrderStatusPayloadType } from './thunk-types'
import { CurrentOrderType, OrderType } from './types'

import {
  adminOrdersAPI,
  ChangeOrderStatusRequestType,
  GetOrdersRequestType,
  GetPaymentOrdersRequestType,
  ReturnOrderToManagerRequestType,
} from 'api/adminAPI/adminOrdersAPI'
import { convertDateFormat, errorUtils, formatPhoneNumber } from 'common'

export const getAllOrdersTC =
  (payload: GetOrdersRequestType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const response = await adminOrdersAPI.getOrders(payload)

      if (response.data.statusCode === 0) {
        const orders: OrderType[] = response.data.orders.data.map((order: OrderType) => {
          return {
            ...order,
            userPhone: formatPhoneNumber(order.userPhone, true),
          }
        })
        const revenue = response.data.revenue
        const profit = response.data.profit

        dispatch(setOrderDataAC(orders))
        dispatch(setTotalOrdersCountAC(response.data.orders.total))
        dispatch(setCurrentPageAC(response.data.orders.current_page))
        dispatch(setPagesCountAC(response.data.orders.last_page))
        dispatch(setPagesSizeAC(response.data.orders.per_page))
        dispatch(setRevenueAC(revenue))
        dispatch(setProfitAC(profit))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getInPaymentOrdersTC =
  (payload: GetPaymentOrdersRequestType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const response = await adminOrdersAPI.getPaymentOrders(payload)

      if (response.data.statusCode === 0) {
        const orders = response.data.paymentOrders.data.map((order: OrderType) => {
          return {
            ...order,
            userPhone: formatPhoneNumber(order.userPhone, true),
          }
        })

        dispatch(setInPaymentOrdersAC(orders))
        dispatch(setTotalInPaymentCountAC(response.data.paymentOrders.total))
        dispatch(setCurrentPageAC(response.data.paymentOrders.current_page))
        dispatch(setPagesCountAC(response.data.paymentOrders.last_page))
        dispatch(setPagesSizeAC(response.data.paymentOrders.per_page))

        dispatch(setAppStatusAC('succeeded'))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
        dispatch(setAppStatusAC('failed'))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getCurrentOrderTC =
  (orderId: number): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const response = await adminOrdersAPI.getUserCurrentOrder(orderId)

      if (response.data.statusCode === 0) {
        const currentOrder: CurrentOrderType = {
          orderId: response.data.orderId,
          orderStatus: response.data.adminStatus,
          orderDate: response.data.orderDate,
          maintenanceAdminName: response.data.maintenanceAdminName,
          // maintenanceManagerId: response.data.maintenanceManagerId,
          maintenanceManagerName: response.data.maintenanceManagerName,
          orderNumber: response.data.orderId,
          userId: response.data.userId,
          cart: response.data.cart,
          userName: response.data.userName,
          userDiscount: response.data.userDiscount,
          orderSum: response.data.orderSum, // fix to total_rub_sum
          deliveryAddress: response.data.deliveryAddress,
          userPassportData: {
            ...response.data.userPassportData,
            birthdate: convertDateFormat(response.data.userPassportData.birthdate, 'dd.mm.yyyy'),
          },
          userPhone: formatPhoneNumber(response.data.userPhone, true),
          userEmail: response.data.userEmail,
          communicationType: response.data.communicationType,
          communicationLink: response.data.communicationLink,
          paymentMethod: response.data.paymentMethod,
          discountedSum: response.data.discountedSum,
          isCallNeeded: response.data.isCallNeeded,
          trackingNumber: response.data.trackingNumber,
          deliveryServiceName: response.data.deliveryServiceName,
          deliveryMethod: response.data.deliveryMethod,
          files: response.data.files,
          trackNumbers: response.data.trackNumbers,
          payerUserRole: response.data.payerUserRole,
          payerName: response.data.payerName,
          warehouse: response.data.warehouse,
          role: response.data.payerUserRole,
          payerId: response.data.payerId,
          cancelNote: response.data.cancelNote,
          returnNote: response.data.returnNote,
          buyerRejectNote: response.data.buyerRejectNote,
        }

        dispatch(setCurrentOrderAC(currentOrder))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const changeOrderStatusTC =
  (payload: ChangeOrderStatusPayloadType): AppThunkType =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      dispatch(setAppStatusAC('loading'))
      const requestPayload: ChangeOrderStatusRequestType = {
        orderId: payload.orderId,
        adminStatus: payload.adminStatus,
      }

      if (payload.cancelNote) {
        requestPayload.cancelNote = payload.cancelNote
      }
      const response = await adminOrdersAPI.changeOrderStatus(requestPayload)

      if (response.data.statusCode === 0) {
        const values: GetOrdersRequestType = {
          page: state.admin.currentPage,
          pageSize: state.admin.pageSize,
        }

        dispatch(
          getAllOrdersTC(
            ordersPC(
              values,
              payload.params?.fromDate,
              payload.params?.toDate,
              payload.params?.statusForSearch,
              payload.params?.searchValue,
              payload.params?.prices,
              payload.params?.gender
            )
          )
        )
        dispatch(setAppSuccessAC({ type: 'success', title: 'статус заказа изменен' }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const editOrderTC =
  (value: CurrentOrderType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const response = await adminOrdersAPI.editOrder(value)

      if (response.data.statusCode === 0) {
        dispatch(getCurrentOrderTC(value.orderId))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        dispatch(setAppSuccessAC({ type: 'success', title: 'данные изменены' }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const returnOrderToManagerTC =
  (value: ReturnOrderToManagerRequestType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const response = await adminOrdersAPI.returnOrderToManager(value)

      if (response.data.statusCode === 0) {
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        dispatch(setAppSuccessAC({ type: 'success', title: 'заказ возвращен менеджеру' }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const sentOrderForPaymentTC =
  (orderId: number, warehouseId: number): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const response = await adminOrdersAPI.sendOrderForPayment(orderId, warehouseId)

      if (response.data.statusCode === 0) {
        dispatch(setAdminPanelTabAC('orders'))
        dispatch(setAppSuccessAC({ type: 'success', title: 'заказ отправлен на оплату' }))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

import React, { useEffect } from 'react'

import styles from './TopModal.module.scss'

import { ReactComponent as CloseIcon } from 'assets/icons/svgIcons/closeIcon.svg'

type TopModalPropsType = {
  children: React.ReactNode
  isOpen: boolean
  className?: string
  iconClassName?: string
  closeHandler?: () => void
}
export const TopModal = (props: TopModalPropsType) => {
  const { isOpen, iconClassName, closeHandler, className, children } = props

  const overlayClasses = `${styles.overlay} ${isOpen ? styles['overlay--active'] : ''}`

  const closeBoxStyles = `${iconClassName || ''} ${
    isOpen ? `${styles.closeBox} ${styles['closeBox--active']}` : styles.closeBox
  } `

  const containerStyles = `${className || ''} ${styles['container-top']} ${
    isOpen && styles['container-top--active']
  }`

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
    }

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  return (
    <>
      <div className={overlayClasses} onClick={closeHandler} />
      <div className={containerStyles}>
        <div className={closeBoxStyles} onClick={closeHandler}>
          <CloseIcon />
        </div>
        {isOpen && <>{children}</>}
      </div>
    </>
  )
}

import React from 'react'

import styles from './order-info.module.scss'

import { ReactComponent as WarehouseIcon } from 'assets/icons/svgIcons/warehouseIcon.svg'

type WarehouseComponentPropsType = {
  address: string
}
export const WarehouseComponent = (props: WarehouseComponentPropsType) => {
  const { address } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <WarehouseIcon />
        <span>склад</span>
      </div>
      <div className={styles.itemDescription}>
        <span>{address}</span>
      </div>
    </div>
  )
}

import React, { useRef } from 'react'

import { useFormik } from 'formik'

import { useAppDispatch } from '../../../../hooks/customHooks'
import { Button, Input, PasswordInput } from '../../../../ui'

import styles from './AuthorizationModalsStyle.module.scss'

import { ModalType, setRootModalDataAC, userLoginTC } from 'app/store'
import { getValidScheme } from 'common'

export type LoginModalPropsType = {
  page: 'main' | 'ordering'
  modalType: ModalType
}
export const LoginModal = ({ page, modalType }: LoginModalPropsType) => {
  const dispatch = useAppDispatch()
  const inputRef = useRef<HTMLInputElement>(null)

  const formik = useFormik({
    initialValues: {
      userEmail: '',
      userPassword: '',
    },
    validationSchema: getValidScheme(modalType),
    onSubmit: values => {
      dispatch(userLoginTC(values))
    },
  })

  const handleSubmit = () => {
    formik.handleSubmit()
  }
  let disabledButton =
    !formik.isValid ||
    formik.values.userEmail.length === 0 ||
    formik.values.userPassword.length === 0

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.loginForm}>
        <form className={styles.modalBox} autoComplete="off">
          <div className={styles.header}>
            {page === 'main' ? (
              <b>вход в личный кабинет</b>
            ) : (
              <b>Войдите в личный кабинет, чтобы оформить заказ</b>
            )}
          </div>
          <div className={styles.content}>
            <form className={styles.form} autoComplete="off">
              <Input
                ref={inputRef}
                name={'userEmail'}
                size={'base'}
                variant={'default'}
                label={'почта'}
                type={'email'}
                autoComplete={'off'}
                value={formik.values.userEmail}
                onChange={formik.handleChange}
                hasError={!!formik.errors.userEmail && formik.touched.userEmail}
                errorText={formik.errors.userEmail}
                onKeyDown={e => onKeyDown(e)}
                setFormikFieldValue={formik.setFieldTouched}
              />

              <PasswordInput
                name={'userPassword'}
                value={formik.values.userPassword}
                label={'пароль'}
                onChange={formik.handleChange}
                hasError={!!formik.errors.userPassword && formik.touched.userPassword}
                errorText={formik.errors.userPassword}
                onKeyDown={e => onKeyDown(e)}
                setFormikFieldValue={formik.setFieldTouched}
                autoComplete={'new-password'}
              />
            </form>

            <div className={styles.submitBtn}>
              <Button
                mod="fluid"
                size="large"
                variant="default"
                type="submit"
                onClick={handleSubmit}
                disabled={disabledButton}
              >
                войти
              </Button>

              <Button
                mod="fluid"
                size="large"
                variant="white"
                type="button"
                onClick={() =>
                  dispatch(
                    setRootModalDataAC({
                      isOpen: true,
                      modalType: 'registration',
                      direction: 'right',
                    })
                  )
                }
              >
                создать новый аккаунт
              </Button>

              <Button
                mod="fluid"
                size="large"
                variant="transparent"
                type="button"
                onClick={() =>
                  dispatch(
                    setRootModalDataAC({
                      isOpen: true,
                      modalType: 'passwordRecovery',
                      direction: 'right',
                    })
                  )
                }
              >
                забыли пароль?
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

import React from 'react'

import styles from './OrderStatusHandler.module.scss'

import { RootStatusType } from 'app/store'
import { statusIdentifier } from 'common'

type OrderStatusChangerPropsType = {
  status: RootStatusType
  orderId?: number
  selectedOrderId?: number | null
  className?: string
}

export const OrderStatusHandler = ({
  status,
  orderId,
  selectedOrderId,
  className,
}: OrderStatusChangerPropsType) => {
  const currentStatus = statusIdentifier(status)

  let isActive = false

  if (orderId && selectedOrderId) {
    isActive = orderId === selectedOrderId
  }

  const orderStatusBoxClassName = `${styles.orderStatusBox} ${className} ${
    isActive ? styles.active : ''
  }`

  return (
    <div className={orderStatusBoxClassName}>
      <div className={styles.statusColorBox} style={{ background: currentStatus.background }} />
      <span>{currentStatus.name}</span>
    </div>
  )
}

import React from 'react'

import styles from './GoodsCounter.module.scss'

export const GoodsCounter = ({ extraCount }: GoodsCounterPropsType) => {
  return (
    <div className={styles.countBox}>
      <span>+{extraCount}</span>
    </div>
  )
}

// types =======================================================================
type GoodsCounterPropsType = {
  extraCount: number
}

import React from 'react'

import { MessengerHandler } from '../../MessengerHandler/MessengerHandler'

import styles from './order-info.module.scss'

import { CurrentOrderType } from 'app/store'
import { ReactComponent as EmailIcon } from 'assets/icons/svgIcons/emailIcon.svg'
import { ReactComponent as MessageIcon } from 'assets/icons/svgIcons/message.svg'

type CommunicationComponentPropsType = {
  orderData: CurrentOrderType | null
}
export const CommunicationComponent = (props: CommunicationComponentPropsType) => {
  const { orderData } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <div className={styles.titleIcon}>
          <MessageIcon />
        </div>

        <span>
          предпочтительный <br />
          способ связи
        </span>
      </div>
      <div className={styles.itemDescription}>
        {(orderData?.communicationType === 'Telegram' ||
          orderData?.communicationType === 'Whatsapp') &&
        orderData.communicationLink !== '' ? (
          <MessengerHandler
            communicationNickName={orderData?.communicationLink}
            communicationType={orderData?.communicationType}
          />
        ) : (
          <div className={styles.contactItem}>
            <div className={styles.icon}>
              <EmailIcon />
            </div>

            <span>{orderData?.communicationLink}</span>
          </div>
        )}
      </div>
    </div>
  )
}

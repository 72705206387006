import React, { MouseEvent } from 'react'

import { useAppDispatch } from '../../../../hooks/customHooks'
import { Button } from '../../../../ui'

import styles from './FiltersModal.module.scss'

import { AdminPanelTabsType, RangeType, setRootModalDataAC } from 'app/store'
import { ReactComponent as ManagerIcon } from 'assets/icons/svgIcons/managerIcon.svg'
import { DataPickerFilter, FilterComponent } from 'common/components'
import { PriceRangeFilter } from 'features/AdminPanel'
import { PopupCheckBoxType, PopupDataType } from 'types/popup-types'

type FiltersModalsPropsType = {
  openCheckboxHandler: (e: MouseEvent<HTMLDivElement>, type: PopupCheckBoxType) => void
  fromDate: Date | null
  toDate: Date | null
  handleFromDateChange: (date: Date | null) => void
  handleToDateChange: (date: Date | null) => void
  resetAllFilters: () => void
  filteredPrices: RangeType | null
  filterPriceHandler: (prices: RangeType) => void
  popupData: PopupDataType
  activeTab: AdminPanelTabsType
}
export const FiltersModal = (props: FiltersModalsPropsType) => {
  const {
    fromDate,
    toDate,
    handleFromDateChange,
    handleToDateChange,
    openCheckboxHandler,
    resetAllFilters,
    filteredPrices,
    filterPriceHandler,
    popupData,
    activeTab,
  } = props
  const dispatch = useAppDispatch()

  const resetHandler = () => {
    resetAllFilters()
    dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
  }
  const confirmHandler = () => {
    dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.box}>
          <div className={styles.header}>Фильтры</div>
          <div className={styles.content}>
            <div className={styles.item}>
              <span className={styles.span}>Дата</span>
              <DataPickerFilter
                toDate={toDate}
                fromDate={fromDate}
                handleFromDateChange={handleFromDateChange}
                handleToDateChange={handleToDateChange}
              />
            </div>
            <div className={styles.item}>
              <span className={styles.span}>сумма</span>
              <div className={styles.price}>
                <PriceRangeFilter
                  filteredPrices={filteredPrices}
                  filterPriceHandler={filterPriceHandler}
                />
              </div>
            </div>
            {activeTab === 'stuff' && (
              <div className={styles.item}>
                <FilterComponent
                  openCheckboxHandler={openCheckboxHandler}
                  isActive={popupData.isOpen && popupData.type === 'users'}
                  title={'тип пользователя'}
                  type={'users'}
                  icon={<ManagerIcon />}
                  className={styles.filterItem}
                />
              </div>
            )}
            <div className={styles.item}>
              <FilterComponent
                openCheckboxHandler={openCheckboxHandler}
                isActive={popupData.isOpen && popupData.type === 'gender'}
                title={'пол'}
                type={'gender'}
                className={styles.filterItem}
              />
            </div>
            {activeTab === 'stuff' && (
              <div className={styles.item}>
                <FilterComponent
                  openCheckboxHandler={openCheckboxHandler}
                  isActive={popupData.isOpen && popupData.type === 'ages'}
                  title={'возраст'}
                  type={'ages'}
                  className={styles.filterItem}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.footer}>
          <Button size={'base'} variant="white" className={styles.button} onClick={resetHandler}>
            сбросить
          </Button>
          <Button
            size={'base'}
            variant="default"
            className={styles.button}
            onClick={confirmHandler}
          >
            применить
          </Button>
        </div>
      </div>
    </>
  )
}

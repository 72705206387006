import React, { useEffect } from 'react'

import { Link, useNavigate } from 'react-router-dom'

import { useAppDispatch } from '../../../../hooks/customHooks'
import { JumboVideo } from '../../../../sections'
import { Button } from '../../../../ui'
import { scrollToSection, SectionId } from '../../../../utils/scrollToSection'

import styles from './BurgerModal.module.scss'

import { PATH } from 'app/routes/AppRoutes'
import { setRootModalDataAC, setUserAccountTabAC } from 'app/store'
import { ReactComponent as CloseIcn } from 'assets/icons/close-aside.svg'
import { ReactComponent as LogoIcon } from 'assets/icons/svgIcons/oh-really-logo.svg'

type BurgerModalPropsType = {
  isOpen: boolean
  closeBurgerHandler: () => void
  isLoggedIn: boolean
  goToBonusPageHandler: () => void
  openLoginModalHandler: () => void
  scrollToSection: (sectionId: SectionId, isBurger: boolean, yOffset: number) => void
  logoutHandler: () => void
  location: string
  isUserAccountPage: boolean
}

export const BurgerModal = (props: BurgerModalPropsType) => {
  const {
    isOpen,
    closeBurgerHandler,
    isLoggedIn,
    goToBonusPageHandler,
    openLoginModalHandler,
    logoutHandler,
    location,
    isUserAccountPage,
  } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const loginHandler = () => {
    closeBurgerHandler()
    openLoginModalHandler()
  }
  const goToAccountHandler = () => {
    closeBurgerHandler()
    dispatch(setUserAccountTabAC('orderHistory'))
    navigate(PATH.USER_ACCOUNT_HISTORY)
  }
  const registrationHandler = () => {
    closeBurgerHandler()
    dispatch(
      setRootModalDataAC({
        isOpen: true,
        modalType: 'registration',
        direction: 'right',
      })
    )
  }

  const clickHandler = (sectionId: SectionId) => {
    const windowWidth = window.innerWidth
    // eslint-disable-next-line no-nested-ternary
    const yOffset = windowWidth <= 576 ? -64 : windowWidth <= 768 ? -72 : -98

    if (isUserAccountPage) {
      scrollToSection(sectionId, navigate, location, yOffset)
    }

    closeBurgerHandler()
    scrollToSection(sectionId, navigate, location, yOffset)
  }

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('modal-open')
    }

    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen])

  return (
    <div className={`${styles.wrapper} ${isOpen ? styles['is-open'] : ''}`}>
      {/*<div className={styles.bg} onClick={closeBurgerHandler} />*/}
      <div className={styles.block}>
        <JumboVideo />
        <div className={styles.header}>
          <Link to={PATH.MAIN} className={styles.logo}>
            <LogoIcon className={styles.logoImg} />
          </Link>
          <button className={styles.close} onClick={closeBurgerHandler}>
            <CloseIcn className={styles.closeIcn} />
          </button>
        </div>

        <div className={styles.body}>
          <menu className={styles.burgerMenu}>
            <li className={styles.menuItem}>
              <Link
                className={styles.menuEl}
                to={PATH.MAIN}
                onClick={e => {
                  e.preventDefault()

                  clickHandler(SectionId.FAQ)
                }}
              >
                вопрос-ответ
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link
                className={styles.menuEl}
                to={PATH.MAIN}
                onClick={e => {
                  e.preventDefault()
                  clickHandler(SectionId.CONTACTS)
                }}
              >
                контакты
              </Link>
            </li>
            {isLoggedIn && (
              <li className={styles.menuItem} onClick={goToBonusPageHandler}>
                <span className={styles.menuEl}>привести друга</span>
              </li>
            )}
          </menu>
          {isLoggedIn ? (
            <Button
              size={'base'}
              variant={'white'}
              className={styles.modalBtn}
              onClick={isUserAccountPage ? logoutHandler : goToAccountHandler}
            >
              {isUserAccountPage ? 'выйти' : 'личный кабинет'}
            </Button>
          ) : (
            <div className={styles.btnBox}>
              <Button
                size={'base'}
                variant={'default'}
                className={styles.modalBtn}
                onClick={loginHandler}
              >
                войти
              </Button>
              <Button
                size="base"
                variant="white"
                type="button"
                className={styles.modalBtn}
                onClick={registrationHandler}
              >
                создать новый аккаунт
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

import React from 'react'

import styles from './LogoComponent.module.scss'

import { ReactComponent as Logo } from 'assets/icons/svgIcons/oh-really-logo.svg'

type LogoPropsType = {
  style?: React.CSSProperties
}

export const LogoComponent = ({ style }: LogoPropsType) => {
  return (
    <div className={styles.logoBox} style={style}>
      <Logo className={styles.logo} />
    </div>
  )
}

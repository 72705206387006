import React, { lazy, Suspense } from 'react'

import { Navigate, Outlet, Route, Routes, useParams } from 'react-router-dom'

import { checkTokenInCookie } from '../../api/cookieWorkers'
import PrivacyPolicy from '../../features/additionalPages/PrivacyPolicy'

const MainAsync = lazy(() => import('features/Main/Main'))
const MyCartAsync = lazy(() => import('features/MyCart/MyCart'))
const OrderingAsync = lazy(() => import('features/Ordering/Ordering'))
const PasswordRecoveryAsync = lazy(() => import('features/PasswordRecovery/PasswordRecovery'))
// const UserAccountAsync = lazy(() => import('features/UserAccount/UserAccount'))
const AdminAuthAsync = lazy(() => import('features/AdminAuth/AdminAuth'))
const AdminPanelAsync = lazy(() => import('features/AdminPanel/AdminPanel'))
const AdminOrderDetailsAsync = lazy(
  () => import('features/AdminPanel/admin/AdminOrderDetails/AdminOrderDetails')
)
const NotFoundAsync = lazy(() => import('features/additionalPages/NotFound/NotFound'))
const OfferPageAsync = lazy(() => import('features/additionalPages/OfferPage'))
// const RequisitesAsync = lazy(() => import('features/additionalPages/Requisites'))
const PaymentAndRefundAsync = lazy(() => import('features/additionalPages/PaymentAndRefund'))
const UserAgreementAsync = lazy(() => import('features/additionalPages/UserAgreement'))
const OrderDetailsAsync = lazy(() => import('features/AdminPanel/buyer/OrderDetails/OrderDetails'))
const BlockedAccountAsync = lazy(
  () => import('features/additionalPages/BlockedAccount/BlockedAccount')
)
const AdminWarehouseAsync = lazy(() => import('features/AdminPanel/admin/Warehouse/Warehouse'))
const OrderCreateFailedAsync = lazy(
  () => import('features/Ordering/OrderingCompleted/OrderCreateFailed')
)
const OrderCreatedSuccessfulAsync = lazy(
  () => import('features/Ordering/OrderingCompleted/OrderCreatedSuccessful')
)
const UserOrderDetailsAsync = lazy(
  () => import('features/UserAccount/UserOrderDetails/UserOrderDetails')
)
const UserOrderHistoryAsync = lazy(() => import('features/UserAccount/OrderHistory/OrderHistory'))
const UserAccountBonusesAsync = lazy(() => import('features/UserAccount/Bonuses/Bonuses'))
const UserAccountAddressesAsync = lazy(() => import('features/UserAccount/MyAddresses/MyAddresses'))
const UserAccountPassportAsync = lazy(() => import('features/UserAccount/MyPassport/MyPassport'))
const UserAccountSettingsAsync = lazy(() => import('features/UserAccount/Settings/Settings'))
const AdminPanelAddCardAsync = lazy(() => import('features/AdminPanel/CardsTab/AddCard/AddCard'))
const SettingsAsync = lazy(() => import('features/AdminPanel/admin/Settings/Settings'))
const EmailVerifyAsync = lazy(() => import('features/additionalPages/email-verify/EmailVerify'))

export const PATH = {
  MAIN: '/',
  INVITE_LINK: '/invite-link/:token',
  FAQ: '/faq',
  CONTACTS: '/contacts',
  MY_CART: '/my-cart',
  ORDERING: '/ordering',
  ORDERING_SUCCESSFUL: '/ordering/successful',
  ORDERING_FAILED: '/ordering/failed',
  USER_ACCOUNT: '/user-account',
  USER_ACCOUNT_ORDER_DETAILS: '/user-account/history/:orderId',
  USER_ACCOUNT_HISTORY: '/user-account/history',
  USER_ACCOUNT_BONUSES: '/user-account/bonuses',
  USER_ACCOUNT_ADDRESSES: '/user-account/addresses',
  USER_ACCOUNT_PASSPORT: '/user-account/passport',
  USER_ACCOUNT_SETTINGS: '/user-account/settings',
  PASSWORD_RECOVERY: '/password-recovery/:token',
  // ADMIN_AUTH: '/admin-auth',
  ADMIN_PANEL: '/admin-panel',
  ADMIN_PANEL_ROLE: '/admin-panel/:role',
  ADD_CARD_SUCCESS: '/admin-panel/add-card/successful',
  ADD_CARD_FAILED: '/admin-panel/add-card/failed',
  ADMIN_PANEL_ADMIN: '/admin-panel/admin',
  ADMIN_PANEL_ADMIN_BUYER_ORDER: '/admin-panel/admin/buyer',
  ADMIN_PANEL_MANAGER: '/admin-panel/manager',
  ADMIN_PANEL_BUYER: '/admin-panel/buyer',
  ADMIN_PANEL_BLOCKED_ACCOUNT: '/admin-panel/buyer/blocked-account',
  ADMIN_ORDER_DETAILS: '/:orderId',
  ADMIN_WAREHOUSE: '/admin-panel/admin/warehouse',
  ADMIN_SETTINGS: '/admin-panel/admin/settings',
  NOT_FOUND: '/not-found',
  PRIVACY_POLICY: '/privacy-policy',
  PUBLIC_OFFER: '/public-offer',
  // REQUISITES: '/requisites',
  PAYMENT_AND_REFUND: '/payment-and-refund',
  USER_AGREEMENT: '/user-agreement',
  EMAIL_VERIFY: '/email/verify',
}

export type PathType = (typeof PATH)[keyof typeof PATH]

const RequireToken = () => {
  let { token } = useParams()

  return token ? <Outlet /> : <Navigate to={PATH.MAIN} />
}

const RequireUserAuth = () => {
  if (checkTokenInCookie('BearerToken')) {
    return <Outlet />
  }

  return <Navigate to={PATH.MAIN} />
}

const RequireAuth = () => {
  if (checkTokenInCookie('BearerToken')) {
    return <Outlet />
  }

  return <Navigate to={PATH.ADMIN_PANEL} />
}

export const AppRoutes = () => {
  return (
    <Suspense fallback={<></>}>
      <Routes>
        <Route path={PATH.MAIN} element={<MainAsync />} />
        <Route path={PATH.INVITE_LINK} element={<MainAsync />} />
        <Route path={PATH.MY_CART} element={<MyCartAsync />} />
        <Route path={PATH.ORDERING} element={<OrderingAsync />} />
        <Route path={PATH.EMAIL_VERIFY} element={<EmailVerifyAsync />} />
        <Route element={<RequireToken />}>
          <Route path={PATH.PASSWORD_RECOVERY} element={<PasswordRecoveryAsync />} />
        </Route>
        <Route element={<RequireUserAuth />}>
          {/*<Route index path={PATH.USER_ACCOUNT} element={<UserAccountAsync />} />*/}
          <Route index path={PATH.USER_ACCOUNT_HISTORY} element={<UserOrderHistoryAsync />} />
          <Route index path={PATH.USER_ACCOUNT_BONUSES} element={<UserAccountBonusesAsync />} />
          <Route index path={PATH.USER_ACCOUNT_ORDER_DETAILS} element={<UserOrderDetailsAsync />} />
          <Route index path={PATH.USER_ACCOUNT_ADDRESSES} element={<UserAccountAddressesAsync />} />
          <Route index path={PATH.USER_ACCOUNT_PASSPORT} element={<UserAccountPassportAsync />} />
          <Route index path={PATH.USER_ACCOUNT_SETTINGS} element={<UserAccountSettingsAsync />} />
        </Route>
        <Route index path={PATH.ORDERING_FAILED} element={<OrderCreateFailedAsync />} />
        <Route index path={PATH.ORDERING_SUCCESSFUL} element={<OrderCreatedSuccessfulAsync />} />
        <Route path={PATH.ADMIN_PANEL} element={<AdminAuthAsync />} />
        <Route element={<RequireAuth />}>
          <Route path={PATH.ADMIN_PANEL_ROLE} element={<AdminPanelAsync />} />
          <Route
            path={PATH.ADMIN_PANEL_ADMIN + PATH.ADMIN_ORDER_DETAILS}
            element={<AdminOrderDetailsAsync />}
          />
          <Route
            path={PATH.ADMIN_PANEL_ADMIN_BUYER_ORDER + PATH.ADMIN_ORDER_DETAILS}
            element={<OrderDetailsAsync />}
          />
          <Route
            path={PATH.ADMIN_PANEL_MANAGER + PATH.ADMIN_ORDER_DETAILS}
            element={<AdminOrderDetailsAsync />}
          />
          <Route
            path={PATH.ADMIN_PANEL_BUYER + PATH.ADMIN_ORDER_DETAILS}
            element={<OrderDetailsAsync />}
          />
          <Route path={PATH.ADMIN_WAREHOUSE} element={<AdminWarehouseAsync />} />
          <Route path={PATH.ADMIN_SETTINGS} element={<SettingsAsync />} />
          <Route path={PATH.ADD_CARD_SUCCESS} element={<AdminPanelAddCardAsync />} />
          <Route path={PATH.ADD_CARD_FAILED} element={<AdminPanelAddCardAsync />} />
        </Route>
        <Route path={PATH.NOT_FOUND} element={<NotFoundAsync />} />
        <Route path={PATH.PRIVACY_POLICY} element={<PrivacyPolicy />} />
        <Route path={PATH.PUBLIC_OFFER} element={<OfferPageAsync />} />
        {/* <Route path={PATH.REQUISITES} element={<RequisitesAsync />} /> */}
        <Route path={PATH.PAYMENT_AND_REFUND} element={<PaymentAndRefundAsync />} />
        <Route path={PATH.USER_AGREEMENT} element={<UserAgreementAsync />} />
        <Route path={PATH.ADMIN_PANEL_BLOCKED_ACCOUNT} element={<BlockedAccountAsync />} />
        <Route path={'*'} element={<Navigate to={PATH.NOT_FOUND} />} />
      </Routes>
    </Suspense>
  )
}

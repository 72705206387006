import axios from 'axios'

import { getTokenFromCookie } from './cookieWorkers'

export const instance = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  withCredentials: true,
  headers: {
    Accept: 'application/json',
  },
})

instance.interceptors.request.use(config => {
  const authToken = getTokenFromCookie('BearerToken')

  if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`
  }

  return config
})

import React from 'react'

import { useFormik } from 'formik'
import * as Yup from 'yup'

import { useAppDispatch } from '../../../../hooks/customHooks'
import { Button, Input } from '../../../../ui'

import styles from './WarehouseModal.module.scss'

import {
  addWarehouseTC,
  deleteWarehouseTC,
  editWarehouseTC,
  ModalType,
  WarehouseType,
} from 'app/store'

type WarehouseModalPropsType = {
  selectedWarehouse?: WarehouseType | null
  modalType: ModalType
}
export const WarehouseModal = (props: WarehouseModalPropsType) => {
  const { selectedWarehouse, modalType } = props
  const dispatch = useAppDispatch()

  const isEdit = modalType === 'editWarehouse'
  const modalTitle = isEdit ? 'Редактировать адрес' : 'Добавить адрес'

  const formik = useFormik({
    initialValues: {
      address: isEdit && selectedWarehouse ? selectedWarehouse.address : '',
      numbers: isEdit && selectedWarehouse ? selectedWarehouse.numbers : [],
    },
    validationSchema: Yup.object().shape({
      address: Yup.string().required('обязательное поле'),
      numbers: Yup.array().of(
        Yup.object().shape({
          telNum: Yup.string().required('обязательное поле'),
        })
      ),
    }),
    onSubmit: values => {
      if (isEdit && selectedWarehouse) {
        dispatch(editWarehouseTC({ ...values, id: selectedWarehouse?.id }))
      } else {
        dispatch(addWarehouseTC(values))
      }
    },
  })

  const disableAddPhoneBtn = formik.values.numbers.find(item => item.telNum === '')
  const isDisabledEditBtn =
    formik.values.address === selectedWarehouse?.address &&
    formik.values.numbers === selectedWarehouse?.numbers
  const isDisabledAddBtn = !formik.isValid || !formik.dirty

  const handleSubmit = () => {
    formik.handleSubmit()
  }
  const addPhoneHandler = () => {
    const newNumber = { telId: null, telNum: '' }

    formik.setFieldValue('numbers', [...formik.values.numbers, newNumber])
  }
  const clearPhoneHandler = (index: number) => {
    const numbers = formik.values.numbers.filter((_, i) => i !== index)

    formik.setFieldValue('numbers', numbers)
  }
  const deleteHandler = () => {
    if (selectedWarehouse) dispatch(deleteWarehouseTC(selectedWarehouse.id))
  }

  return (
    <div className={styles.box}>
      <div className={styles.title}>{modalTitle}</div>
      <div className={styles.content}>
        <form className={styles.form}>
          <div className={styles.inputBox}>
            <Input
              value={formik.values.address}
              onChange={formik.handleChange}
              name={'address'}
              size="large"
              variant="default"
              type={'text'}
              label={'город, улица и дом'}
              hasError={!!formik.errors.address}
              errorText={formik.errors.address}
            />

            {formik.values.numbers.map((item, index) => (
              <Input
                key={index}
                value={item.telNum}
                onChange={formik.handleChange}
                name={`numbers[${index}].telNum`}
                size="large"
                variant="default"
                type={'phone'}
                label={'телефон'}
                mask={'+_ (___) ___-__-__'}
                hasClear={true}
                clearStyle="transparent"
                onClear={() => clearPhoneHandler(index)}
              />
            ))}
            <Button
              size="small"
              variant="transparent"
              type="button"
              className={styles.addPhoneBtn}
              onClick={addPhoneHandler}
              disabled={!!disableAddPhoneBtn}
            >
              + добавить еще телефон
            </Button>
          </div>
          <div className={styles.btnBox}>
            <Button
              mod="fluid"
              size="large"
              variant="default"
              type={'submit'}
              onClick={handleSubmit}
              disabled={modalType === 'editUser' ? isDisabledEditBtn : isDisabledAddBtn}
            >
              {isEdit ? 'сохранить изменения' : 'добавить адрес'}
            </Button>
            {isEdit && (
              <Button
                className={styles.deleteBtn}
                mod="fluid"
                size="large"
                variant="transparent"
                type="button"
                onClick={deleteHandler}
              >
                удалить адрес
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'

import { Button } from '../../ui'
import { getCookieTime, removeCookieTime, setCookieTime } from '../../utils/cartLifeTimeHandlers'

import styles from './ConfirmEmail.module.scss'

import { sendConfirmationEmailTC } from 'app/store'
import { AppThunkDispatch } from 'app/store/types'

type ConfirmEmailPropsType = {
  dispatch: AppThunkDispatch
}

export const ConfirmEmail = (props: ConfirmEmailPropsType) => {
  const { dispatch } = props

  const [timer, setTimer] = useState<number | null>(null)

  const minutes = timer ? Math.floor(timer / 60) : 0
  const seconds = timer ? timer % 60 : 0

  const handleClick = () => {
    dispatch(sendConfirmationEmailTC())
    const currentTime = Date.now()

    setCookieTime('timer', currentTime.toString(), 1 / 720) // сохраняем текущее время в куки на 2 минуты
    setTimer(119) // устанавливаем время таймера в 2 минуты (120 секунд)
  }

  useEffect(() => {
    if (timer && timer > 0) {
      const timerId = setTimeout(() => {
        setTimer(timer - 1)
      }, 1000)

      return () => clearTimeout(timerId)
    } else if (timer === 0) {
      removeCookieTime('timer') // удаляем данные из кук по истечении 2 минут
      setTimer(null)
    }
  }, [timer])

  useEffect(() => {
    const cookieTime = getCookieTime('timer') // проверяем куки на наличие таймера при первой загрузке

    if (cookieTime) {
      const remainingTime = 120 - Math.floor((Date.now() - Number(cookieTime)) / 1000)

      setTimer(remainingTime > 0 ? remainingTime : null)
    }
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>Требуется подтверждение электронной почты</div>
        <div className={styles.description}>
          Ваш адрес электронной почты не подтверждён. Пожалуйста, подтвердите свою почту, чтобы
          получить доступ ко всем возможностям нашего сервиса.
        </div>
      </div>
      <div>
        <Button
          size={'base'}
          variant={'white'}
          onClick={handleClick}
          disabled={timer !== null}
          className={styles.button}
        >
          {timer !== null
            ? `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
            : 'Отправить код на почту'}
        </Button>
      </div>
    </div>
  )
}

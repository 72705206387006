import React, { useEffect, useMemo, useState } from 'react'

import { useNavigate } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import { ViewProductType } from '../../../app/store/userReducer/types'
import { useAppSelector } from '../../hooks/customHooks'

import styles from './FloatCart.module.scss'

import './animation-styles.css'
import { selectedViewCart } from 'app/store'
import { Image } from 'common/ui'

const FloatCart = () => {
  const viewCart = useAppSelector(selectedViewCart)

  const navigate = useNavigate()

  const [allProducts, setAllProducts] = useState<ViewProductType[] | null>(null)
  const lastFourProducts = useMemo(() => {
    return allProducts ? allProducts.slice(Math.max(allProducts.length - 4, 0)) : []
  }, [allProducts])

  const [remainingProducts, setRemainingProducts] = useState<number>(0)
  const [predState, setPred] = useState<number>()
  const hasGoods = useMemo(() => {
    return viewCart?.length > 0
  }, [viewCart])
  const navigateToCart = () => {
    navigate(`/my-cart`)
  }

  useEffect(() => {
    if (Array.isArray(viewCart)) {
      setAllProducts(viewCart)
    }
  }, [viewCart])

  useEffect(() => {
    if (Array.isArray(allProducts)) {
      setRemainingProducts(allProducts?.length - lastFourProducts?.length)
    }
  }, [allProducts, lastFourProducts])

  const [animate, setAnimate] = useState(false)

  useEffect(() => {
    if (remainingProducts > 0) {
      setAnimate(true)

      setPred(remainingProducts - 1)

      setTimeout(() => {
        setAnimate(false)
        setPred(remainingProducts)
      }, 300)
    }
  }, [remainingProducts])

  useEffect(() => {
    document.body.setAttribute(
      'data-jivosite-position',
      viewCart?.length > 0 ? 'changed' : 'default'
    )
    // console.log('float cart', viewCart?.length > 0 ? 'changed' : 'default')
  }, [viewCart])

  return (
    <div
      onClick={navigateToCart}
      className={`${styles.block}  ${hasGoods ? '' : styles['block--hidden']}`}
      id={'floatCart'}
      data-hasgoods={hasGoods.toString()}
    >
      <div className={styles.wrapper}>
        <b className={styles.title}>перейти в корзину</b>

        <div className={styles.goods}>
          <TransitionGroup
            className={`${styles.listWrapper} ${
              remainingProducts ? styles['numbers--active'] : ''
            }`}
          >
            {lastFourProducts &&
              lastFourProducts.map(item => (
                <CSSTransition
                  key={item.productId}
                  timeout={500}
                  classNames="slide-down"
                  mountOnEnter
                >
                  <div className={styles.listItemWrapper}>
                    <li className={styles.listItem} key={item.productId}>
                      <Image src={item.productImg!} />
                    </li>
                  </div>
                </CSSTransition>
              ))}
          </TransitionGroup>

          <div
            className={`${styles.numbers} ${remainingProducts ? styles['numbers--active'] : ''}`}
          >
            <div className={styles.numbersList}>
              <span className={`${styles.numbersEl} ${animate ? styles['numbers-animate'] : ''}`}>
                +{predState}
              </span>
              <span className={`${styles.numbersEl} ${animate ? styles['numbers-animate'] : ''}`}>
                +{remainingProducts}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FloatCart

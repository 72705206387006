import React from 'react'

import { RootStatusType } from '../../../../app/store'
import { progressBarStylesIdentifier } from '../../../utils/progressBarStylesIdentifier'

import styles from './StatusStagesComponent.module.scss'

import { ReactComponent as CarIcon } from 'assets/icons/svgIcons/carIcon.svg'
import { ReactComponent as CreditCardIcon } from 'assets/icons/svgIcons/creditCardIcon.svg'
import { ReactComponent as StatusIcon } from 'assets/icons/svgIcons/statusIcon.svg'
import { ReactComponent as ThumbsUpIcon } from 'assets/icons/svgIcons/thumbsUpIcon.svg'

export const StatusStagesComponent = ({ status }: StatusStagesComponentPropsType) => {
  const progressStyles = progressBarStylesIdentifier(status)

  return (
    <div className={styles.stagesContainer}>
      <div className={styles.visualBox}>
        <StatusIcon className={progressStyles.iconStyles.statusIcon} />
        <div className={styles.progressLine}>
          <div id={'orderPlaced'} className={progressStyles.progressLineStyles.orderPlaced}></div>
        </div>
        <CreditCardIcon className={progressStyles.iconStyles.creditCardIcon} />
        <div className={styles.progressLine}>
          <div id={'orderPayed'} className={progressStyles.progressLineStyles.orderPayed}></div>
        </div>
        <CarIcon className={progressStyles.iconStyles.carIcon} />
        <div className={styles.progressLine}>
          <div id={'orderHanded'} className={progressStyles.progressLineStyles.orderHanded}></div>
        </div>
        <ThumbsUpIcon className={progressStyles.iconStyles.thumbsUpIcon} />
      </div>
      <div className={styles.statusBox}>
        <div className={`${styles.orderPlaced} ${progressStyles.statusStyles.orderPlaced}`}>
          оформлен
        </div>
        <div className={`${styles.orderPayed} ${progressStyles.statusStyles.orderPayed}`}>
          оплачен
        </div>
        <div className={`${styles.orderHanded} ${progressStyles.statusStyles.orderHanded}`}>
          передан в службу доставки
        </div>
        <div className={`${styles.orderReceived} ${progressStyles.statusStyles.orderReceived}`}>
          получен
        </div>
      </div>
    </div>
  )
}

// types ======================================================================

type StatusStagesComponentPropsType = {
  status: RootStatusType
}

import React from 'react'

import styles from './CheckBoxItem.module.scss'

import { RootAdminStatusType } from 'app/store/adminReducer/types'
import { ReactComponent as CheckedIcon } from 'assets/icons/svgIcons/checkedIcon.svg'
import { statusIdentifier } from 'common'

type StatusItemProps = {
  value: RootAdminStatusType
  selectedStatus: RootAdminStatusType | null
  itemStatusHandler: (value: RootAdminStatusType) => void
  className?: string
}

export const CheckBoxItem = (props: StatusItemProps) => {
  const { value, selectedStatus, itemStatusHandler, className } = props
  const isSelected = selectedStatus === value

  const handleClick = () => {
    if (!isSelected) {
      itemStatusHandler(value)
    }
  }
  const currentStatus = statusIdentifier(value)

  const itemStyles = `${className} ${styles.statusItem} ${isSelected ? styles.selected : ''}`

  return (
    <div className={itemStyles} onClick={handleClick}>
      <div className={styles.statusTitle}>
        <div className={styles.statusColor} style={{ background: currentStatus.background }} />
        <label htmlFor={value as string}>{currentStatus.name}</label>
        <input type="radio" id={value as string} value={value as string} name="orderStatus" />
      </div>
      <div className={styles.iconBox}>
        <CheckedIcon style={isSelected ? { opacity: 1 } : { opacity: 0 }} />
      </div>
    </div>
  )
}

import React, { useState } from 'react'

import { AddressSuggestions, DaDataAddress, DaDataSuggestion } from 'react-dadata'

import styles from './DaDataInput.module.scss'

import { dadataKey } from 'app/keys/dadata-key'
import DeliveryAddress from 'types/delivery-address'

type Props = {
  currentAddress?: DeliveryAddress
  handelInputChange: (value: string) => void
  onChangeDaData: (value: DaDataSuggestion<DaDataAddress> | undefined) => void
  hasError: boolean
  errorText?: string
  dadataSuggestionsClassName?: string
}

export const DadataInput = (props: Props) => {
  const {
    currentAddress,
    handelInputChange,
    onChangeDaData,
    hasError,
    errorText,
    dadataSuggestionsClassName,
  } = props

  const [inputFocus, setInputFocus] = useState<boolean>(false)

  return (
    <div className={`${styles.block} ${hasError ? styles['block--has-error'] : ''}`}>
      <div className={`${styles.dadataWrapper}`}>
        <label
          className={`${styles.label} ${styles['label--size-base']} ${
            styles['label--variant-default']
          }
        ${
          (currentAddress?.address && currentAddress?.address !== '') || inputFocus
            ? styles['label--active']
            : ''
        } `}
        >
          город, улица и дом
        </label>

        <AddressSuggestions
          token={dadataKey}
          onChange={(value: DaDataSuggestion<DaDataAddress> | undefined) => onChangeDaData(value)}
          inputProps={{
            onFocus: () => setInputFocus(true),
            onBlur: () => setInputFocus(false),
            onInput: (e: React.ChangeEvent<HTMLInputElement>) => handelInputChange(e.target.value),
            className: `${hasError ? styles['input--has-error'] : ''}`,
          }}
          containerClassName={`${styles.dadataContainer} ${
            hasError ? styles['input--active'] : ''
          } `}
          suggestionsClassName={`${dadataSuggestionsClassName ?? ''} ${styles.dadataSuggestions}`}
          suggestionClassName={styles.dadataSuggestion}
          currentSuggestionClassName={styles.dadataCurrentSuggestion}
          highlightClassName={styles.dadataHighlight}
          hintClassName={styles.dadataHint}
        />
      </div>
      {hasError && errorText && <span className={styles.error}>{errorText}</span>}
    </div>
  )
}

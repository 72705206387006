import React from 'react'

import arrowLeftActive from '../../../assets/icons/svgIcons/arrowLeftActiveIcon.svg'
import arrowLeftDisable from '../../../assets/icons/svgIcons/arrowLeftDisableIcon.svg'
import arrowRightActive from '../../../assets/icons/svgIcons/arrowRightActiveIcon.svg'
import arrowRightDisable from '../../../assets/icons/svgIcons/arrowRightDisableIcon.svg'
import { DOTS, usePagination } from '../../hooks/usePagination'

import styles from './Pagination.module.scss'

export const Pagination = (props: PaginationPropsType) => {
  const { currentPage, onPageChanged, pageSize, totalCount } = props

  const totalPagesCount = Math.ceil(totalCount / pageSize)

  const arrowLeft = currentPage === 1 ? arrowLeftDisable : arrowLeftActive
  const arrowRight = currentPage === totalPagesCount ? arrowRightDisable : arrowRightActive

  const paginationRange = usePagination({
    currentPage,
    pagesCount: totalCount, //amount of all items
    siblingCount: 0,
    pageSize,
  })

  if (!paginationRange) return null
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    if (currentPage !== lastPage) onPageChanged(currentPage + 1)
  }

  const onPrevious = () => {
    if (currentPage !== 1) onPageChanged(currentPage - 1)
  }

  let lastPage = paginationRange ? paginationRange[paginationRange.length - 1] : 0

  return (
    <div className={styles.paginationBox}>
      <div className={styles.paginationItem}>
        <img src={arrowLeft} alt="arrowLeft" onClick={onPrevious} />
      </div>
      {paginationRange?.map((pageNumber: string | number, index) => {
        // If the pageItem is a DOT, render the DOTS unicode character

        if (pageNumber === DOTS) {
          return (
            <div key={index} className={styles.dots}>
              {DOTS}
            </div>
          )
        }

        return (
          <div
            key={index}
            className={
              pageNumber === currentPage
                ? `${styles.paginationItem} ${styles.active}`
                : styles.paginationItem
            }
            onClick={() => onPageChanged(pageNumber as number)}
          >
            {pageNumber}
          </div>
        )
      })}
      <div className={styles.paginationItem}>
        <img src={arrowRight} alt="arrowLeft" onClick={onNext} />
      </div>
    </div>
  )
}

// types ==============================================================================================================

type PaginationPropsType = {
  totalCount: number
  currentPage: number
  onPageChanged: (pageNumber: number) => void
  pageSize: number
}

import React, { useState } from 'react'

import { useFormik } from 'formik'
import { DaDataAddress, DaDataSuggestion } from 'react-dadata'
import * as uuid from 'uuid'
import * as Yup from 'yup'

import { useAppDispatch } from '../../../../hooks/customHooks'
import { Button, Input, Switch } from '../../../../ui'

import styles from './UserAddressModal.module.scss'

import { AddAddressPayloadType } from 'api/userAPI/userAccountAPI'
import { addAddressTC, ModalType, removeAddressTC, updateAddressTC } from 'app/store'
import { DadataInput } from 'features/Ordering/DaDataInput/DaDataInput'
import DeliveryAddress from 'types/delivery-address'

type Props = {
  modalType: ModalType
  editAddress?: DeliveryAddress
}

export const UserAddressModal = (props: Props) => {
  const { modalType, editAddress } = props
  const dispatch = useAppDispatch()
  const editModal = modalType === 'editAddress'
  const modalTitle = editModal ? 'редактировать адрес' : 'добавить адрес'

  const [currentAddress, setCurrentAddress] = useState<DeliveryAddress | undefined>()

  const validationSchema = Yup.object().shape({
    // id: Yup.string(),
    address: Yup.string().required('это поле не может быть пустым'),
    entrance: Yup.string(),
    floor: Yup.string(),
    flat: Yup.string(),
    intercom: Yup.string(),
    note: Yup.string(),
    isDefaultAddress: Yup.boolean(),
  })

  const formik = useFormik({
    initialValues: {
      addressId: editModal ? editAddress?.addressId : '',
      address: editModal ? editAddress?.address : '',
      entrance: editModal ? editAddress?.entrance : '',
      floor: editModal ? editAddress?.floor : '',
      flat: editModal ? editAddress?.flat : '',
      intercom: editModal ? editAddress?.intercom : '',
      note: editModal ? editAddress?.note : '',
      isDefaultAddress: editModal ? editAddress?.isDefaultAddress : false,
    } as DeliveryAddress,
    validationSchema,
    onSubmit: values => {
      if (editModal) {
        const payload = {
          ...values,
          isDefault: values.isDefaultAddress,
        }

        dispatch(updateAddressTC(payload))
      } else {
        const payload: AddAddressPayloadType = {
          address: values.address,
          entrance: values.entrance,
          floor: values.floor,
          flat: values.flat,
          intercom: values.intercom,
          note: values.note,
          isDefaultAddress: values.isDefaultAddress,
        }

        dispatch(addAddressTC(payload))
      }
    },
  })

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  const deleteAddressHandler = () => {
    dispatch(removeAddressTC(formik.values.addressId))
  }

  const handelInputChange = (value: string, field: keyof DeliveryAddress) => {
    // @ts-ignore
    const updatedData: DeliveryAddress = {
      ...currentAddress,
      [field]: value,
      addressId: currentAddress?.addressId ?? uuid.v4(),
    }

    setCurrentAddress(updatedData)
    formik.setFieldValue('address', value)
  }

  const onChangeDaData = (daData: DaDataSuggestion<DaDataAddress> | undefined) => {
    if (daData?.value) {
      formik.setFieldValue('address', daData.value)
    }
  }

  return (
    <form className={styles.modalBox}>
      <div className={styles.header}>{modalTitle}</div>
      <div className={styles.content}>
        <div className={styles.orderingForm}>
          <div className={styles.form}>
            {editModal ? (
              <Input
                name={'address'}
                size={'base'}
                variant={'default'}
                label={'город, улица и дом'}
                value={formik.values.address}
                onChange={formik.handleChange}
                hasError={!!formik.errors.address}
                errorText={formik.errors.address}
              />
            ) : (
              <DadataInput
                currentAddress={currentAddress}
                handelInputChange={(value: string) => handelInputChange(value, 'address')}
                onChangeDaData={(value: DaDataSuggestion<DaDataAddress> | undefined) =>
                  onChangeDaData(value)
                }
                hasError={formik.touched.address || !!formik.errors?.address}
                errorText={formik.errors?.address}
                dadataSuggestionsClassName={styles.dadataSuggestions}
              />
            )}

            <div className={styles.inputBox}>
              <Input
                name={'entrance'}
                size={'base'}
                variant={'default'}
                label={'подъезд'}
                value={formik.values.entrance}
                onChange={formik.handleChange}
                mask={'___'}
              />
              <Input
                name={'floor'}
                size={'base'}
                variant={'default'}
                label={'этаж'}
                value={formik.values.floor}
                onChange={formik.handleChange}
                mask={'___'}
              />

              <Input
                name={'flat'}
                size={'base'}
                variant={'default'}
                label={'квартира'}
                value={formik.values.flat}
                onChange={formik.handleChange}
                mask={'____'}
              />

              <Input
                name={'intercom'}
                size={'base'}
                variant={'default'}
                label={'домофон'}
                value={formik.values.intercom}
                onChange={formik.handleChange}
                mask={'______'}
              />
            </div>

            <Input
              name={'note'}
              size={'base'}
              variant={'default'}
              label={'комментарий курьеру'}
              value={formik.values.note}
              onChange={formik.handleChange}
            />

            <div className={styles.formSwitch}>
              <Switch
                name={'isDefaultAddress'}
                value={formik.values.isDefaultAddress}
                onFormikChange={formik.handleChange}
              />
              <span>адрес по-умолчанию</span>
            </div>
          </div>
        </div>

        <div className={styles.submitBtn}>
          {modalType === 'addAddress' && (
            <Button mod="fluid" size="large" variant="default" type="submit" onClick={handleSubmit}>
              добавить адрес
            </Button>
          )}
          {modalType === 'editAddress' && (
            <div className={styles.actionBlock}>
              <Button
                mod="fluid"
                size="large"
                variant="default"
                type="submit"
                onClick={handleSubmit}
              >
                сохранить изменения
              </Button>
              <Button
                mod="fluid"
                size="large"
                variant="transparent"
                type="button"
                onClick={deleteAddressHandler}
              >
                удалить адрес
              </Button>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

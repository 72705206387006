import React from 'react'

import TextareaAutosize from 'react-textarea-autosize'

import { Button } from '../../../../ui'

import styles from './ReturnOrderModal.module.scss'

type ReturnOrderModalPropsType = {
  value: string
  onChange: (value: string) => void
  onSent: () => void
  placeholder: string
  title: string
  text?: string
  btnText: string
}

export const ReturnOrderModal = (props: ReturnOrderModalPropsType) => {
  const { value, onChange, onSent, placeholder, title, text, btnText } = props

  return (
    <div className={styles.modalBox}>
      <div className={styles.header}>
        <div className={styles.title}>{title}</div>
        {text && <div className={styles.text}>{text}</div>}
      </div>

      <div className={styles.contentBox}>
        <div className={styles.textareaBox}>
          <TextareaAutosize
            minRows={3}
            value={value}
            placeholder={placeholder}
            onChange={event => onChange(event.currentTarget.value)}
          />
        </div>

        <Button
          size="large"
          variant="default"
          className={styles.buttonBox}
          disabled={value.length === 0}
          onClick={onSent}
        >
          {btnText}
        </Button>
      </div>
    </div>
  )
}

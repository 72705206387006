import React, { MouseEvent } from 'react'

import { AccountStatusHandler } from '../AccountStatusHandler/AccountStatusHandler'

import styles from './MobileViewAdminUserComponent.module.scss'
import { SkeletonMobileView } from './SkeletonMobileView'

import { EmployeeType, ModalType } from 'app/store'
import disabledEditIcon from 'assets/icons/svgIcons/disabledEditIcon.svg'
import { formatDateTime, formatSum, roleHandler } from 'common'
import { PopupCheckBoxType } from 'types/popup-types'

type MobileViewAdminUserComponentPropsType = {
  employeeData: EmployeeType[]
  modalHandler: (modalType: ModalType, user?: EmployeeType) => void
  openCheckboxHandler: (e: MouseEvent<HTMLDivElement>, type: PopupCheckBoxType) => void
  selectedAccountId: number | null
  searchValue: string
}
export const MobileViewAdminUserComponent = (props: MobileViewAdminUserComponentPropsType) => {
  const { searchValue, employeeData, modalHandler, openCheckboxHandler, selectedAccountId } = props

  return (
    <>
      {employeeData.length ? (
        employeeData.map(user => {
          return (
            <div key={user.userId} className={styles.userBox}>
              <div className={styles.header}>
                <div className={styles.userName}>{user.userName}</div>
                <div className={styles.action} onClick={() => modalHandler('editUser', user)}>
                  <img src={disabledEditIcon} alt="edit" />
                </div>
              </div>
              <div className={styles.contentBox}>
                <div className={styles.contentItem}>
                  <div className={styles.itemName}>почта</div>
                  <div className={styles.itemValue}>{user.userEmail}</div>
                </div>
                <div className={styles.contentItem}>
                  <div className={styles.itemName}>тип пользователя</div>
                  <div className={styles.itemValue}>{roleHandler(user.role)}</div>
                </div>
                <div className={styles.contentItem}>
                  <div className={styles.itemName}>сумма</div>
                  <div className={styles.itemValue}>
                    {user.amount ? `${formatSum(user.amount)} ₽` : ''}
                  </div>
                </div>
                <div className={styles.contentItem}>
                  <div className={styles.itemName}>дата создания</div>
                  <div className={styles.itemValue}>
                    {formatDateTime(user.creationDate, 'dateWithTimeComma')}
                  </div>
                </div>
                <div className={styles.contentItem}>
                  <div className={styles.itemName}>дата изменения</div>
                  <div className={styles.itemValue}>
                    {formatDateTime(user.changeDate, 'onlyDate')}
                  </div>
                </div>
                <div className={styles.contentItem}>
                  <div className={styles.itemName}>рефералы</div>
                  <div className={styles.itemValue}>{user.referrals ? user.referrals : '-'}</div>
                </div>
              </div>
              <AccountStatusHandler
                isBlocked={user.blocked}
                selectedAccountId={selectedAccountId}
                userId={user.userId}
                openCheckboxHandler={openCheckboxHandler}
                className={styles.button}
              />
            </div>
          )
        })
      ) : (
        <>
          <SkeletonMobileView />
          <div className={styles.noData}>
            {searchValue === '' ? 'нет данных' : 'ничего не найдено'}
          </div>
        </>
      )}
    </>
  )
}

import React from 'react'

import { formatSum } from '../../../utils/format-sum'
import { formatDateTime } from '../../../utils/formateDateTime'

import styles from './MobileViewOrderComponent.module.scss'
import { SkeletonMobileView } from './SkeletonMobileView'

import { OrderType } from 'app/store'
import { RootAdminStatusType } from 'app/store/adminReducer/types'
import { OrderStatusHandler } from 'common/components/index'
import { PopupCheckBoxType } from 'types/popup-types'

type MobileViewOrderComponentPropsType = {
  orderData: OrderType[]
  openCheckboxHandler: (
    e: React.MouseEvent<HTMLDivElement>,
    type: PopupCheckBoxType,
    id: number,
    status: RootAdminStatusType
  ) => void
  selectedOrderId: number | null
  navigateToOrderHandler: (id: number) => void
  searchValue: string
}
export const MobileViewOrderComponent = (props: MobileViewOrderComponentPropsType) => {
  const { searchValue, orderData, selectedOrderId, openCheckboxHandler, navigateToOrderHandler } =
    props

  return (
    <div className={styles.mobileOrderList}>
      {orderData.length ? (
        orderData.map(order => {
          return (
            <div
              key={order.orderId}
              className={styles.orderItem}
              onClick={() => navigateToOrderHandler(order.orderId)}
            >
              <div className={styles.orderHeader}>
                <div className={styles.titleBox}>
                  <div className={styles.title}>
                    № {order.orderId} от {formatDateTime(order.orderDate, 'full')}
                  </div>
                  <div className={styles.price}>
                    <span>{formatSum(order.orderSum)} ₽</span>
                  </div>
                </div>
                <div
                  className={styles.orderStatus}
                  onClick={event =>
                    openCheckboxHandler(event, 'changeStatus', order.orderId, order.adminStatus)
                  }
                >
                  <OrderStatusHandler
                    status={order.adminStatus}
                    selectedOrderId={selectedOrderId}
                    orderId={order.orderId}
                  />
                </div>
              </div>
              <div className={styles.orderContacts}>
                <div className={styles.contactItem}>
                  <span>{order.userName}</span>
                </div>
                <div className={styles.contactItem}>
                  <span>{order.userPhone}</span>
                </div>
                <div className={styles.contactItem}>
                  <span>{order.userEmail}</span>
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <>
          <SkeletonMobileView />
          <div className={styles.notFoundBox}>
            {searchValue === '' ? 'нет данных' : 'ничего не найдено'}
          </div>
        </>
      )}
    </div>
  )
}

import React from 'react'

import styles from './MessengerHandler.module.scss'

import telegramIcon from 'assets/icons/svgIcons/telegramIcon.svg'
import whatsappIcon from 'assets/icons/svgIcons/whatsAppIcon.svg'

export const MessengerHandler = (props: MessengerHandlerPropsType) => {
  return (
    <div
      className={props.communicationType === 'Telegram' ? styles.telegramBox : styles.whatsappBox}
    >
      <img
        src={props.communicationType === 'Telegram' ? telegramIcon : whatsappIcon}
        alt="messenger icon"
      />
      <span>{props.communicationNickName}</span>
    </div>
  )
}

//type ===========================================

type MessengerHandlerPropsType = {
  communicationType: string
  communicationNickName: string
}

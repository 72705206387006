import React from 'react'

import { Checkbox } from '../../../ui'

import styles from './PassportItem.module.scss'

import { ReactComponent as EditIcon } from 'assets/icons/svgIcons/editAdminUser.svg'
import UserPassport from 'types/user-passport'

type PassportItemPropsType = {
  passport: UserPassport
  updatePassport: (passport: UserPassport) => void
  setDefaultPassport: (passport: UserPassport) => void
}

export const PassportItem = (props: PassportItemPropsType) => {
  const { passport, updatePassport, setDefaultPassport } = props

  const setDefaultHandler = (value: boolean) => {
    setDefaultPassport({ ...passport, isDefault: value })
  }

  return (
    <div className={styles.block}>
      <div className={styles.box}>
        <div className={styles.passport}>
          <h5 className={styles.title}>
            {passport.number}
            <EditIcon onClick={() => updatePassport(passport)} />
          </h5>
          <p>{passport.issueDate}</p>
        </div>

        <div className={styles.actions}>
          {passport.isDefault && <div className={styles.badge}>по умолчанию</div>}

          <Checkbox
            id={passport.id?.toString() ?? '0'}
            value={passport.isDefault ?? false}
            onChange={value => setDefaultHandler(value)}
          />
        </div>
      </div>
      <div className={styles.mobileBox}>
        <div className={styles.edit}>
          <EditIcon className={styles.editIcon} onClick={() => updatePassport(passport)} />
          редактировать
        </div>

        {passport.isDefault && <div className={styles.mobileBadge}>по умолчанию</div>}
      </div>
    </div>
  )
}

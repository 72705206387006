import { AvailableBrandEnum, ShopNameEnum } from '../../types/available-brand-enum'

export const shopsData = [
  {
    name: ShopNameEnum.Farfetch,
    type: AvailableBrandEnum.Farfetch,
    url: 'https://www.farfetch.com/',
  },
  {
    name: ShopNameEnum.Yoox,
    type: AvailableBrandEnum.Yoox,
    url: 'https://www.yoox.com/',
  },
  // {
  //   name: 'Louic Vuitton',
  //   type: 'lv',
  //   url: 'https://www.louisvuitton.com/',
  // },
  // {
  //   name: 'Hermes',
  //   type: 'hermes',
  //   url: 'https://www.hermes.com/',
  // },
  // {
  //   name: 'Matches',
  //   type: 'matches',
  //   url: 'https://www.matchesfashion.com/womens/sale',
  // },
  {
    name: ShopNameEnum.NetAPorter,
    type: AvailableBrandEnum.NetAPorter,
    url: 'https://www.net-a-porter.com/en-ru/',
  },
  {
    name: ShopNameEnum.MrPorter,
    type: AvailableBrandEnum.MrPorter,
    url: 'https://www.mrporter.com/en-ru/',
  },
]

export const boutiquesData = [
  {
    name: ShopNameEnum.Prada,
    type: AvailableBrandEnum.Prada,
    url: 'https://www.prada.com/ ',
  },
  {
    name: ShopNameEnum.Dior,
    type: AvailableBrandEnum.Dior,
    url: 'https://www.dior.com/',
  },
  // {
  //   name: ShopNameEnum.PhilippPleinOutlet,
  //   type: AvailableBrandEnum.PhilippPleinOutlet,
  //   url: 'https://pleinoutlet.com/',
  // },
]

import React from 'react'

import { imgCollector } from '../../../utils/imgCollector'
import { GoodsCounter } from '../GoodsCounter/GoodsCounter'

import styles from './GoodsImgMapper.module.scss'

import placeholder from 'assets/icons/imgPlaceholder_60.png'
import { allOrderHistoryType } from 'types/user-history-type'

type GoodsImgMapperPropsType = {
  order: allOrderHistoryType
}

export const GoodsImgMapper = (props: GoodsImgMapperPropsType) => {
  const { order } = props

  const imgArr = imgCollector(order?.cart)
  const extraCount = imgArr.length - 4

  return (
    <div className={styles.list}>
      {imgArr.slice(0, 4).map((img, index) => {
        const imgAlt = img.substring(img.lastIndexOf('/') + 1, img.lastIndexOf('.'))

        return (
          <div key={index} className={styles.itemImg}>
            <img
              src={img}
              alt={imgAlt}
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                const target = e.target as HTMLImageElement

                target.src = placeholder
              }}
            />
          </div>
        )
      })}
      {extraCount > 0 && <GoodsCounter extraCount={extraCount} />}
    </div>
  )
}

import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'

import { adminReducer } from './adminReducer/adminReducer'
import { appReducer } from './appReducer/appReducer'
import { buyerReducer } from './buyerReducer/buyerReducer'
import { filterReducer } from './filterReducer/filterReducer'
import { userReducer } from './userReducer/userReducer'

export const rootReducer = combineReducers({
  app: appReducer,
  admin: adminReducer,
  buyer: buyerReducer,
  user: userReducer,
  filter: filterReducer,
})

const composeEnhancers =
  // @ts-ignore
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    // @ts-ignore
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
  compose

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))

export default store

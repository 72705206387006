import React from 'react'

import { useAppDispatch } from '../../../hooks/customHooks'
import { Button } from '../../../ui'

import styles from './PriceRangeModal.module.scss'

import { RangeType, setRootModalDataAC } from 'app/store'
import { PriceRangeFilter } from 'features/AdminPanel'

type PriceRangeModalPropsType = {
  filteredPrices: RangeType | null
  filterPriceHandler: (prices: RangeType) => void
}
export const PriceRangeModal = (props: PriceRangeModalPropsType) => {
  const { filteredPrices, filterPriceHandler } = props
  const dispatch = useAppDispatch()
  const handleModalClose = () => {
    dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
  }

  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.header}>поиск по сумме</div>
        <div className={styles.content}>
          <PriceRangeFilter
            filteredPrices={filteredPrices}
            filterPriceHandler={filterPriceHandler}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <Button
          size="large"
          variant="default"
          className={styles.button}
          type={'button'}
          onClick={handleModalClose}
        >
          найти заказы
        </Button>
      </div>
    </div>
  )
}

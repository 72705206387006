import React from 'react'

import styles from './DocumentComponent.module.scss'

import { DocumentsPageType, RequisitesType } from 'app/store/appReducer/types'

type DocumentPropsType = {
  document?: DocumentsPageType
  requisites?: RequisitesType
  position?: string
}
export const DocumentComponent = ({ document, requisites, position }: DocumentPropsType) => {
  return (
    <div className={position === 'second' ? styles['container--second'] : styles.container}>
      {document?.notice && (
        <div className={styles.notice} dangerouslySetInnerHTML={{ __html: document.notice }} />
      )}
      {document?.title && (
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: document.title }} />
      )}
      {requisites?.title && (
        <div className={styles.title} dangerouslySetInnerHTML={{ __html: requisites.title }} />
      )}
      {document?.description && (
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: document.description }}
        />
      )}
      {document?.text &&
        document.text.map((item, index) => (
          <div key={`${Math.random()}-${index}`} className={styles.contentBox}>
            {item.subTitle && (
              <div
                className={styles.subTitle}
                dangerouslySetInnerHTML={{ __html: item.subTitle }}
              />
            )}
            {item.description && (
              <div
                className={styles.content}
                dangerouslySetInnerHTML={{ __html: item.description }}
              />
            )}
            {item.paragraphs &&
              item.paragraphs.map((paragraph, index) => (
                <div key={`${Math.random()}-${index}`} className={styles.content}>
                  {paragraph.content && (
                    <div
                      className={styles.subContent}
                      dangerouslySetInnerHTML={{ __html: paragraph.content }}
                    />
                  )}
                  {paragraph.sub &&
                    paragraph.sub.map((sub, index) => (
                      <div
                        key={index}
                        className={styles.sub}
                        dangerouslySetInnerHTML={{ __html: sub.content }}
                      />
                    ))}
                </div>
              ))}
          </div>
        ))}
      {requisites && (
        <div className={styles.requisitesBox}>
          {requisites.content &&
            requisites.content.map((item, index) => (
              <div key={index} className={styles.requisiteItem}>
                <div className={styles.requisiteItemTitle}>{item.subTitle}</div>
                {item.description && (
                  <div className={styles.requisiteItemDescription}>{item.description}</div>
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  )
}

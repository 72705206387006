import React from 'react'

import { OrderStatusHandler } from '../../OrderStatusHandler/OrderStatusHandler'
import { ReturnDescriptionComponent } from '../../ReturnDescriptionComponent/ReturnDescriptionComponent'

import styles from './order-info.module.scss'

import { CurrentOrderType } from 'app/store'
import { ReactComponent as StatusIcon } from 'assets/icons/svgIcons/statusIcon.svg'

type StatusComponentPropsType = {
  orderData: CurrentOrderType | null
}
export const StatusComponent = (props: StatusComponentPropsType) => {
  const { orderData } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <StatusIcon />
        <span>статус</span>
      </div>
      <div className={styles.itemDescription}>
        <div className={styles.statuses}>
          {orderData?.orderStatus && <OrderStatusHandler status={orderData.orderStatus} />}
          {orderData?.isCallNeeded && <div className={styles.callNeeded}>Требуется звонок</div>}
        </div>
        {orderData &&
          orderData?.returnNote &&
          orderData.maintenanceAdminName &&
          orderData.orderStatus === 'ReturnedByAdmin' && (
            <ReturnDescriptionComponent
              name={orderData.maintenanceAdminName}
              orderNote={orderData.returnNote}
              role={'Admin'}
            />
          )}
        {orderData && orderData?.buyerRejectNote && (
          <ReturnDescriptionComponent
            orderNote={orderData.buyerRejectNote}
            name={orderData.payerName}
            role={orderData.payerUserRole}
          />
        )}
        {orderData && orderData?.cancelNote && (
          <ReturnDescriptionComponent
            orderNote={orderData.cancelNote}
            name={orderData.maintenanceManagerName}
            role={'Manager'}
          />
        )}
      </div>
    </div>
  )
}

import React, { useEffect, useState } from 'react'

import { NavLink, useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../hooks/customHooks'

import styles from './UserAccountMenu.module.scss'

import { PATH } from 'app/routes/AppRoutes'
import { selectedMenuIconPosition, setMenuIconPositionAC } from 'app/store'
import { ReactComponent as MenuLine } from 'assets/icons/svgIcons/menuLine.svg'

export const UserAccountMenu = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const iconPosition = useAppSelector(selectedMenuIconPosition)
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true)
  // const startPosition = location.pathname === PATH.USER_ACCOUNT_BONUSES ? 36 : 0
  //
  // const [iconPosition, setIconPosition] = useState(startPosition)
  const iconHeight = 39

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false)
    } else {
      if (location.pathname === PATH.USER_ACCOUNT_HISTORY) dispatch(setMenuIconPositionAC(3))
      else if (location.pathname === PATH.USER_ACCOUNT_BONUSES)
        dispatch(setMenuIconPositionAC(iconHeight))
      else if (location.pathname === PATH.USER_ACCOUNT_ADDRESSES)
        dispatch(setMenuIconPositionAC(iconHeight * 2))
      else if (location.pathname === PATH.USER_ACCOUNT_PASSPORT)
        dispatch(setMenuIconPositionAC(iconHeight * 3))
      else if (location.pathname === PATH.USER_ACCOUNT_SETTINGS)
        dispatch(setMenuIconPositionAC(iconHeight * 4))
    }
  }, [isFirstLoad])

  return (
    <div className={styles.menuContainer}>
      <div className={styles.line} style={{ transform: `translateY(${iconPosition}px)` }}>
        <MenuLine />
      </div>
      <NavLink
        to={PATH.USER_ACCOUNT_HISTORY}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        история заказов
      </NavLink>
      <NavLink
        to={PATH.USER_ACCOUNT_BONUSES}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        бонусы
      </NavLink>

      <NavLink
        to={PATH.USER_ACCOUNT_ADDRESSES}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        мои адреса
      </NavLink>
      <NavLink
        to={PATH.USER_ACCOUNT_PASSPORT}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        мои паспорта
      </NavLink>
      <NavLink
        to={PATH.USER_ACCOUNT_SETTINGS}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        настройки
      </NavLink>
    </div>
  )
}

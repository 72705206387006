import React, { ChangeEvent, useRef, useState } from 'react'

import styles from './DragAndDrop.module.scss'

type DragAndDropPropsType = {
  onFileUpload: (formData: FormData) => void
}

export const DragAndDrop = (props: DragAndDropPropsType) => {
  const { onFileUpload } = props

  const inputRef = useRef<HTMLInputElement>(null)
  const [dragActive, setDragActive] = useState(false)

  // @ts-ignore
  const handleDrag = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  // @ts-ignore
  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    if (e.dataTransfer?.files && e.dataTransfer.files[0]) {
      const formData = new FormData()

      formData.append('image', e.dataTransfer.files[0])
      onFileUpload(formData)
      // onFileUpload(e.target.files[0])
    }
  }

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    if (e.target.files && e.target.files[0]) {
      // onFileUpload(e.target.files[0])

      const formData = new FormData()

      formData.append('image', e.target.files[0])

      onFileUpload(formData)
    }
    if (inputRef.current) inputRef.current.value = ''
  }

  return (
    <div className={styles.dnd}>
      <div className={styles.title}>
        <span>Прикрепите фотографии и трек-номера оформленного заказа</span>
      </div>
      <div className={styles['dnd-area']}>
        <div
          className={`${styles['drop-zone']} ${dragActive ? styles['drop-zone--active'] : ''}`}
          onDragEnter={event => handleDrag(event)}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={event => handleDrop(event)}
        >
          <div className={styles.text}>
            Перетащите или <span onClick={() => inputRef.current?.click()}>выберите</span> файлы
          </div>
          <input
            type={'file'}
            onChange={handleChange}
            ref={inputRef}
            accept={'image/jpeg, image/png'}
          />
        </div>
        <div className={styles.caption}>
          <span>форматы: JPEG, PNG</span>
          <span>макс. размер: 25 МБ</span>
        </div>
      </div>
    </div>
  )
}

import React from 'react'

import { roleHandler } from '../../../../utils/roleHandler'

import styles from './order-info.module.scss'

import { UserRolesType } from 'app/store'
import { ReactComponent as WhoBoughtIcon } from 'assets/icons/svgIcons/whoBought.svg'

type BuyerNameComponentPropsType = {
  payerName: string
  payerUserRole: UserRolesType
}
export const BuyerNameComponent = (props: BuyerNameComponentPropsType) => {
  const { payerName, payerUserRole } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <WhoBoughtIcon />
        <span>кто купил</span>
      </div>
      <div className={styles.itemDescription}>
        <span>
          {payerName} [{roleHandler(payerUserRole)}]
        </span>
      </div>
    </div>
  )
}

import React, { useState } from 'react'

import { Link, useLocation, useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../hooks/customHooks'
import { getBrowserInfo } from '../../utils/getBrowserInfo'
import { getExtensionUrl } from '../../utils/getExtensionUrl'
import { isAllowExtBtn } from '../../utils/isAllowExtBtn'
import { scrollToSection, SectionId } from '../../utils/scrollToSection'
import { LoginModal } from '../modals/userModals/authorizationModals/LoginModal'
import { RegistrationModal } from '../modals/userModals/authorizationModals/RegistrationModal'

import styles from './MainHeader.module.scss'

import { PATH } from 'app/routes/AppRoutes'
import {
  clearCartAC,
  selectedExtensionId,
  selectedRootModalData,
  selectedUserId,
  setOrderingResponseAC,
  setRootModalDataAC,
  setUserAccountTabAC,
  userLogoutTC,
} from 'app/store'
import { ReactComponent as BurgerIcon } from 'assets/icons/svgIcons/burgerIcon.svg'
import { ReactComponent as LogoIcon } from 'assets/icons/svgIcons/oh-really-logo.svg'
import width from 'assets/styles/widthContainer.module.scss'
import { getEmptyCart } from 'common'
import { BurgerModal, RecoveryPasswordModal, RightModal } from 'common/components/index'
import { Button } from 'common/ui'

type MainHeaderPropsType = {
  isInverted?: boolean
  isFixed?: boolean
}

const MainHeader = ({ isInverted, isFixed }: MainHeaderPropsType) => {
  const isLoggedIn = useAppSelector(selectedUserId)
  const rootModalData = useAppSelector(selectedRootModalData)
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const chromeExtensionId = useAppSelector(selectedExtensionId)

  const [isBurgerOpen, setIsBurgerOpen] = useState<boolean>(false)

  const isUserAccountPage = location.pathname.startsWith(PATH.USER_ACCOUNT)

  const currentBrowser = getBrowserInfo()
  const allowExtBtn = isAllowExtBtn(currentBrowser)
  const extensionPageUrl = getExtensionUrl(currentBrowser, chromeExtensionId)

  const clickHandler = (sectionId: SectionId, isBurger: boolean, yOffset: number) => {
    if (isBurger) closeBurgerHandler()

    scrollToSection(sectionId, navigate, location.pathname, yOffset)
  }

  const openLoginModalHandler = () => {
    dispatch(setRootModalDataAC({ isOpen: true, modalType: 'login', direction: 'right' }))
  }

  const logoutHandler = () => {
    dispatch(userLogoutTC())
    closeBurgerHandler()
  }

  const openBurgerHandler = () => {
    setIsBurgerOpen(true)
  }
  const closeBurgerHandler = () => {
    setIsBurgerOpen(false)
  }

  const goToBonusPageHandler = () => {
    dispatch(setUserAccountTabAC('bonuses'))
    navigate(PATH.USER_ACCOUNT_BONUSES)
    closeBurgerHandler()
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const installExtensionHandler = () => {
    window.open(extensionPageUrl, '_blank')
  }

  const logoClickHandler = () => {
    if (
      location.pathname === PATH.ORDERING_SUCCESSFUL ||
      location.pathname === PATH.ORDERING_FAILED
    ) {
      dispatch(clearCartAC())
      dispatch(
        setOrderingResponseAC({
          orderId: null,
          cart: getEmptyCart(),
          paymentUrl: null,
        })
      )
    } else {
      navigate(PATH.MAIN)
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }
  }

  return (
    <>
      <div
        className={`${styles.block} ${isInverted ? styles['block--inverted'] : ''} ${
          isFixed ? styles['block--fixed'] : ''
        }`}
      >
        <div className={width.fluid}>
          <div className={styles.wrapper}>
            <div onClick={logoClickHandler} className={styles.logo}>
              <LogoIcon
                className={`${styles.logoImg} ${isInverted ? styles['block--inverted'] : ''}`}
              />
            </div>

            <div className={styles.side}>
              <menu className={styles.menu}>
                <li className={styles.menuItem}>
                  <Link
                    className={styles.menuEl}
                    to={PATH.MAIN}
                    onClick={() => {
                      clickHandler(SectionId.FAQ, false, -97)
                    }}
                  >
                    вопрос-ответ
                  </Link>
                </li>

                <li className={styles.menuItem}>
                  <Link
                    className={styles.menuEl}
                    to={location.hash ? location.pathname + location.hash : location.pathname}
                    onClick={() => {
                      clickHandler(SectionId.CONTACTS, false, -97)
                    }}
                  >
                    контакты
                  </Link>
                </li>

                <>
                  {isLoggedIn ? (
                    <li className={styles.menuItem} onClick={goToBonusPageHandler}>
                      <span className={styles.menuEl}>привести друга</span>
                    </li>
                  ) : null}
                  {allowExtBtn ? (
                    <li className={styles.menuItem} id={'install-extension-btn'}>
                      <Button size="base" variant="white" onClick={installExtensionHandler}>
                        скачать расширение
                      </Button>
                    </li>
                  ) : null}
                  {!isUserAccountPage && isLoggedIn ? (
                    <li className={styles.menuItem}>
                      <Button
                        size="base"
                        variant="default"
                        className={styles.menuEnter}
                        onClick={() => navigate(PATH.USER_ACCOUNT_HISTORY)}
                      >
                        личный кабинет
                      </Button>
                    </li>
                  ) : null}
                  {isUserAccountPage && isLoggedIn ? (
                    <li className={styles.menuItem}>
                      <Button
                        size="base"
                        variant="default"
                        className={styles.menuEnter}
                        onClick={logoutHandler}
                      >
                        Выйти
                      </Button>
                    </li>
                  ) : null}
                </>
                {!isLoggedIn ? (
                  <li className={styles.menuItem}>
                    <Button
                      size="base"
                      variant="default"
                      className={styles.menuEnter}
                      onClick={openLoginModalHandler}
                    >
                      Войти
                    </Button>
                  </li>
                ) : null}
              </menu>
            </div>

            <div className={styles.burgerBox} onClick={openBurgerHandler}>
              <BurgerIcon />
            </div>
          </div>
        </div>
      </div>

      <BurgerModal
        isOpen={isBurgerOpen}
        closeBurgerHandler={closeBurgerHandler}
        isLoggedIn={!!isLoggedIn}
        goToBonusPageHandler={goToBonusPageHandler}
        openLoginModalHandler={openLoginModalHandler}
        scrollToSection={clickHandler}
        location={location.pathname}
        logoutHandler={logoutHandler}
        isUserAccountPage={isUserAccountPage}
      />

      <RightModal modalData={rootModalData} modalType={rootModalData.modalType}>
        {rootModalData.modalType === 'login' && (
          <LoginModal page={'ordering'} modalType={rootModalData.modalType} />
        )}
        {rootModalData.modalType === 'registration' && (
          <RegistrationModal page={'ordering'} modalType={rootModalData.modalType} />
        )}
        {rootModalData.modalType === 'passwordRecovery' && (
          <RecoveryPasswordModal modalType={rootModalData.modalType} />
        )}
      </RightModal>
    </>
  )
}

export default MainHeader

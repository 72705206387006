import { PhoneNumbersType, WarehouseType } from '../../app/store'
import { BasicResponse, RootResponse } from '../api-type'
import { instance } from '../instance'

export const adminWarehouseAPI = {
  getWarehouses() {
    return instance.get<GetWarehousesResponseType>('/admin/warehouse')
  },
  addWarehouse(payload: WarehouseRequestType) {
    return instance.post<'', WarehouseResponseType, WarehouseRequestType>(
      '/admin/warehouse',
      payload
    )
  },
  editWarehouse(payload: WarehouseRequestType) {
    return instance.put<'', WarehouseResponseType, WarehouseRequestType>(
      '/admin/warehouse',
      payload
    )
  },
  deleteWarehouse(id: number) {
    return instance.delete(`/admin/warehouse?id=${id}`)
  },
}

type GetWarehousesResponseType = RootResponse<'data', WarehouseType[]>

export type WarehouseRequestType = {
  id?: number
  address: string
  numbers: PhoneNumbersType[]
}
export type WarehouseResponseType = {
  data: BasicResponse
}

import React, { useEffect, useState } from 'react'

import { useAppSelector } from '../../hooks/customHooks'
import { getBrowserInfo } from '../../utils/getBrowserInfo'
import { getExtensionUrl } from '../../utils/getExtensionUrl'

import styles from './LongLoadingTip.module.scss'

import { selectedExtensionId } from 'app/store'
import { ReactComponent as CloseIcn } from 'assets/icons/close.svg'

type LongLoadingTipProps = {
  isLoading: boolean
}

export const LongLoadingTip = ({ isLoading }: LongLoadingTipProps) => {
  const [shown, setShown] = useState<boolean>(false)
  const extensionId = useAppSelector(selectedExtensionId)

  const currentBrowser = getBrowserInfo()
  const extensionPageUrl = getExtensionUrl(currentBrowser, extensionId)

  const closeHandler = () => {
    setShown(false)
  }

  const clickHandler = () => {
    window.open(extensionPageUrl, '_blank')
  }

  useEffect(() => {
    if (isLoading) {
      const timeout = setTimeout(() => {
        setShown(true)

        return () => clearTimeout(timeout)
      }, 4000)
    } else {
      setShown(false)
    }
  }, [isLoading])

  return (
    <div className={`${styles.container} ${shown ? styles['container--shown'] : ''}`}>
      <button className={styles.close} onClick={closeHandler}>
        <CloseIcn className={styles.closeIcn} />
      </button>

      <div className={styles.body}>
        <div className={styles.title}>Медленная загрузка?</div>
        <div className={styles.text}>
          Воспользуйтесь нашим{' '}
          <span id={'extension-link'} onClick={clickHandler}>
            расширением
          </span>
          , чтобы добавлять товары в корзину быстрее
        </div>
      </div>
    </div>
  )
}

import {
  ADD_PRODUCT,
  CLEAR_CART,
  EDIT_PRODUCT,
  FETCH_USER,
  FETCH_USER_CART,
  REMOVE_PRODUCT,
  SET_ADDRESSES,
  SET_ALL_ORDER_HISTORY,
  SET_ALL_ORDERS_COUNT,
  SET_BONUSES,
  SET_CURRENT_ORDER,
  SET_CURRENT_PAGE,
  SET_DELETED_PRODUCT,
  SET_DELIVERY_COST,
  SET_DISCOUNTED_CART,
  SET_IS_USE_DISCOUNT,
  SET_IS_VERIFIED_EMAIL,
  SET_ORDERING_RESPONSE,
  SET_PAGES_COUNT,
  SET_PASSPORT_DATA,
  SET_RETURNED_ORDER,
  SET_RETURNED_ORDERS_COUNT,
  SET_SERVICE_FEE,
  SET_TOTAL_RUB_SUM,
  UPDATE_USER_DATA,
  USER_LOGOUT,
} from './actions-types'
import { BonusesType, OrderCreatedType, ViewProductType } from './types'

import { FetchUserResponseType } from 'api/userAPI/user-authorization-api'
import CartType from 'types/cart-type'
import DeliveryAddress from 'types/delivery-address'
import HistoryCurrentOrderType from 'types/history-current-order-type'
import ProductType from 'types/product-type'
import { CheckedUserData } from 'types/user-data'
import { allOrderHistoryType } from 'types/user-history-type'
import UserPassport from 'types/user-passport'

export const fetchUserCartAC = (payload: { cart: CartType; viewCart: ViewProductType[] }) => ({
  type: FETCH_USER_CART,
  payload,
})

export const fetchUserAC = (user: FetchUserResponseType | {}) => ({
  type: FETCH_USER,
  payload: user,
})

//user data================================================================
export const updateUserDateAC = (userData: CheckedUserData) => ({
  type: UPDATE_USER_DATA,
  payload: userData,
})

//cart ====================================================================
export const addProductAC = (product: ProductType) =>
  ({ type: ADD_PRODUCT, payload: product } as const)

export const editProductAC = (product: ProductType) =>
  ({ type: EDIT_PRODUCT, payload: product } as const)

export const removeProductAC = (product: ProductType) =>
  ({ type: REMOVE_PRODUCT, payload: product } as const)

//addresses================================================================
export const setAddressesAC = (addresses: DeliveryAddress[]) => ({
  type: SET_ADDRESSES,
  payload: addresses,
})

// ordering=================================================================
export const setAllOrderHistoryAC = (payload: allOrderHistoryType[]) =>
  ({ type: SET_ALL_ORDER_HISTORY, payload } as const)
export const setAllOrdersCountAC = (payload: number) =>
  ({ type: SET_ALL_ORDERS_COUNT, payload } as const)
export const setReturnedOrderAC = (payload: allOrderHistoryType[]) =>
  ({ type: SET_RETURNED_ORDER, payload } as const)
export const setReturnedOrdersCountAC = (payload: number) =>
  ({ type: SET_RETURNED_ORDERS_COUNT, payload } as const)
export const setUserCurrentPageAC = (payload: number) =>
  ({ type: SET_CURRENT_PAGE, payload } as const)
export const setUserPagesCountAC = (payload: number) =>
  ({ type: SET_PAGES_COUNT, payload } as const)
export const setCurrentOrderAC = (payload: HistoryCurrentOrderType | null) =>
  ({ type: SET_CURRENT_ORDER, payload } as const)
export const setBonusesAC = (payload: BonusesType) => ({ type: SET_BONUSES, payload } as const)
export const userLogoutAC = () => ({ type: USER_LOGOUT } as const)
export const setIsUseDiscountAC = (payload: boolean) =>
  ({ type: SET_IS_USE_DISCOUNT, payload } as const)
export const setDeletedProductAC = (payload: ProductType | null) =>
  ({ type: SET_DELETED_PRODUCT, payload } as const)
export const setTotalRubSumAC = (payload: number) => ({ type: SET_TOTAL_RUB_SUM, payload } as const)
export const setDeliveryCostAC = (payload: number) =>
  ({ type: SET_DELIVERY_COST, payload } as const)
export const setServiceFeeAC = (payload: number) => ({ type: SET_SERVICE_FEE, payload } as const)
export const setDiscountedCartAC = (payload: CartType) =>
  ({ type: SET_DISCOUNTED_CART, payload } as const)
export const setOrderingResponseAC = (payload: OrderCreatedType) =>
  ({ type: SET_ORDERING_RESPONSE, payload } as const)
export const clearCartAC = () => ({ type: CLEAR_CART } as const)
export const setPassportDataAC = (payload: UserPassport[]) =>
  ({ type: SET_PASSPORT_DATA, payload } as const)
export const setIsVerifiedEmailAC = (payload: boolean) =>
  ({ type: SET_IS_VERIFIED_EMAIL, payload } as const)

import { AxiosError } from 'axios'

import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import { AppThunkType } from '../types'

import { setAddressesAC } from './actions'
import { checkUserTC } from './thanks'

import { AddAddressPayloadType, userAccountAPI } from 'api/userAPI/userAccountAPI'
import { errorUtils } from 'common'
import DeliveryAddress from 'types/delivery-address'

export const getAddressesTC = (): AppThunkType => async dispatch => {
  try {
    dispatch(setAppStatusAC('loading'))

    const response = await userAccountAPI.getAddresses()

    if (response.data.statusCode === 0) {
      dispatch(setAddressesAC(response.data.addresses))
    } else {
      dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkUserTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const addAddressTC =
  (payload: AddAddressPayloadType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const response = await userAccountAPI.addAddress(payload)

      if (response.data.statusCode === 0) {
        dispatch(getAddressesTC())
        dispatch(setAppSuccessAC({ type: 'success', title: 'адрес успешно добавлен' }))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const updateAddressTC =
  (payload: DeliveryAddress): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const response = await userAccountAPI.editAddress(payload)

      if (response.data.statusCode === 0) {
        dispatch(getAddressesTC())
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))

        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppErrorAC({ type: 'error', title: err.message }))

      dispatch(setAppStatusAC('failed'))
    }
  }

export const removeAddressTC =
  (addressId: string): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const response = await userAccountAPI.removeAddress(addressId)

      if (response.data.statusCode === 0) {
        dispatch(getAddressesTC())
        dispatch(setAppSuccessAC({ type: 'success', title: 'адрес успешно удален' }))

        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

import { AppRootState } from '../types'

export const selectedBuyerData = (state: AppRootState) => state.buyer.buyerData
export const selectedOrderList = (state: AppRootState) => state.buyer.orderList
export const selectedMyOrders = (state: AppRootState) => state.buyer.myOrders
export const selectedBuyerCurrentPage = (state: AppRootState) => state.buyer.currentPage
export const selectedBuyerPageSize = (state: AppRootState) => state.buyer.pageSize
export const selectedBuyerPagesCount = (state: AppRootState) => state.buyer.pagesCount
export const selectedOrderListCount = (state: AppRootState) => state.buyer.orderListCount
export const selectedBuyerRevenue = (state: AppRootState) => state.buyer.totalRevenue
export const selectedMyOrdersCount = (state: AppRootState) => state.buyer.myOrdersCount
export const selectedBuyerCardsData = (state: AppRootState) => state.buyer.cardsData
export const selectedBuyerOrder = (state: AppRootState) => state.buyer.currentOrder

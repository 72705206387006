import React, { useEffect } from 'react'

import { PopupPortal } from '../../../ui'

import styles from './Popup.module.scss'

import {
  CharacteristicsType,
  GenderType,
  RangeType,
  RootAdminStatusType,
  UserRolesType,
  UsersByCharacteristicsType,
  UsersByRoleType,
} from 'app/store'
import {
  AccountStatusCheckBox,
  AgeCheckBox,
  GenderCheckBox,
  OrderStatusFilterCheckbox,
  UsersCheckBox,
} from 'features/AdminPanel'
import { PopupDataType } from 'types/popup-types'

type PopupPropsType = {
  popupData: PopupDataType
  closeCheckboxHandler: () => void
  resetOrderStatusFilter: () => void
  statusForSearch: RootAdminStatusType
  selectedOrderId: number | null
  searchStatusHandler: (status: RootAdminStatusType) => void
  selectedStatus: RootAdminStatusType | null
  changeStatusHandler: (status: RootAdminStatusType) => void
  filterAccountStatus: RootAdminStatusType
  searchAccountStatusHandler: (status: RootAdminStatusType) => void
  resetAccountStatusFilter: () => void
  changeAccountStatusHandler: (status: RootAdminStatusType) => void
  filterUserRole: UserRolesType | null
  searchUserRoleHandler: (role: UserRolesType) => void
  searchUserCharacteristicsHandler: (characteristic: CharacteristicsType) => void
  usersByRole: UsersByRoleType[]
  usersByCharacteristics: UsersByCharacteristicsType[]
  resetUsersFilter: () => void
  selectedCharacteristic: CharacteristicsType | null
  filteredGender: GenderType | null
  filterGenderHandler: (gender: GenderType) => void
  resetGenderFilter: () => void
  filteredAges: RangeType | null
  filterAgesHandler: (ages: RangeType) => void
  resetAgesFilter: () => void
}

export const Popup = (props: PopupPropsType) => {
  const {
    popupData,
    closeCheckboxHandler,
    resetOrderStatusFilter,
    searchUserCharacteristicsHandler,
    statusForSearch,
    selectedOrderId,
    searchStatusHandler,
    selectedStatus,
    changeStatusHandler,
    filterAccountStatus,
    searchAccountStatusHandler,
    resetAccountStatusFilter,
    changeAccountStatusHandler,
    filterUserRole,
    searchUserRoleHandler,
    usersByRole,
    usersByCharacteristics,
    resetUsersFilter,
    selectedCharacteristic,
    filteredGender,
    filterGenderHandler,
    resetGenderFilter,
    filteredAges,
    filterAgesHandler,
    resetAgesFilter,
  } = props

  useEffect(() => {
    if (popupData.isOpen) {
      document.body.classList.add('modal-open')
    } else {
      document.body.classList.remove('modal-open')
    }
  }, [popupData])

  return (
    <>
      {popupData.isOpen && (
        <PopupPortal id={'status-popup'}>
          {popupData.isOpen && <div className={styles.overlay} onClick={closeCheckboxHandler} />}
          <div
            style={{
              position: 'absolute',
              top: popupData.y,
              left: popupData.x,
              zIndex: 1001,
            }}
          >
            {popupData.type === 'searchStatus' && (
              <OrderStatusFilterCheckbox
                resetHandler={resetOrderStatusFilter}
                selectedStatus={selectedStatus}
                statusForSearch={statusForSearch}
                selectedOrderId={selectedOrderId}
                searchStatusHandler={searchStatusHandler}
                isReset={true}
                type={popupData.type}
              />
            )}
            {popupData.type === 'changeStatus' && (
              <OrderStatusFilterCheckbox
                resetHandler={resetOrderStatusFilter}
                selectedStatus={selectedStatus}
                statusForSearch={statusForSearch}
                selectedOrderId={selectedOrderId}
                changeStatusHandler={changeStatusHandler}
                isReset={false}
                type={popupData.type}
              />
            )}
            {popupData.type === 'filterAccountStatus' && (
              <AccountStatusCheckBox
                filterAccountStatus={filterAccountStatus}
                setFilterAccountStatus={searchAccountStatusHandler}
                resetHandler={resetAccountStatusFilter}
                isReset={true}
              />
            )}
            {popupData.type === 'setAccountStatus' && (
              <AccountStatusCheckBox
                changeAccountStatus={changeAccountStatusHandler}
                isReset={false}
              />
            )}
            {popupData.type === 'users' && (
              <UsersCheckBox
                filterUserRole={filterUserRole}
                setFilterUserRole={searchUserRoleHandler}
                setFilterUserCharacteristics={searchUserCharacteristicsHandler}
                usersByRole={usersByRole}
                usersByCharacteristics={usersByCharacteristics}
                selectedCharacteristic={selectedCharacteristic}
                resetHandler={resetUsersFilter}
                isReset={true}
              />
            )}
            {popupData.type === 'gender' && (
              <GenderCheckBox
                resetHandler={resetGenderFilter}
                setFilterGenderHandler={filterGenderHandler}
                isReset={true}
                genderFiltered={filteredGender}
              />
            )}
            {popupData.type === 'ages' && (
              <AgeCheckBox
                resetHandler={resetAgesFilter}
                filterAgesHandler={filterAgesHandler}
                isReset={true}
                filteredAges={filteredAges} // todo - get it from store
              />
            )}
          </div>
        </PopupPortal>
      )}
    </>
  )
}

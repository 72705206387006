import React from 'react'

import { MessengerHandler } from '../../MessengerHandler/MessengerHandler'

import styles from './order-info.module.scss'

import { CurrentOrderType } from 'app/store'
import { ReactComponent as ContactsIcon } from 'assets/icons/svgIcons/contactsIcon.svg'
import { ReactComponent as EmailIcon } from 'assets/icons/svgIcons/emailIcon.svg'
import { ReactComponent as PhoneIcon } from 'assets/icons/svgIcons/phoneIcon.svg'

type ContactsPropsType = {
  orderData: CurrentOrderType | null
}

export const ContactsComponent = (props: ContactsPropsType) => {
  const { orderData } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <ContactsIcon />
        <span>контакты</span>
      </div>

      <div className={styles.itemDescription}>
        {orderData?.userPhone !== '' && (
          <div className={styles.contactItem}>
            <div className={styles.icon}>
              <PhoneIcon />
            </div>
            <span>{orderData?.userPhone}</span>
          </div>
        )}
        <div className={styles.contactItem}>
          <div className={styles.icon}>
            <EmailIcon />
          </div>
          <span>{orderData?.userEmail}</span>
        </div>
        {(orderData?.communicationType === 'Telegram' ||
          orderData?.communicationType === 'Whatsapp') &&
        orderData.communicationLink ? (
          <MessengerHandler
            communicationNickName={orderData?.communicationLink}
            communicationType={orderData?.communicationType}
          />
        ) : null}
      </div>
    </div>
  )
}

import React from 'react'

import styles from './order-info.module.scss'

import { ReactComponent as CreditCardIcon } from 'assets/icons/svgIcons/creditCardIcon.svg'

type PaymentComponentPropsType = {
  paymentMethod: string
}

export const PaymentComponent = (props: PaymentComponentPropsType) => {
  const { paymentMethod } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <CreditCardIcon />
        <span>способ оплаты</span>
      </div>
      <div className={styles.itemDescription}>
        <span>{paymentMethod}</span>
      </div>
    </div>
  )
}

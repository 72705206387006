import React from 'react'

import styles from './SearchModal.module.scss'

import { ReactComponent as SearchIcon } from 'assets/icons/svgIcons/searchMobileIcon.svg'

type SearchModalPropsType = {
  value: string
  onChange: (value: string) => void
  title: string
  placeholder: string
  closeHandler?: () => void
}

export const SearchModal = (props: SearchModalPropsType) => {
  const { value, onChange, placeholder, title, closeHandler } = props

  return (
    <div className={styles.searchBox}>
      <div className={styles.searchTitle}>{title}</div>
      <div className={styles.searchInput}>
        <SearchIcon />
        <input
          placeholder={placeholder}
          value={value}
          onChange={e => onChange(e.target.value)}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              closeHandler && closeHandler()
            }
          }}
        />
      </div>
    </div>
  )
}

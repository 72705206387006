import { CharacteristicsType, RootAdminStatusType, UserRolesType } from '../adminReducer/types'

import {
  CLEAR_FILTER,
  SET_ACCOUNT_ID_TO_CHANGE,
  SET_FILTER_ACCOUNT_STATUS,
  SET_FILTERED_GENDER,
  SET_FILTERED_USER_ROLE,
  SET_FILTERED_USERS_AGES,
  SET_FILTERED_USERS_CHARACTERISTICS,
  SET_FILTERED_USERS_PRICES,
  SET_FROM_DATE,
  SET_SEARCH_VALUE,
  SET_SELECTED_ACCOUNT_ID,
  SET_SELECTED_ORDER_ID,
  SET_SELECTED_STATUS,
  SET_STATUS_FOR_SEARCH,
  SET_TO_DATE,
  SET_USERS_BY_CHARACTERISTICS,
  SET_USERS_BY_ROLE,
} from './action-types'
import { GenderType, RangeType, UsersByCharacteristicsType, UsersByRoleType } from './types'

export const setSearchValueAC = (searchValue: string) =>
  ({
    type: SET_SEARCH_VALUE,
    searchValue,
  } as const)

export const setSelectStatusAC = (selectedStatus: RootAdminStatusType | null) =>
  ({
    type: SET_SELECTED_STATUS,
    selectedStatus,
  } as const)
export const setSelectedOrderIdAC = (selectedOrderId: number | null) =>
  ({
    type: SET_SELECTED_ORDER_ID,
    selectedOrderId,
  } as const)
export const setFilteredUserRoleAC = (filteredUserRole: UserRolesType | null) =>
  ({
    type: SET_FILTERED_USER_ROLE,
    filterUserRole: filteredUserRole,
  } as const)
export const setFilterAccountStatusAC = (filterAccountStatus: RootAdminStatusType | null) =>
  ({
    type: SET_FILTER_ACCOUNT_STATUS,
    filterAccountStatus,
  } as const)
export const setStatusForSearchAC = (statusForSearch: RootAdminStatusType | null) =>
  ({
    type: SET_STATUS_FOR_SEARCH,
    statusForSearch,
  } as const)
export const setSelectedAccountIdAC = (selectedAccountId: number | null) =>
  ({
    type: SET_SELECTED_ACCOUNT_ID,
    selectedAccountId,
  } as const)
export const setAccountIdToChangeAC = (accountIdToChange: number | null) =>
  ({
    type: SET_ACCOUNT_ID_TO_CHANGE,
    accountIdToChange,
  } as const)
export const setFromDateAC = (fromDate: Date | null) =>
  ({
    type: SET_FROM_DATE,
    fromDate,
  } as const)
export const setToDateAC = (toDate: Date | null) =>
  ({
    type: SET_TO_DATE,
    toDate,
  } as const)
// export const setSelectedDateChangedAC = (selectedDateChanged: boolean) =>
//   ({
//     type: SET_SELECTED_DATE_CHANGED,
//     selectedDateChanged,
//   } as const)
export const setFilteredGenderAC = (filteredGender: GenderType) =>
  ({ type: SET_FILTERED_GENDER, filteredGender } as const)
export const setFilteredUsersAgesAC = (ages: RangeType | null) =>
  ({ type: SET_FILTERED_USERS_AGES, ages } as const)
export const setFilteredUsersPricesAC = (prices: RangeType | null) =>
  ({ type: SET_FILTERED_USERS_PRICES, prices } as const)
export const setUsersByRoleAC = (usersByRole: UsersByRoleType[]) =>
  ({ type: SET_USERS_BY_ROLE, usersByRole } as const)
export const setUsersByCharacteristicsAC = (usersByCharacteristics: UsersByCharacteristicsType[]) =>
  ({ type: SET_USERS_BY_CHARACTERISTICS, usersByCharacteristics } as const)
export const setFilteredUsersCharacteristicsAC = (
  filteredUsersCharacteristics: CharacteristicsType | null
) => ({ type: SET_FILTERED_USERS_CHARACTERISTICS, filteredUsersCharacteristics } as const)
export const clearFilterAC = () => ({ type: CLEAR_FILTER } as const)

import {
  DocumentsPageResponseType,
  FAQType,
  RequisitesResponseType,
} from '../../app/store/appReducer/types'
import { instance } from '../instance'

export const appAPI = {
  getPrivacyPolicy() {
    return instance.get<DocumentsPageResponseType>('/privacy-policy')
  },
  getOfferAgreement() {
    return instance.get<DocumentsPageResponseType>('/public-offer')
  },
  getFAQ() {
    return instance.get<FAQType[]>('/faq-web')
  },
  getRequisites() {
    return instance.get<RequisitesResponseType>('/requisites')
  },
  getPaymentAndRefund() {
    return instance.get<DocumentsPageResponseType>('/payment-and-refund')
  },
  getUserAgreement() {
    return instance.get<DocumentsPageResponseType>('/user-agreement')
  },
  getAnnexDocument() {
    return instance.get<DocumentsPageResponseType>('/user-agreement-annex')
  },
}

import {
  ADMIN_LOGOUT,
  AdminReducerActionType,
  CHANGE_ORDER_STATUS,
  SET_ADMIN_DATA,
  SET_ADMIN_SETTINGS,
  SET_ALL_ORDER_DATA,
  SET_CARDS_DATA,
  SET_CURRENT_ORDER,
  SET_CURRENT_PAGE,
  SET_EMPLOYEE_DATA,
  SET_IN_PAYMENT_ORDERS,
  SET_PAGE_SIZE,
  SET_PAGES_COUNT,
  SET_PROFIT,
  SET_REVENUE,
  SET_TOTAL_EMPLOYEE_COUNT,
  SET_TOTAL_IN_PAYMENT_COUNT,
  SET_TOTAL_ORDERS_COUNT,
  SET_WAREHOUSES,
} from './actions-type'
import { InitialStateType } from './types'

const initialState: InitialStateType = {
  userData: {
    userId: null,
    userFullName: null,
    role: null,
    blocked: false,
  },
  orders: [],
  currentOrder: null,
  inPayment: [],
  employeeData: [],
  cardsData: [],
  warehouses: [],
  totalOrdersCount: 0,
  totalInPaymentCount: 0,
  totalEmployeeCount: 0,
  revenue: '',
  profit: '',
  pageSize: 10,
  currentPage: 1,
  pagesCount: 0,
  isFetching: false,
  settings: null,
}

export const adminReducer = (
  state = initialState,
  action: AdminReducerActionType
): InitialStateType => {
  switch (action.type) {
    case SET_ADMIN_DATA:
      return { ...state, userData: action.payload }
    case SET_ALL_ORDER_DATA:
      return { ...state, orders: [...action.payload] }
    case SET_IN_PAYMENT_ORDERS:
      return { ...state, inPayment: [...action.payload] }
    case SET_CURRENT_ORDER:
      return { ...state, currentOrder: action.payload }
    case SET_TOTAL_ORDERS_COUNT:
      return { ...state, totalOrdersCount: action.payload }
    case SET_TOTAL_IN_PAYMENT_COUNT:
      return { ...state, totalInPaymentCount: action.payload }
    case SET_EMPLOYEE_DATA:
      return { ...state, employeeData: [...action.payload] }
    case SET_CARDS_DATA:
      return { ...state, cardsData: [...action.payload] }
    case SET_TOTAL_EMPLOYEE_COUNT:
      return { ...state, totalEmployeeCount: action.payload }
    case CHANGE_ORDER_STATUS:
      return {
        ...state,
        orders: state.orders.map(order =>
          order.orderId === action.orderId ? { ...order, adminStatus: action.status } : order
        ),
      }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload }
    case SET_PAGES_COUNT:
      return { ...state, pagesCount: action.payload }
    case SET_PAGE_SIZE:
      return { ...state, pageSize: action.payload }
    case SET_PROFIT:
      return { ...state, profit: action.payload }
    case SET_REVENUE:
      return { ...state, revenue: action.payload }
    case ADMIN_LOGOUT:
      return initialState
    case SET_WAREHOUSES:
      return { ...state, warehouses: [...action.payload] }
    case SET_ADMIN_SETTINGS:
      return {
        ...state,
        settings: state.settings ? { ...state.settings, ...action.payload } : action.payload,
      }
    default:
      return state
  }
}

import {
  APP_SET_ADMIN_PANEL_TAB,
  APP_SET_ANNEX_DOCUMENT,
  APP_SET_BUYER_ACCOUNT_TAB,
  APP_SET_COOKIE_MSG,
  APP_SET_ERROR,
  APP_SET_FAQ,
  APP_SET_FORGOT_PASSWORD_STATUS,
  APP_SET_INFO,
  APP_SET_IS_INITIALIZED,
  APP_SET_OFFER_AGREEMENT,
  APP_SET_PAYMENT_AND_REFUND,
  APP_SET_PREVIEW_STATUS,
  APP_SET_PRIVACY_POLICY,
  APP_SET_REQUISITES,
  APP_SET_ROOT_MODAL_DATA,
  APP_SET_STATUS,
  APP_SET_SUCCESS,
  APP_SET_USER_ACCOUNT_TAB,
  APP_SET_USER_AGREEMENT,
  AppReducerActionsType,
  SET_ADD_CARD_URL,
  SET_IMG_PREVIEW_DATA,
  SET_MENU_ICON_POSITION,
} from './actions-type'
import { InitialStateType } from './types'

const initialState: InitialStateType = {
  appStatus: 'idle',
  appError: null,
  appSuccess: null,
  appInfo: null,
  appUseCookies: null,
  forgotPasswordStatus: 'idle',
  isAppInitialized: false,
  activeAdminPanelTab: 'orders',
  activeBuyerPanelTab: 'ordersList',
  activeUserAccountTab: 'orderHistory',
  rootModal: { isOpen: false, modalType: null, direction: null },
  privacyPolicy: null,
  offerAgreement: null,
  annexDocument: null,
  requisites: null,
  paymentAndRefund: null,
  userAgreement: null,
  previewStatus: 'hide',
  faq: null,
  extensionId: 'jnpfbiongidlmaeapncnlkdgcedhkjod', //todo: check extensionId (jnpfbiongidlmaeapncnlkdgcedhkjod - prod id), (fbgigfoogglmbmdflokacfocoagmdghd - dev id)
  imgPreviewData: null,
  menuIconPosition: 0,
  addCardUrl: '',
}

export const appReducer = (
  state = initialState,
  action: AppReducerActionsType
): InitialStateType => {
  switch (action.type) {
    case APP_SET_STATUS:
      return { ...state, appStatus: action.status }
    case APP_SET_ERROR:
      return { ...state, appError: action.payload }
    case APP_SET_SUCCESS:
      return { ...state, appSuccess: action.payload }
    case APP_SET_INFO:
      return { ...state, appInfo: action.payload }
    case APP_SET_COOKIE_MSG:
      return { ...state, appUseCookies: action.payload }
    case APP_SET_FORGOT_PASSWORD_STATUS:
      return { ...state, forgotPasswordStatus: action.status }
    case APP_SET_IS_INITIALIZED:
      return { ...state, isAppInitialized: action.value }
    case APP_SET_ADMIN_PANEL_TAB:
      return { ...state, activeAdminPanelTab: action.tab }
    case APP_SET_BUYER_ACCOUNT_TAB:
      return { ...state, activeBuyerPanelTab: action.tab }
    case APP_SET_USER_ACCOUNT_TAB:
      return { ...state, activeUserAccountTab: action.tab }
    case APP_SET_ROOT_MODAL_DATA:
      return {
        ...state,
        rootModal: {
          isOpen: action.payload.isOpen,
          modalType: action.payload.modalType,
          direction: action.payload.direction,
        },
      }
    case APP_SET_PRIVACY_POLICY:
      return { ...state, privacyPolicy: action.payload }
    case APP_SET_OFFER_AGREEMENT:
      return { ...state, offerAgreement: action.payload }
    case APP_SET_FAQ:
      return { ...state, faq: action.payload }
    case APP_SET_REQUISITES:
      return { ...state, requisites: action.payload }
    case APP_SET_PAYMENT_AND_REFUND:
      return { ...state, paymentAndRefund: action.payload }
    case APP_SET_USER_AGREEMENT:
      return { ...state, userAgreement: action.payload }
    case APP_SET_ANNEX_DOCUMENT:
      return { ...state, annexDocument: action.payload }
    case APP_SET_PREVIEW_STATUS:
      return { ...state, previewStatus: action.payload }
    case SET_IMG_PREVIEW_DATA:
      return { ...state, imgPreviewData: action.payload }
    case SET_MENU_ICON_POSITION:
      return { ...state, menuIconPosition: action.position }
    case SET_ADD_CARD_URL:
      return { ...state, addCardUrl: action.url }
    default:
      return state
  }
}

import React, { ReactNode } from 'react'

import styles from './FilterComponent.module.scss'

import { ReactComponent as Arrow } from 'assets/icons/svgIcons/arrowDownIcon.svg'
import { PopupCheckBoxType } from 'types/popup-types'

type StatusCheckBoxPropsType = {
  openCheckboxHandler: (e: React.MouseEvent<HTMLDivElement>, type: PopupCheckBoxType) => void
  title: string
  icon?: ReactNode
  type: PopupCheckBoxType
  isActive: boolean
  className?: string
  titleClassName?: string
}

export const FilterComponent = (props: StatusCheckBoxPropsType) => {
  const { openCheckboxHandler, title, icon, type, isActive, className, titleClassName } = props

  const containerStyles = `${className} ${styles.statusFilter}  ${
    isActive ? styles.activeStatusFilter : ''
  }`

  const titleStyles = `${titleClassName} ${styles.title}`

  return (
    <div className={containerStyles} onClick={e => openCheckboxHandler(e, type)}>
      <div className={styles.statusTitleBox}>
        {icon}
        <div className={titleStyles}>{title}</div>
      </div>
      <Arrow className={`${styles.icon} ${isActive ? styles['is-active'] : ''}`} />
    </div>
  )
}

import React, { useEffect, useState } from 'react'

import { Skeleton } from '@mui/material'

import { PreviewStatusType } from '../../../app/store/appReducer/types'
import imgPlaceholder from '../../../assets/icons/imgPlaceholder_60.png'
import ProductType from '../../../types/product-type'
import NumberInput from '../../ui/Input/NumberInput'
import { formatSum } from '../../utils/format-sum'
import { LongLoadingTip } from '../LongLoadingTip/LongLoadingTip'

import styles from './AddGoods.module.scss'

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg'
import { Button, Image, Input } from 'common/ui'

type Props = {
  className?: string
  id: string
  ref?: any
  product: ProductType
  addToCart: (product: ProductType) => void
  clearInputHandler?: () => void
  clearInputHandlerAsync?: () => void
  previewStatus: PreviewStatusType
  isLoading: boolean
}

const AddGoods = (props: Props) => {
  const { className, product, addToCart, id, clearInputHandler, previewStatus, isLoading } = props

  const [productState, setProductState] = useState<ProductType | null>()
  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [isTablet, setIsTablet] = useState<boolean>(false)

  useEffect(() => {
    if (product) {
      const currentProduct = {
        productId: product?.productId,
        productLink: product?.productLink || '',
        productImg: product?.productImg,
        productName: product?.productName,
        productColor: product?.productColor,
        productSize: product?.productSize,
        productPrice: {
          rubles: product?.productPrice.rubles,
          euro: product?.productPrice.euro,
        },
        productQuantity: product?.productQuantity,
        productComment: product?.productComment,
        brandType: product?.brandType,
        item: product?.item,
      }

      setProductState(currentProduct)
    }
  }, [product])

  const addProductHandler = async () => {
    if (productState) {
      await addToCart(productState)
    }
  }

  const closeProductHandler = () => {
    setProductState(null)
    clearInputHandler && clearInputHandler()
  }

  const setProductHandler = (updatedValues: Partial<ProductType>) => {
    //@ts-ignore
    setProductState(prevState => {
      return {
        ...prevState,
        ...updatedValues,
      }
    })
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768)
      setIsTablet(window.innerWidth <= 1024)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const productImg = productState?.productImg ? productState.productImg : imgPlaceholder

  return (
    <div
      className={`${styles.block} 
      ${className || ''} 
      ${previewStatus !== 'hide' ? styles['block--shown'] : ''} `}
      id={id}
      ref={props.ref}
    >
      {previewStatus === 'fetching' && !isTablet && (
        <LongLoadingTip isLoading={previewStatus === 'fetching'} />
      )}
      <div className={styles.productPreview}>
        <div className={styles.avatar}>
          {isLoading ? (
            <Skeleton variant="rectangular" className={styles.skeleton} width={76} height={76} />
          ) : (
            <Image src={productImg} />
          )}
        </div>

        <div className={styles.product}>
          {isLoading ? (
            <>
              <Skeleton variant="rectangular" className={styles.skeleton} width={160} height={15} />
              <Skeleton variant="rectangular" className={styles.skeleton} width={105} height={15} />
            </>
          ) : (
            <>
              <div className={styles.productName}>
                <p>{productState?.productName}</p>
              </div>

              <div className={styles.productSum}>
                <span className={styles.sumRu}>
                  {formatSum(productState?.productPrice.rubles)}₽
                </span>

                <span className={styles.sumPound}>
                  €{formatSum(productState?.productPrice.euro)}
                </span>
              </div>
            </>
          )}
        </div>
      </div>
      <div className={styles.actions}>
        <div className={styles.actionsBox}>
          {isLoading ? (
            <Skeleton
              variant="rectangular"
              className={styles.skeleton}
              width={isTablet ? 70 : 120}
              height={44}
            />
          ) : (
            <Input
              size="small"
              variant="default"
              hasLoad={false}
              placeholder={'Цвет'}
              value={productState?.productColor}
              onInput={value => setProductHandler({ productColor: value })}
            />
          )}

          {isLoading ? (
            <Skeleton
              variant="rectangular"
              className={styles.skeleton}
              width={isTablet ? 70 : 120}
              height={44}
            />
          ) : (
            <Input
              size="small"
              variant="default"
              hasLoad={false}
              placeholder={'S, M, L, XL, XXL'}
              value={productState?.productSize}
              onInput={value => setProductHandler({ productSize: value })}
            />
          )}

          {isLoading ? (
            <Skeleton
              variant="rectangular"
              className={styles.skeleton}
              width={isTablet ? 70 : 120}
              height={44}
            />
          ) : (
            <NumberInput
              hasLoad={false}
              value={productState?.productQuantity}
              onInput={value => setProductHandler({ productQuantity: value })}
            />
          )}
        </div>

        {isLoading ? (
          <Skeleton
            variant="rectangular"
            className={styles.skeleton}
            width={isMobile ? 120 : 190}
            height={44}
          />
        ) : (
          <Button
            className={styles.addGoodsBtn}
            size="base"
            variant="default"
            type="button"
            hasLoad={previewStatus === 'adding'}
            onClick={addProductHandler}
          >
            {isMobile ? 'В корзину' : 'Добавить в корзину'}
          </Button>
        )}
      </div>
      <div className={styles.closeBtn} onClick={closeProductHandler}>
        <CloseIcon />
      </div>
    </div>
  )
}

export default AddGoods

//types ====================================================================================================

import React from 'react'

import styles from './order-info.module.scss'

import { CurrentOrderType } from 'app/store'
import { ReactComponent as DeliveryAddressIcon } from 'assets/icons/svgIcons/deliveryAddressIcon.svg'

type DeliveryComponentPropsType = {
  orderData: CurrentOrderType | null
}
export const DeliveryComponent = (props: DeliveryComponentPropsType) => {
  const { orderData } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <DeliveryAddressIcon />
        <span>адрес доставки</span>
      </div>
      <div className={styles.itemDescription}>
        <span>
          {orderData?.deliveryAddress?.address ? `${orderData.deliveryAddress.address}` : null}
          {orderData?.deliveryAddress?.flatNumber
            ? `, квартира ${orderData.deliveryAddress.flatNumber}`
            : null}
          {orderData?.deliveryAddress?.entrance
            ? `, подъезд ${orderData.deliveryAddress.entrance}`
            : null}
          {orderData?.deliveryAddress?.floor ? `, этаж ${orderData.deliveryAddress.floor}` : null}
          {orderData?.deliveryAddress?.intercom
            ? `, домофон ${orderData.deliveryAddress.intercom}`
            : null}
        </span>
      </div>
    </div>
  )
}

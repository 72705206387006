import {
  AdminStatusType,
  CurrentOrderType,
  GenderType,
  IFile,
  OrderType,
  RangeType,
  ReturnStatusType,
  RootStatusType,
  TrackNumbersType,
  UserRolesType,
  WarehouseType,
} from '../../app/store'
import {
  CommunicationType,
  DeliveryAddressType,
  RootAdminStatusType,
  UserPassportDataType,
} from '../../app/store/adminReducer/types'
import CartType from '../../types/cart-type'
import { BasicResponse } from '../api-type'
import { instance } from '../instance'

export const adminOrdersAPI = {
  getOrders(payload: GetOrdersRequestType) {
    return instance.get<GetOrdersResponseType>('/admin/orders', { params: payload })
  },
  getPaymentOrders(payload: GetPaymentOrdersRequestType) {
    return instance.get<GetPaymentOrdersResponseType>('/admin/orders/payment', { params: payload })
  },
  getUserCurrentOrder(orderId: number) {
    return instance.get<CurrentOrderResponseType>(`/admin/orders/id`, {
      params: { orderId },
    })
  },
  returnOrderToManager(payload: ReturnOrderToManagerRequestType) {
    return instance.put<BasicResponse>('/admin/orders/back', payload)
  },
  sendOrderForPayment(orderId: number, warehouseId: number) {
    return instance.put<BasicResponse>('/admin/orders/status/pay', {
      orderId: orderId,
      warehouseId: warehouseId,
    })
  },
  changeOrderStatus(payload: ChangeOrderStatusRequestType) {
    return instance.put<BasicResponse>('/admin/orders/status', payload)
  },
  deleteOrder(orderId: string) {
    return instance.delete<BasicResponse>('/admin/orders/id', {
      params: { orderId: orderId },
    })
  },
  editOrder(payload: CurrentOrderType) {
    return instance.put<BasicResponse>('/admin/orders/id', payload)
  },
}

//TYPES================================================

export type GetOrdersRequestType = {
  pageSize: number
  page: number
  fromDate?: string // когда выбираем дату с какого числа прислать заказы 22.03.2022
  toDate?: string // по какое число, если этих дат нет то присылаем все заказы
  orderStatus?: RootStatusType // сортируем по статусу заказа, если нет статуса, то получаем все заказы
  searchValue?: string
  prices?: RangeType | null
  gender?: GenderType | null
}

export type GetOrdersResponseType = BasicResponse & {
  profit: string
  revenue: string
  orders: {
    current_page: number
    last_page: number // используем как общее количество страниц (pagesCount)
    per_page: number // кол-во элементов на стр (pageSize)
    total: number // кол-во заказов с фильтром/поиском
    data: OrderType[]
  }
}

export type CurrentOrderResponseType = BasicResponse & {
  adminStatus: AdminStatusType
  communicationType: CommunicationType
  communicationLink: string
  deliveryAddress: DeliveryAddressType
  discountedSum: number
  cart: CartType
  isCallNeeded: boolean
  maintenanceAdminName: string
  maintenanceManagerId: number
  maintenanceManagerName: string
  orderDate: string
  orderId: number
  orderSum: number
  paymentMethod: string
  userDiscount: number
  userEmail: string
  userId: number
  userName: string
  userPassportData: UserPassportDataType
  userPhone: string
  trackingNumber: string | null
  deliveryServiceName: string | null
  deliveryMethod: string
  trackNumbers: TrackNumbersType
  files: Array<IFile>
  payerUserRole: UserRolesType
  payerName: string
  warehouse: WarehouseType
  cancelNote?: string
  returnNote: string
  buyerRejectNote: string
  payerId?: number
}

export type GetPaymentOrdersRequestType = {
  pageSize?: number
  page?: number
  searchValue?: string
}
export type GetPaymentOrdersResponseType = {
  statusCode: number
  message: string
  totalInPaymentCount: number
  paymentOrders: {
    current_page: number
    last_page: number // используем как общее количество страниц (pagesCount)
    per_page: number // кол-во элементов на стр (pageSize)
    total: number // кол-во заказов с фильтром/поиском
    data: OrderType[]
  }
}

export type ReturnOrderToManagerRequestType = {
  orderId: number
  returnNote: string
}

export type ChangeOrderStatusRequestType = {
  orderId: number
  adminStatus: RootAdminStatusType
  returnStatus?: ReturnStatusType
  cancelNote?: string
}

import React from 'react'

import { useFormik } from 'formik'

import { useAppDispatch, useAppSelector } from '../../../../hooks/customHooks'
import { Button, Input, Switch } from '../../../../ui'

import styles from './UserPassportModal.module.scss'

import {
  addPassportDataTC,
  changePassportDataTC,
  ModalType,
  removePassportTC,
  selectedGenders,
} from 'app/store'
import { getValidScheme } from 'common'
import { GenderRadio } from 'common/components'
import UserPassport from 'types/user-passport'

type UserPassportModalPropsType = {
  modalType: ModalType
  editPassport: UserPassport | null
}
export const UserPassportModal = (props: UserPassportModalPropsType) => {
  const { modalType, editPassport } = props
  const dispatch = useAppDispatch()
  const isEdit = modalType === 'editPassport'
  const title = isEdit ? 'редактировать паспорт' : 'добавить паспорт'

  const genders = useAppSelector(selectedGenders)

  const formik = useFormik({
    initialValues: {
      id: isEdit && editPassport ? editPassport.id : null,
      // country: isEdit && editPassport ? editPassport.country : '',
      country: 'Российская Федерация',
      number: isEdit && editPassport ? editPassport.number : '',
      issueDate: isEdit && editPassport ? editPassport.issueDate : '',
      unitCode: isEdit && editPassport ? editPassport.unitCode : '',
      whoGave: isEdit && editPassport ? editPassport.whoGave : '',
      birthdate: isEdit && editPassport ? editPassport.birthdate : '',
      gender: isEdit && editPassport ? editPassport.gender : undefined,
      isDefault: isEdit && editPassport ? editPassport.isDefault : false,
    },
    validationSchema: getValidScheme(modalType),
    onSubmit: values => {
      if (isEdit) {
        dispatch(changePassportDataTC(values))
      } else {
        dispatch(addPassportDataTC(values))
      }
    },
  })

  const isDisabledAddBtn = !formik.isValid || !formik.dirty

  const deleteHandler = () => {
    if (editPassport?.id) dispatch(removePassportTC(editPassport.id))
  }

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  return (
    <form className={styles.box}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>
        <div className={styles.form}>
          <Input
            value={formik.values.country}
            onChange={formik.handleChange}
            name={'country'}
            size="large"
            variant="default"
            type={'text'}
            label={'страна'}
            hasError={formik.touched.country && !!formik.errors.country}
            errorText={formik.errors.country}
            setFormikFieldValue={formik.setFieldTouched}
            disabled={true}
          />
          <div className={styles.container}>
            <Input
              value={formik.values.number}
              onChange={formik.handleChange}
              name={'number'}
              size="large"
              variant="default"
              label={'серия и номер'}
              mask={'____ ______'}
              hasError={formik.touched.number && !!formik.errors.number}
              errorText={formik.errors.number}
              setFormikFieldValue={formik.setFieldTouched}
            />
            <Input
              value={formik.values.issueDate}
              onChange={formik.handleChange}
              name={'issueDate'}
              size="large"
              variant="default"
              label={'когда выдан'}
              mask={'__.__.____'}
              hasError={formik.touched.issueDate && !!formik.errors.issueDate}
              errorText={formik.errors.issueDate}
              setFormikFieldValue={formik.setFieldTouched}
            />
          </div>
          <Input
            name={'unitCode'}
            value={formik.values.unitCode}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            mask={'___-___'}
            label={'код подразделения'}
            hasError={formik.touched.unitCode && !!formik.errors.unitCode}
            errorText={formik.errors.unitCode}
            setFormikFieldValue={formik.setFieldTouched}
          />
          <Input
            name={'whoGave'}
            size="large"
            variant={'default'}
            label={'кем выдан'}
            value={formik.values.whoGave}
            onChange={formik.handleChange}
            hasError={formik.touched.whoGave && !!formik.errors.whoGave}
            errorText={formik.errors.whoGave}
            setFormikFieldValue={formik.setFieldTouched}
          />
          <Input
            value={formik.values.birthdate}
            name={'birthdate'}
            size="large"
            variant={'default'}
            label={'дата рождения'}
            onChange={formik.handleChange}
            mask={'__.__.____'}
            hasError={formik.touched.birthdate && !!formik.errors?.birthdate}
            errorText={formik.errors.birthdate}
            setFormikFieldValue={formik.setFieldTouched}
            onBlur={() => formik.setFieldTouched('gender', true)}
          />
          <GenderRadio
            genders={genders}
            currentGender={formik.values.gender}
            name={modalType ?? ''}
            onChange={(gender: string) => formik.setFieldValue('gender', gender)}
            hasError={!!formik.errors?.gender && formik.touched?.gender}
            errorText={formik.errors?.gender}
          />
          <div className={styles.switch}>
            <Switch
              name={'isDefault'}
              value={formik.values.isDefault}
              onFormikChange={formik.handleChange}
            />
            <span>паспорт по-умолчанию</span>
          </div>
        </div>
        <div className={styles.btnBox}>
          <Button
            mod="fluid"
            size="large"
            variant="default"
            type={'submit'}
            onClick={handleSubmit}
            disabled={isDisabledAddBtn}
          >
            {isEdit ? 'сохранить изменения' : 'добавить паспорт'}
          </Button>
          {isEdit && (
            <Button
              className={styles.deleteBtn}
              mod="fluid"
              size="large"
              variant="transparent"
              type="button"
              onClick={deleteHandler}
            >
              удалить паспорт
            </Button>
          )}
        </div>
      </div>
    </form>
  )
}

import { AxiosError } from 'axios'

import { setAppStatusAC, setAppSuccessAC } from '../appReducer/actions'
import { AppThunkType } from '../types'

import { setAdminSettingsAC } from './actions'
import { checkAdminAuthTC } from './thanks'
import { SettingsType } from './types'

import { adminSettingsAPI } from 'api/adminAPI/settings-api'
import { errorUtils } from 'common'

export const getSettingsTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))

  try {
    const response = await adminSettingsAPI.getSettings()

    if (response.data.statusCode === 0) {
      const {
        payerDeliveryCost,
        referralDiscountRate,
        referrerDiscountRate,
        serviceProfitRate,
        serviceFee,
        bankPayoutProfitRate,
        bankPaymentCardProfitRate,
        bankPaymentSbpProfitRate,
        swiftAdjustmentRate,
        canEdit,
      } = response.data

      const payload: SettingsType = {
        payerDeliveryCost,
        referralDiscountRate,
        referrerDiscountRate,
        serviceProfitRate,
        serviceFee,
        bankPayoutProfitRate,
        bankPaymentCardProfitRate,
        bankPaymentSbpProfitRate,
        swiftAdjustmentRate,
        canEdit,
      }

      dispatch(setAdminSettingsAC(payload))

      dispatch(setAppStatusAC('succeeded'))
    }
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkAdminAuthTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const updateSettingsTC =
  (payload: SettingsType): AppThunkType =>
  async (dispatch, getState) => {
    const prevSettings = getState().admin.settings

    dispatch(setAppStatusAC('loading'))
    try {
      const response = await adminSettingsAPI.updateSettings(payload)

      if (response.data.statusCode === 0) {
        dispatch(setAdminSettingsAC(payload))
        // dispatch(getSettingsTC())
        dispatch(setAppSuccessAC({ type: 'success', title: 'данные изменены' }))
        dispatch(setAppStatusAC('succeeded'))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      dispatch(setAdminSettingsAC(prevSettings))

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

import { AxiosError } from 'axios'

import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import { setUsersByCharacteristicsAC, setUsersByRoleAC } from '../filterReducer/actions'
import { AppThunkType } from '../types'

import {
  setCurrentPageAC,
  setEmployeeDataAC,
  setPagesCountAC,
  setPagesSizeAC,
  setTotalEmployeeCountAC,
} from './actions'
import { checkAdminAuthTC } from './thanks'
import {
  AddUserPayloadType,
  ChangeAccountStatusPayloadType,
  DeleteUserPayloadType,
} from './thunk-types'

import {
  AddEmployeeRequestType,
  adminEmployeeAPI,
  GetEmployeeRequestType,
  UpdateEmployeeRequestType,
} from 'api/adminAPI/adminEmployeeAPI'
import { errorUtils } from 'common'
import { stuffPC } from 'features/AdminPanel/admin/helpers'

export const getEmployeeDataTC =
  (value: GetEmployeeRequestType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))

    try {
      const response = await adminEmployeeAPI.getEmployee(value)

      if (response.data.statusCode === 0) {
        const employeeData = response.data.users.data

        dispatch(setEmployeeDataAC(employeeData))
        dispatch(setUsersByRoleAC(response.data.stat.byRoles))
        dispatch(setUsersByCharacteristicsAC(response.data.stat.byCharacteristics))
        dispatch(setTotalEmployeeCountAC(response.data.users.total))
        dispatch(setCurrentPageAC(response.data.users.current_page))
        dispatch(setPagesCountAC(response.data.users.last_page))
        dispatch(setPagesSizeAC(response.data.users.per_page))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const changeEmployeeDataTC =
  (payload: ChangeAccountStatusPayloadType): AppThunkType =>
  async (dispatch, getState) => {
    const pageSize = getState().admin.pageSize
    const currentPage = getState().admin.currentPage

    const changePayload: UpdateEmployeeRequestType = {
      userId: payload.userId,
      role: payload.role,
      userName: payload.userName,
      userEmail: payload.userEmail,
      blocked: payload.blocked,
      priceMin: payload.priceMin,
      priceMax: payload.priceMax,
      userPassword: payload.userPassword,
    }

    try {
      dispatch(setAppStatusAC('loading'))
      const response = await adminEmployeeAPI.editEmployee(changePayload)

      if (response.data.statusCode === 0) {
        const getPayload: GetEmployeeRequestType = stuffPC(
          {
            pageSize,
            page: currentPage,
          },
          payload.fromDate,
          payload.toDate,
          payload.filterAccountStatus,
          payload.filterUserRole,
          payload.filteredUserCharacteristic,
          payload.searchValue,
          payload.filteredGender,
          payload.filteredAges,
          payload.filteredPrices
        )

        dispatch(getEmployeeDataTC(getPayload))
        dispatch(setAppSuccessAC({ type: 'success', title: 'данные сотрудника изменены' }))

        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const addNewEmployeeTC =
  (payload: AddUserPayloadType): AppThunkType =>
  async (dispatch, getState) => {
    const pageSize = getState().admin.pageSize
    const currentPage = getState().admin.currentPage

    try {
      dispatch(setAppStatusAC('loading'))

      const addPayload: AddEmployeeRequestType = {
        role: payload.role,
        userName: payload.userName,
        userEmail: payload.userEmail,
        userPassword: payload.userPassword,
        priceMin: payload.priceMin,
        priceMax: payload.priceMax,
      }

      const response = await adminEmployeeAPI.addEmployee(addPayload)

      if (response.data.statusCode === 0) {
        const getPayload: GetEmployeeRequestType = stuffPC(
          {
            pageSize,
            page: currentPage,
          },
          payload.fromDate,
          payload.toDate,
          payload.filterAccountStatus,
          payload.filterUserRole,
          payload.filteredUserCharacteristic,
          payload.searchValue,
          payload.filteredGender,
          payload.filteredAges,
          payload.filteredPrices
        )

        dispatch(getEmployeeDataTC(getPayload))
        dispatch(setAppSuccessAC({ type: 'success', title: 'сотрудник добавлен' }))

        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const deleteEmployeeTC =
  (payload: DeleteUserPayloadType): AppThunkType =>
  async (dispatch, getState) => {
    const pageSizes = getState().admin.pageSize
    const currentPage = getState().admin.currentPage

    try {
      dispatch(setAppStatusAC('loading'))

      const response = await adminEmployeeAPI.deleteEmployee(payload.userId)

      if (response.data.statusCode === 0) {
        const getPayload: GetEmployeeRequestType = stuffPC(
          {
            pageSize: pageSizes,
            page: currentPage,
          },
          payload.fromDate,
          payload.toDate,
          payload.filterAccountStatus,
          payload.filterUserRole,
          payload.filteredUserCharacteristic,
          payload.searchValue,
          payload.filteredGender,
          payload.filteredAges,
          payload.filteredPrices
        )

        dispatch(getEmployeeDataTC(getPayload))
        dispatch(setAppSuccessAC({ type: 'success', title: 'сотрудник удален' }))

        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
        dispatch(setAppStatusAC('failed'))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

import React from 'react'

import { useFormik } from 'formik'
import { Link } from 'react-router-dom'

import { useAppDispatch } from '../../../../hooks/customHooks'
import { Button, Checkbox, Input, PasswordInput } from '../../../../ui'

import styles from './AuthorizationModalsStyle.module.scss'

import { PATH } from 'app/routes/AppRoutes'
import { ModalType, setRootModalDataAC, userRegistrationTC } from 'app/store'
import { getValidScheme } from 'common'

type PropsType = {
  page: 'main' | 'ordering'
  modalType: ModalType
}
export const RegistrationModal = ({ page, modalType }: PropsType) => {
  const dispatch = useAppDispatch()

  const formik = useFormik({
    initialValues: {
      userFullName: '',
      userEmail: '',
      userPhone: '',
      userPassword: '',
      confirmPassword: '',
      agreeWithPrivacyPolicy: false,
    },
    validationSchema: getValidScheme(modalType),
    onSubmit: values => {
      dispatch(userRegistrationTC(values))
    },
  })

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  let disabledButton =
    !formik.isValid ||
    formik.values.userFullName.length === 0 ||
    formik.values.userEmail.length === 0 ||
    formik.values.userPhone.length === 0 ||
    formik.values.userPassword.length === 0 ||
    formik.values.confirmPassword.length === 0 ||
    !formik.values.agreeWithPrivacyPolicy

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.registrationForm}>
        <form className={styles.modalBox} autoComplete="off">
          <div className={styles.header}>
            {page === 'main' ? (
              <b>регистрация</b>
            ) : (
              <b>
                Зарегистрируйтесь, <br /> чтобы оформить заказ
              </b>
            )}
            <span
              onClick={() =>
                dispatch(
                  setRootModalDataAC({ isOpen: true, modalType: 'login', direction: 'right' })
                )
              }
            >
              у меня уже есть аккаунт
            </span>
          </div>

          <div className={styles.content}>
            <form className={styles.form} autoComplete={'off'}>
              <Input
                name={'userFullName'}
                size={'base'}
                variant={'default'}
                label={'имя и фамилия'}
                value={formik.values.userFullName}
                onChange={formik.handleChange}
                hasError={!!formik.errors.userFullName && formik.touched.userFullName}
                errorText={formik.errors.userFullName}
                onKeyDown={e => onKeyDown(e)}
                setFormikFieldValue={formik.setFieldTouched}
                autoComplete={'off'}
              />

              <Input
                name={'userEmail'}
                size={'base'}
                variant={'default'}
                label={'почта'}
                type={'email'}
                value={formik.values.userEmail}
                onChange={formik.handleChange}
                hasError={formik.touched.userEmail && !!formik.errors.userEmail}
                errorText={formik.errors.userEmail}
                onKeyDown={e => onKeyDown(e)}
                setFormikFieldValue={formik.setFieldTouched}
                autoComplete={'off'}
              />
              <Input
                name={'userPhone'}
                size={'base'}
                variant={'default'}
                label={'телефон'}
                mask={'+_ (___) ___-__-__'}
                value={formik.values.userPhone}
                onChange={formik.handleChange}
                hasError={!!formik.touched.userPhone && !!formik.errors.userPhone}
                errorText={formik.errors.userPhone}
                onKeyDown={e => onKeyDown(e)}
                setFormikFieldValue={formik.setFieldTouched}
                autoComplete={'off'}
              />

              <PasswordInput
                name={'userPassword'}
                value={formik.values.userPassword}
                label={'придумайте пароль'}
                onChange={formik.handleChange}
                hasError={!!formik.errors.userPassword && formik.touched.userPassword}
                errorText={formik.errors.userPassword}
                onKeyDown={e => onKeyDown(e)}
                setFormikFieldValue={formik.setFieldTouched}
                autoComplete={'new-password'}
              />

              <PasswordInput
                name={'confirmPassword'}
                value={formik.values.confirmPassword}
                label={'повторите пароль'}
                onChange={formik.handleChange}
                hasError={!!formik.errors.confirmPassword && formik.touched.confirmPassword}
                errorText={formik.errors.confirmPassword}
                onKeyDown={e => onKeyDown(e)}
                setFormikFieldValue={formik.setFieldTouched}
                autoComplete={'new-password'}
              />
            </form>
          </div>

          <div className={styles.submitBtn}>
            <Button
              mod="fluid"
              size="large"
              variant="default"
              type="submit"
              onClick={handleSubmit}
              disabled={disabledButton}
            >
              зарегистрироваться
            </Button>

            <div className={styles.privacyPolicy}>
              <Checkbox
                id={'agreeWithPrivacyPolicy'}
                name={'agreeWithPrivacyPolicy'}
                value={formik.values.agreeWithPrivacyPolicy}
                onFormikChange={formik.handleChange}
              />
              <div className={styles.agreementText}>
                <Link to={PATH.PUBLIC_OFFER} target="_blank" rel="noopener noreferrer">
                  Я ознакомился и согласен с условиями Пользовательского соглашения и приложений к
                  нему, включая Общие условия агентского договора по приобретению и доставке
                  товаров, Правилами совершения платежей и возврата оплаты, Политикой обработки
                  персональных данных и файлов Cookie
                </Link>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

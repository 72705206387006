import { AxiosError } from 'axios'

import {
  setAddCardUrlAC,
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
} from '../appReducer/actions'
import { setBuyerCardsDataAC } from '../buyerReducer/actions'
import { AppThunkType } from '../types'

import { setCardsDataAC } from './actions'
import { checkAdminAuthTC } from './thanks'

import { adminCardsAPI, ChangeDefaultCardPayloadType } from 'api/adminAPI/admin-cards-api'
import { errorUtils } from 'common'

export const getCardsDataTC = (): AppThunkType => async (dispatch, getState) => {
  dispatch(setAppStatusAC('loading'))
  const userRole = getState().admin.userData.role

  try {
    const response = await adminCardsAPI.getCards()

    if (response.data.statusCode === 0) {
      const cards = response.data.cards

      if (userRole === 'Admin') {
        dispatch(setCardsDataAC(cards))
      } else {
        dispatch(setBuyerCardsDataAC(cards))
      }
    } else {
      dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

      dispatch(setAppStatusAC('failed'))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkAdminAuthTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const changeDefaultCardTC =
  (payload: ChangeDefaultCardPayloadType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))

    try {
      const response = await adminCardsAPI.changeDefaultCard(payload)

      if (response.data.statusCode === 0) {
        dispatch(getCardsDataTC())

        dispatch(setAppSuccessAC({ type: 'success', title: 'карта по умолчанию изменена' }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const addNewCardTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))

  dispatch(setAddCardUrlAC(''))
  try {
    const response = await adminCardsAPI.addNewCard()

    if (response.data.statusCode === 0) {
      dispatch(setAddCardUrlAC(response.data.url))
    } else {
      dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

      dispatch(setAppStatusAC('failed'))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkAdminAuthTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const deleteCardTC =
  (cardId: string): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))

    try {
      const response = await adminCardsAPI.deleteCard(cardId)

      if (response.data.statusCode === 0) {
        dispatch(getCardsDataTC())

        dispatch(setAppSuccessAC({ type: 'success', title: 'карта удалена' }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

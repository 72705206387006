import React from 'react'

import { Skeleton } from '@mui/material'

import styles from './MobileViewAdminUserComponent.module.scss'

export const SkeletonMobileView = () => {
  const skeletonArray = [1, 2]

  const skeletons = skeletonArray.map((el, index) => {
    const items = Array.from({ length: 6 }, (_, i) => i)

    const skeletonItems = items.map((item, index) => {
      const opacity = 0.8 - index * 0.15

      return (
        <div key={item} className={styles.contentItem}>
          <div className={styles.itemName}>
            <Skeleton
              sx={{ opacity: opacity }}
              animation={false}
              variant="rounded"
              height={16}
              width={100}
            />
          </div>
          <div className={styles.itemValue}>
            <Skeleton
              sx={{ opacity: opacity }}
              animation={false}
              variant="rounded"
              height={16}
              min-width={180}
              width={'100%'}
            />
          </div>
        </div>
      )
    })

    const opacity = 0.8

    const borderColor = `1px solid rgba(20, 24, 36, ${opacity * 0.15})`

    return (
      <div key={index} className={styles.userBox} style={{ border: borderColor }}>
        <div className={styles.header}>
          <div className={styles.userName}>
            <Skeleton
              sx={{ opacity: opacity }}
              animation={false}
              variant="rounded"
              height={16}
              width={100}
            />
          </div>
        </div>
        <div className={styles.contentBox}>{skeletonItems}</div>
        <Skeleton
          sx={{ opacity: opacity * 0.15 }}
          animation={false}
          variant="rounded"
          height={36}
          width={'100%'}
        />
      </div>
    )
  })

  return <>{skeletons}</>
}

import { BasicResponse } from '../api-type'
import { instance } from '../instance'

import CartType from 'types/cart-type'
import DeliveryAddress from 'types/delivery-address'
import ProductType from 'types/product-type'
import UserData from 'types/user-data'
import UserPassport from 'types/user-passport'

export const cartAPI = {
  addProduct(product: ProductType) {
    return instance.post(`/cart/add-product`, product)
  },
  editProduct(product: ProductType) {
    return instance.post(`/cart/edit-product`, product)
  },
  deleteProduct(payload: DeleteProductType) {
    return instance.delete(`/cart/remove-product`, { params: payload })
  },
  getUpdatedCart(payload: UseDiscountRequestType) {
    return instance.post<UseDiscountResponseType>(`/cart`, payload)
  },
  createOrder(order: CreateOrderRequestType) {
    return instance.post<CreateOrderResponseType>(`/ordering`, order)
  },
  getCreatedOrder(orderId: string) {
    return instance.get<SuccessCreateOrderResponseType>(`/user/get-order-cart`, {
      params: { orderId },
    })
  },
  clearCart() {
    return instance.delete<BasicResponse>(`/cart/clear`)
  },
}

// types ============================================================
export type DeleteProductType = {
  productId: string
  brandType: string
}

export type UseDiscountRequestType = {
  isDiscount?: boolean
  cart: CartType
}
export type UseDiscountResponseType = BasicResponse & {
  totalRubSum: number
  deliveryCost: number
  cart: CartType
  isDiscount: boolean
  serviceFee: number
}
export type CreateOrderRequestType = {
  address: DeliveryAddress
  userData: UserData
  userPassport: UserPassport
  cart: CartType
  isUseDiscount: boolean
  deliveryMethod: 'courier' | 'issuePoint'
}
export type CreateOrderResponseType = BasicResponse & {
  cart: CartType
  orderId: number
  paymentUrl: string
}
export type SuccessCreateOrderResponseType = BasicResponse & {
  orderId: number
  cart: CartType
}

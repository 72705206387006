import React from 'react'

import styles from './ReturnDescriptionComponent.module.scss'

import { UserRolesType } from 'app/store'
import { CustomAvatar } from 'common/components'

export const ReturnDescriptionComponent = ({
  orderNote,
  name,
  role,
}: ReturnDescriptionComponentPropsType) => {
  return (
    <div className={styles.noteBox}>
      <div className={styles.note}>{orderNote}</div>
      <CustomAvatar name={name} role={role} />
    </div>
  )
}

type ReturnDescriptionComponentPropsType = {
  orderNote: string
  name: string | undefined | null
  role: UserRolesType
}

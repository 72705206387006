import React from 'react'

import styles from './CheckBoxWrapper.module.scss'

type CheckBoxWrapperPropsType = {
  children: React.ReactNode
  reset?: () => void
  className?: string
  isReset: boolean
}

export const CheckBoxWrapper = (props: CheckBoxWrapperPropsType) => {
  const { children, reset, className, isReset } = props

  const containerStyles = className ? `${styles.container} ${className}` : styles.container
  const formStyles = `${styles.formItemBox} ${isReset ? styles['is-reset'] : ''}`

  return (
    <div className={containerStyles}>
      <form className={formStyles}>{children}</form>
      {isReset && (
        <div className={styles.button} onClick={reset}>
          <span>сбросить фильтр</span>
        </div>
      )}
    </div>
  )
}

import React from 'react'

import Radio from '../../ui/Radio/Radio'

import styles from './GenderRadio.module.scss'

import { GenderType } from 'app/store'

type GenderRadioPropsType = {
  genders: GenderType[]
  currentGender: GenderType
  name: string
  hasError?: boolean
  errorText?: string
  onChange: (value: string) => void
}

export const GenderRadio = (props: GenderRadioPropsType) => {
  const { genders, name, currentGender, hasError, onChange, errorText } = props

  const radioClasses = `${styles.radio} ${hasError ? styles['radio--error'] : ''}`

  return (
    <div className={styles.checkbox}>
      <div className={radioClasses}>
        {genders &&
          genders.map((item, index) => (
            <Radio
              key={index}
              id={`${item}-${index}-${name}`}
              value={item}
              label={item === 'male' ? 'мужской' : 'женский'}
              selected={currentGender === item}
              onChange={(gender: string) => onChange(gender)}
              labelStyles={styles.label}
            />
          ))}
      </div>
      {hasError ? <span className={styles['error-text']}>{errorText}</span> : null}
    </div>
  )
}

import React from 'react'

import TextareaAutosize from 'react-textarea-autosize'

import { Button, Input } from '../../../ui'

import styles from './DeliveryActions.module.scss'

import { AdminStatusType } from 'app/store'

type DeliveryActionsPropsType = {
  deliveryServiceName: string
  setDeliveryServiceName: (value: string) => void
  trackingNumber: string
  setTrackingNumber: (value: string) => void
  onSaveDeliveryInfo: () => void
  courierComment: string
  setCourierComment: (value: string) => void
  orderStatus: AdminStatusType
}
export const DeliveryActions = (props: DeliveryActionsPropsType) => {
  const {
    onSaveDeliveryInfo,
    deliveryServiceName,
    setDeliveryServiceName,
    trackingNumber,
    setTrackingNumber,
    courierComment,
    setCourierComment,
    orderStatus,
  } = props

  const disabled = orderStatus === 'Cancelled' || orderStatus === 'Delivered'

  return (
    <div className={styles.box}>
      <div className={styles.title}>Информация о доставке</div>
      <div className={styles.description}>
        Служба доставки и трек-номер заказа будут отображены в лк клиента
      </div>
      <div className={styles.container}>
        <div className={styles.inputs}>
          <Input
            className={styles.inputStyle}
            label="Ссылка на службу доставки"
            value={deliveryServiceName}
            onInput={setDeliveryServiceName}
            size="base"
            variant="default"
            type={'text'}
            disabled={disabled}
          />
          <Input
            className={styles.inputStyle}
            label="трек-номер заказа"
            value={trackingNumber}
            onInput={setTrackingNumber}
            size="base"
            variant="default"
            type={'text'}
            disabled={disabled}
          />
        </div>

        <TextareaAutosize
          className={styles.textarea}
          disabled={disabled}
          minRows={3}
          value={courierComment}
          placeholder={'комментарий курьеру'}
          onChange={event => setCourierComment(event.currentTarget.value)}
        />

        <Button
          className={styles.buttonStyle}
          size="base"
          variant="default"
          type="button"
          onClick={onSaveDeliveryInfo}
          disabled={disabled}
        >
          сохранить
        </Button>
      </div>
    </div>
  )
}

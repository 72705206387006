import React from 'react'

import { CustomAvatar } from '../../CustomAvatar/CustomAvatar'

import styles from './order-info.module.scss'

import { ReactComponent as ManagerIcon } from 'assets/icons/svgIcons/managerIcon.svg'

type MaintenanceComponentPropsType = {
  name: string
}

export const MaintenanceComponent = (props: MaintenanceComponentPropsType) => {
  const { name } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <ManagerIcon />
        <span>Менеджер</span>
      </div>
      <div className={styles.itemDescription}>
        <CustomAvatar name={name} role={'Manager'} />
      </div>
    </div>
  )
}

import React, { ReactNode, useEffect, useRef } from 'react'

import styles from './CustomSelect.module.scss'

import { ModalType } from 'app/store/appReducer/types'
import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down-small.svg'
import { ReactComponent as AddIcon } from 'assets/icons/svgIcons/plusIcon.svg'
import { PassportModalDataType } from 'features/Ordering/Ordering'

type SelectPassportPropsType = {
  label: string
  openModalHandler?: (type: ModalType) => void
  types: PassportModalDataType
  children: ReactNode
  actionTitle: string
  isSelectOpen: boolean
  setIsSelectOpen: (value: boolean) => void
}
export const CustomSelect = (props: SelectPassportPropsType) => {
  const { label, openModalHandler, children, types, actionTitle, isSelectOpen, setIsSelectOpen } =
    props
  // const [isOpen, setIsOpen] = useState<boolean>(false)

  const selectRef = useRef<HTMLDivElement | null>(null)

  const openHandler = () => {
    setIsSelectOpen(!isSelectOpen)
  }

  const addHandler = () => {
    openModalHandler && openModalHandler(types.addModalType)
  }

  useEffect(() => {
    const clickOutsideHandler = (event: MouseEvent) => {
      if (selectRef.current && !selectRef.current.contains(event.target as Node)) {
        setIsSelectOpen(false)
      }
    }

    document.addEventListener('click', clickOutsideHandler)

    return () => {
      document.removeEventListener('click', clickOutsideHandler)
    }
  }, [])

  return (
    <div className={styles.container} ref={selectRef} onClick={openHandler}>
      <div className={styles.box}>
        <div className={styles.label}>{label}</div>
        <ArrowDown className={`${styles.icon} ${isSelectOpen ? styles['is-active'] : ''}`} />
      </div>

      <div className={`${styles.dropdown} ${isSelectOpen ? styles['is-active'] : ''}`}>
        <div
          className={`${styles.action} ${isSelectOpen ? styles['is-active'] : ''}`}
          onClick={addHandler}
        >
          <div className={styles.title}>{actionTitle}</div>
          <div className={styles.iconBox}>
            <AddIcon className={styles.addIcon} />
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}

import { AxiosError } from 'axios'

import { setPagesSizeAC } from '../adminReducer/actions'
import { setAppErrorAC, setAppStatusAC, setAppSuccessAC } from '../appReducer/actions'
import { AppThunkType } from '../types'

import {
  setAllOrderHistoryAC,
  setAllOrdersCountAC,
  setCurrentOrderAC,
  setOrderingResponseAC,
  setReturnedOrderAC,
  setReturnedOrdersCountAC,
  setUserCurrentPageAC,
  setUserPagesCountAC,
} from './actions'
import { checkUserTC } from './thanks'
import { OrderCreatedType } from './types'

import { cartAPI, CreateOrderRequestType } from 'api/userAPI/cartAPI'
import { userAccountAPI, UserOrdersRequestType } from 'api/userAPI/userAccountAPI'
import { convertDateFormat, errorUtils, formatPhoneNumber } from 'common'
import HistoryCurrentOrderType from 'types/history-current-order-type'

export const createOrderTC =
  (payload: CreateOrderRequestType): AppThunkType =>
  async dispatch => {
    try {
      const order: CreateOrderRequestType = {
        ...payload,
        userPassport: {
          ...payload.userPassport,
          issueDate: convertDateFormat(payload.userPassport.issueDate, 'yyyy-mm-dd'),
          birthdate: convertDateFormat(payload.userPassport.birthdate, 'yyyy-mm-dd'),
        },
        userData: {
          ...payload.userData,
          userPhone: formatPhoneNumber(payload.userData.userPhone, false),
        },
      }

      dispatch(setAppStatusAC('loading'))
      const response = await cartAPI.createOrder(order)

      if (response.data.statusCode === 0) {
        dispatch(
          setOrderingResponseAC({
            orderId: response.data.orderId,
            cart: response.data.cart,
            paymentUrl: response.data.paymentUrl,
          })
        )
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getUserOrderHistoryTC =
  (payload: UserOrdersRequestType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const response = await userAccountAPI.getUserOrders(payload)

      if (response.data.statusCode === 0) {
        dispatch(setAllOrderHistoryAC(response.data.userOrderHistory.data))
        dispatch(setUserPagesCountAC(response.data.userOrderHistory.last_page))
        dispatch(setUserCurrentPageAC(response.data.userOrderHistory.current_page))
        dispatch(setPagesSizeAC(response.data.userOrderHistory.per_page))
        dispatch(setAllOrdersCountAC(response.data.userOrderHistory.total))
        dispatch(setReturnedOrdersCountAC(response.data.returnedOrdersCount))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getUserReturnedOrdersTC =
  (payload: UserOrdersRequestType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const response = await userAccountAPI.getUserReturnOrders(payload)

      if (response.data.statusCode === 0) {
        dispatch(setReturnedOrderAC(response.data.userOrderHistory.data))
        dispatch(setUserPagesCountAC(response.data.userOrderHistory.last_page))
        dispatch(setUserCurrentPageAC(response.data.userOrderHistory.current_page))
        dispatch(setPagesSizeAC(response.data.userOrderHistory.per_page))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getUserCurrentOrderTC =
  (orderId: string): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const response = await userAccountAPI.getUserCurrentOrder(orderId)

      if (response.data.statusCode === 0) {
        const order: HistoryCurrentOrderType = {
          ...response.data,
          recipient: {
            ...response.data.recipient,
            phoneNumber: formatPhoneNumber(response.data.recipient.phoneNumber, true),
          },
        }

        dispatch(setCurrentOrderAC(order))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getSuccessfulCreatedOrderTC =
  (orderId: string): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const response = await cartAPI.getCreatedOrder(orderId)

      if (response.data.statusCode === 0) {
        const order: OrderCreatedType = {
          orderId: response.data.orderId,
          cart: response.data.cart,
          paymentUrl: null,
        }

        dispatch(setOrderingResponseAC(order))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

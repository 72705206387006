import { AxiosError } from 'axios'

import { setAppErrorAC, setAppInfoAC, setAppStatusAC, setAppSuccessAC } from '../appReducer/actions'
import { AppThunkType } from '../types'

import {
  addProductAC,
  clearCartAC,
  editProductAC,
  fetchUserCartAC,
  removeProductAC,
  setDeletedProductAC,
  setDeliveryCostAC,
  setDiscountedCartAC,
  setIsUseDiscountAC,
  setServiceFeeAC,
  setTotalRubSumAC,
} from './actions'
import { checkUserTC } from './thanks'
import { ViewProductType } from './types'

import { cartAPI, DeleteProductType } from 'api/userAPI/cartAPI'
import {
  compareCartLifeTime,
  COOKIE_LIFE_TIME_NAME,
  errorUtils,
  removeCookieTime,
  setCookieTime,
} from 'common'
import { AvailableBrandEnum } from 'types/available-brand-enum'
import CartType from 'types/cart-type'
import ProductType from 'types/product-type'

export const fetchUserCartTC = (): AppThunkType => async dispatch => {
  try {
    dispatch(setAppStatusAC('loading'))
    const cartFromStorage = localStorage.getItem('cart') as string
    const viewCartFromStorage = localStorage.getItem('view-cart') as string
    const parsedCartFromStorage = (await JSON.parse(cartFromStorage)) as CartType
    const parsedViewCartFromStorage = (await JSON.parse(viewCartFromStorage)) as ViewProductType[]

    if (parsedCartFromStorage) {
      dispatch(
        fetchUserCartAC({ cart: parsedCartFromStorage, viewCart: parsedViewCartFromStorage })
      )
      dispatch(getUpdatedCartDataTC())
    }
    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkUserTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const addProductTC =
  (product: ProductType): AppThunkType =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      dispatch(setAppStatusAC('loading'))

      if (!state.user.userId || product.brandType in AvailableBrandEnum) {
        dispatch(addProductAC(product))
      } else {
        const response = await cartAPI.addProduct(product)

        if (response.data.statusCode === 0) {
          dispatch(addProductAC(product))
        }
      }
      const updatedState = getState()
      const cartJsonString = JSON.stringify(updatedState.user.cart)
      const viewCartJsonString = JSON.stringify(updatedState.user.viewCart)

      localStorage.setItem('cart', cartJsonString)
      localStorage.setItem('view-cart', viewCartJsonString)

      // Устанавливаем время в куки для последнего обновления корзины
      setCookieTime(COOKIE_LIFE_TIME_NAME, Date.now().toString(), 1)
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(setAppStatusAC('failed'))
    }
  }

export const removeProductTC =
  (product: ProductType): AppThunkType =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      dispatch(setAppStatusAC('loading'))

      if (!state.user.userId) {
        dispatch(removeProductAC(product))
        dispatch(setDeletedProductAC(product))
        dispatch(getUpdatedCartDataTC())
        dispatch(setAppInfoAC({ type: 'info', title: 'Товар удален из корзины' }))
      } else {
        const payload: DeleteProductType = {
          productId: product.productId,
          brandType: product.brandType,
        }
        const response = await cartAPI.deleteProduct(payload)

        if (response.data.statusCode === 0) {
          dispatch(removeProductAC(product))
          dispatch(setDeletedProductAC(product))
          dispatch(getUpdatedCartDataTC())
          dispatch(setAppInfoAC({ type: 'info', title: 'Товар удален из корзины' }))
        } else {
          dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
        }
      }

      const updatedState = getState()

      const cartJsonString = JSON.stringify(updatedState.user.cart)
      const viewCartJsonString = JSON.stringify(updatedState.user.viewCart)

      localStorage.setItem('cart', cartJsonString)
      localStorage.setItem('view-cart', viewCartJsonString)

      // Устанавливаем время в куки для последнего обновления корзины
      setCookieTime(COOKIE_LIFE_TIME_NAME, Date.now().toString(), 1)
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const editProductTC =
  (product: ProductType): AppThunkType =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      dispatch(setAppStatusAC('loading'))
      if (!state.user.userId) {
        dispatch(editProductAC(product))
        const updatedState = getState()

        const jsonString = JSON.stringify(updatedState.user.cart)

        localStorage.setItem('cart', jsonString)
        dispatch(getUpdatedCartDataTC())
      } else {
        const response = await cartAPI.editProduct(product)

        if (response.data.statusCode === 0) {
          dispatch(editProductAC(product))
          const updatedState = getState()

          const jsonString = JSON.stringify(updatedState.user.cart)

          localStorage.setItem('cart', jsonString)
          dispatch(getUpdatedCartDataTC())
        }
      }
      // Устанавливаем время в куки для последнего обновления корзины
      setCookieTime(COOKIE_LIFE_TIME_NAME, Date.now().toString(), 1)

      dispatch(setAppSuccessAC({ type: 'success', title: 'товар изменен' }))
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getUpdatedCartDataTC =
  (payload?: { isDiscount: boolean }): AppThunkType =>
  async (dispatch, getState) => {
    const cart = getState().user.cart

    const payloadWithCart = {
      isDiscount: payload?.isDiscount,
      cart,
    }

    try {
      dispatch(setAppStatusAC('loading'))
      const response = await cartAPI.getUpdatedCart(payloadWithCart)

      if (response.data.statusCode === 0) {
        dispatch(setTotalRubSumAC(response.data.totalRubSum))
        dispatch(setDeliveryCostAC(response.data.deliveryCost))
        dispatch(setDiscountedCartAC(response.data.cart))
        dispatch(setIsUseDiscountAC(response.data.isDiscount))
        dispatch(setServiceFeeAC(response.data.serviceFee))
      }

      // Сравниваем время жизни корзины с временем жизни куки
      compareCartLifeTime(dispatch, cart)

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(setAppStatusAC('failed'))
    }
  }

export const clearCartTC = (): AppThunkType => async (dispatch, getState) => {
  const state = getState()

  const clearCart = async () => {
    dispatch(setTotalRubSumAC(0))
    dispatch(setDeliveryCostAC(0))
    dispatch(setIsUseDiscountAC(false))
    dispatch(setServiceFeeAC(0))
    dispatch(clearCartAC())
    removeCookieTime(COOKIE_LIFE_TIME_NAME)
  }

  try {
    dispatch(setAppStatusAC('loading'))

    if (!state.user.userId) {
      await clearCart()
    } else {
      const response = await cartAPI.clearCart()

      if (response.data.statusCode === 0) {
        await clearCart()
      }
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkUserTC())
    dispatch(setAppStatusAC('failed'))
  }
}

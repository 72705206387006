import {
  BUYER_LOGOUT,
  BuyerReducerActionType,
  SET_CARDS_DATA,
  SET_CURRENT_ORDER,
  SET_CURRENT_PAGE,
  SET_DATA,
  SET_MY_ORDERS,
  SET_MY_ORDERS_COUNT,
  SET_ORDER_LIST,
  SET_ORDER_LIST_COUNT,
  SET_PAGE_SIZE,
  SET_PAGES_COUNT,
  SET_TOTAL_REVENUE,
} from './actions-type'
import { InitialStateType } from './types'

const initialState: InitialStateType = {
  buyerData: {
    userId: null,
    userFullName: null,
    role: null,
    blocked: false,
  },
  orderList: [],
  myOrders: [],
  cardsData: [],
  currentOrder: null,
  totalRevenue: 0,
  orderListCount: 0,
  myOrdersCount: 0,
  currentPage: 1,
  pageSize: 10,
  pagesCount: 0,
}

export const buyerReducer = (
  state = initialState,
  action: BuyerReducerActionType
): InitialStateType => {
  switch (action.type) {
    case SET_DATA:
      return { ...state, buyerData: action.payload }
    case SET_ORDER_LIST:
      return { ...state, orderList: action.payload }
    case SET_MY_ORDERS:
      return { ...state, myOrders: action.payload }
    case SET_CARDS_DATA:
      return { ...state, cardsData: action.payload }
    case SET_ORDER_LIST_COUNT:
      return { ...state, orderListCount: action.payload }
    case SET_MY_ORDERS_COUNT:
      return { ...state, myOrdersCount: action.payload }
    case SET_CURRENT_PAGE:
      return { ...state, currentPage: action.payload }
    case SET_PAGE_SIZE:
      return { ...state, pageSize: action.payload }
    case SET_PAGES_COUNT:
      return { ...state, pagesCount: action.payload }
    case SET_TOTAL_REVENUE:
      return { ...state, totalRevenue: action.payload }
    case BUYER_LOGOUT:
      return initialState
    case SET_CURRENT_ORDER: {
      return { ...state, currentOrder: action.payload }
    }
    default: {
      return state
    }
  }
}

import { AppRootState } from '../types'

export const selectedOrders = (state: AppRootState) => state.admin.orders
export const selectedInPaymentOrders = (state: AppRootState) => state.admin.inPayment
export const selectedEmployeeData = (state: AppRootState) => state.admin.employeeData
export const selectedCards = (state: AppRootState) => state.admin.cardsData
export const selectedAdminCurrentPage = (state: AppRootState) => state.admin.currentPage
export const selectedPagesCount = (state: AppRootState) => state.admin.pagesCount
export const selectedAdminPageSize = (state: AppRootState) => state.admin.pageSize
export const selectedTotalOrdersCount = (state: AppRootState) => state.admin.totalOrdersCount
export const selectedTotalInPaymentCount = (state: AppRootState) => state.admin.totalInPaymentCount
export const selectedTotalEmployeeCount = (state: AppRootState) => state.admin.totalEmployeeCount
export const selectedAdminUserId = (state: AppRootState) => state.admin.userData.userId
export const selectedUserName = (state: AppRootState) => state.admin.userData.userFullName
export const selectedCurrentOrder = (state: AppRootState) => state.admin.currentOrder
export const selectedRevenue = (state: AppRootState) => state.admin.revenue
export const selectedProfit = (state: AppRootState) => state.admin.profit
export const selectedAdminRole = (state: AppRootState) => state.admin.userData.role
export const selectedAdminData = (state: AppRootState) => state.admin.userData
// export const selectedAllUsersData = (state: AppRootState) => state.admin.allUsers
export const selectedWarehouses = (state: AppRootState) => state.admin.warehouses
export const selectedSettings = (state: AppRootState) => state.admin.settings

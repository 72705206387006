import { SettingsType } from '../../app/store/adminReducer/types'
import { BasicResponse } from '../api-type'
import { instance } from '../instance'

export const adminSettingsAPI = {
  getSettings() {
    return instance.get<GetSettingsResponseType>(`/admin/settings`)
  },
  updateSettings(payload: SettingsType) {
    return instance.put<'', UpdateSettingsResponseType, SettingsType>(`/admin/settings`, payload)
  },
}

export type UpdateSettingsResponseType = {
  data: BasicResponse
}

type GetSettingsResponseType = BasicResponse & SettingsType

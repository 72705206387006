import { BasicResponse, RootResponse } from '../api-type'
import { instance } from '../instance'

import UserPassport from 'types/user-passport'

export const passportAPI = {
  getPassportData() {
    return instance.get<RootResponse<'passports', UserPassport[]>>('/user/passports')
  },
  setPassportData(payload: UserPassport) {
    return instance.post<'', ChangePassportResponseType, UserPassport>('/user/passports', payload)
  },
  changePassportData(payload: UserPassport) {
    return instance.put<'', ChangePassportResponseType, UserPassport>('/user/passports', payload)
  },
  removePassport(id: number) {
    return instance.delete<BasicResponse>('/user/passports', { params: { id } })
  },
}

// types ============================================================

export type ChangePassportResponseType = {
  data: BasicResponse
}

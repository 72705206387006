import React from 'react'

import { useAppSelector } from '../../../hooks/customHooks'
import { Image } from '../../../ui'

import styles from './ImgPreviewModal.module.scss'

import { selectedImgPreviewData } from 'app/store'
import placeholder from 'assets/icons/imgPlaceholder_60.png'

export const ImgPreviewModal = () => {
  const imgData = useAppSelector(selectedImgPreviewData)

  return (
    <div className={styles.content}>
      <div className={styles.imgBox}>
        <Image
          src={imgData?.productImg || placeholder}
          alt={`${imgData?.productName}` || 'product img'}
          isLazy={true}
        />
      </div>
      <div className={styles.description}>
        {imgData?.productName ? <div className={styles.title}>{imgData.productName}</div> : null}
        {imgData?.item ? <div className={styles.text}>{imgData.item}</div> : null}
      </div>
    </div>
  )
}

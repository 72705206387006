import {
  ADMIN_LOGOUT,
  CHANGE_ORDER_STATUS,
  SET_ADMIN_DATA,
  SET_ADMIN_SETTINGS,
  SET_ALL_ORDER_DATA,
  SET_CARDS_DATA,
  SET_CURRENT_ORDER,
  SET_CURRENT_PAGE,
  SET_EMPLOYEE_DATA,
  SET_IN_PAYMENT_ORDERS,
  SET_IS_FETCHING,
  SET_PAGE_SIZE,
  SET_PAGES_COUNT,
  SET_PROFIT,
  SET_REVENUE,
  SET_TOTAL_EMPLOYEE_COUNT,
  SET_TOTAL_IN_PAYMENT_COUNT,
  SET_TOTAL_ORDERS_COUNT,
  SET_WAREHOUSES,
} from './actions-type'
import {
  CardType,
  CurrentOrderType,
  EmployeeType,
  OrderType,
  RootAdminStatusType,
  SettingsType,
  UserDataType,
  WarehouseType,
} from './types'

export const setAdminDataAC = (payload: UserDataType) =>
  ({ type: SET_ADMIN_DATA, payload } as const)
export const setOrderDataAC = (payload: OrderType[]) =>
  ({ type: SET_ALL_ORDER_DATA, payload } as const)
export const setTotalOrdersCountAC = (payload: number) =>
  ({ type: SET_TOTAL_ORDERS_COUNT, payload } as const)
export const setCurrentOrderAC = (payload: CurrentOrderType | null) =>
  ({ type: SET_CURRENT_ORDER, payload } as const)
export const setEmployeeDataAC = (payload: EmployeeType[]) =>
  ({ type: SET_EMPLOYEE_DATA, payload } as const)
export const setCardsDataAC = (payload: CardType[]) => ({ type: SET_CARDS_DATA, payload } as const)
export const setTotalEmployeeCountAC = (payload: number) =>
  ({ type: SET_TOTAL_EMPLOYEE_COUNT, payload } as const)
export const setInPaymentOrdersAC = (payload: OrderType[]) =>
  ({ type: SET_IN_PAYMENT_ORDERS, payload } as const)
export const setTotalInPaymentCountAC = (payload: number) =>
  ({ type: SET_TOTAL_IN_PAYMENT_COUNT, payload } as const)
export const changeOrderStatusAC = (orderId: number, status: RootAdminStatusType) =>
  ({ type: CHANGE_ORDER_STATUS, orderId, status } as const)
export const setCurrentPageAC = (payload: number) => ({ type: SET_CURRENT_PAGE, payload } as const)
export const setPagesCountAC = (payload: number) => ({ type: SET_PAGES_COUNT, payload } as const)
export const setPagesSizeAC = (payload: number) => ({ type: SET_PAGE_SIZE, payload } as const)
export const setIsFetchingAC = (payload: boolean) => ({ type: SET_IS_FETCHING, payload } as const)
export const setRevenueAC = (payload: string) => ({ type: SET_REVENUE, payload } as const)
export const setProfitAC = (payload: string) => ({ type: SET_PROFIT, payload } as const)
export const adminLogoutAC = () => ({ type: ADMIN_LOGOUT } as const)
export const setWarehousesAC = (payload: WarehouseType[]) =>
  ({ type: SET_WAREHOUSES, payload } as const)
export const setAdminSettingsAC = (payload: SettingsType | null) =>
  ({ type: SET_ADMIN_SETTINGS, payload } as const)

import React from 'react'

import { CustomDataPicker } from '../CustomDataPicker/CustomDataPicker'

import styles from './DataPickerFilter.module.scss'

type DataPickerFilterPropsType = {
  fromDate: Date | null
  toDate: Date | null
  handleFromDateChange: (date: Date | null) => void
  handleToDateChange: (date: Date | null) => void
}
export const DataPickerFilter = (props: DataPickerFilterPropsType) => {
  const { fromDate, toDate, handleFromDateChange, handleToDateChange } = props

  return (
    <div className={styles.dataPicker}>
      <div className={styles.item}>
        <span className={styles.span}>От</span>
        <CustomDataPicker
          selectedDate={fromDate}
          setSelectedDate={handleFromDateChange}
          position={'bottom-start'}
          popperStyles={styles.popper}
          maxDate={toDate}
        />
      </div>
      <div className={styles.item}>
        <span className={styles.span}>до</span>
        <CustomDataPicker
          selectedDate={toDate}
          minDate={fromDate}
          setSelectedDate={handleToDateChange}
          position={'bottom-end'}
        />
      </div>
    </div>
  )
}

import {
  CLEAR_FILTER,
  FilterActionsType,
  SET_ACCOUNT_ID_TO_CHANGE,
  SET_FILTER_ACCOUNT_STATUS,
  SET_FILTERED_GENDER,
  SET_FILTERED_USER_ROLE,
  SET_FILTERED_USERS_AGES,
  SET_FILTERED_USERS_CHARACTERISTICS,
  SET_FILTERED_USERS_PRICES,
  SET_FROM_DATE,
  SET_SEARCH_VALUE,
  SET_SELECTED_ACCOUNT_ID,
  SET_SELECTED_ORDER_ID,
  SET_SELECTED_STATUS,
  SET_STATUS_FOR_SEARCH,
  SET_TO_DATE,
  SET_USERS_BY_CHARACTERISTICS,
  SET_USERS_BY_ROLE,
} from './action-types'
import { InitialStateType } from './types'

const initialState: InitialStateType = {
  searchValue: '',
  selectedStatus: null,
  selectedOrderId: null,
  filterAccountStatus: null,
  statusForSearch: null,
  selectedAccountId: null,
  accountIdToChange: null,
  fromDate: null,
  toDate: null,
  // selectedDateChanged: false,
  genders: ['male', 'female'],
  filteredGender: undefined,
  age: null,
  prices: null,
  usersByCharacteristics: [],
  filteredUsersCharacteristics: null,
  usersByRole: [],
  filteredUserRole: null,
}

export const filterReducer = (
  state: InitialStateType = initialState,
  action: FilterActionsType
): InitialStateType => {
  switch (action.type) {
    case SET_SEARCH_VALUE:
      return {
        ...state,
        searchValue: action.searchValue,
      }
    case SET_SELECTED_STATUS:
      return {
        ...state,
        selectedStatus: action.selectedStatus,
      }
    case SET_SELECTED_ORDER_ID:
      return {
        ...state,
        selectedOrderId: action.selectedOrderId,
      }
    case SET_FILTERED_USER_ROLE: {
      return {
        ...state,
        filteredUserRole: action.filterUserRole,
      }
    }
    case SET_FILTER_ACCOUNT_STATUS:
      return {
        ...state,
        filterAccountStatus: action.filterAccountStatus,
      }
    case SET_STATUS_FOR_SEARCH:
      return {
        ...state,
        statusForSearch: action.statusForSearch,
      }
    case SET_SELECTED_ACCOUNT_ID:
      return {
        ...state,
        selectedAccountId: action.selectedAccountId,
      }
    case SET_ACCOUNT_ID_TO_CHANGE:
      return {
        ...state,
        accountIdToChange: action.accountIdToChange,
      }
    case SET_FROM_DATE:
      return {
        ...state,
        fromDate: action.fromDate,
      }
    case SET_TO_DATE:
      return {
        ...state,
        toDate: action.toDate,
      }
    // case SET_SELECTED_DATE_CHANGED:
    //   return {
    //     ...state,
    //     selectedDateChanged: action.selectedDateChanged,
    //   }
    case SET_FILTERED_GENDER:
      return {
        ...state,
        filteredGender: action.filteredGender,
      }
    case SET_FILTERED_USERS_AGES: {
      return {
        ...state,
        age: action.ages,
      }
    }
    case SET_FILTERED_USERS_PRICES: {
      return {
        ...state,
        prices: action.prices,
      }
    }
    case SET_USERS_BY_ROLE: {
      return {
        ...state,
        usersByRole: action.usersByRole,
      }
    }
    case SET_USERS_BY_CHARACTERISTICS: {
      return {
        ...state,
        usersByCharacteristics: action.usersByCharacteristics,
      }
    }
    case SET_FILTERED_USERS_CHARACTERISTICS: {
      return {
        ...state,
        filteredUsersCharacteristics: action.filteredUsersCharacteristics,
      }
    }
    case CLEAR_FILTER:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

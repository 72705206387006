import { AxiosError } from 'axios'

import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import { AppThunkType } from '../types'

import { fetchUserAC, setBonusesAC, updateUserDateAC, userLogoutAC } from './actions'
import { BonusesType } from './types'

import {
  getTokenFromCookie,
  removeTokenFromCookie,
  setBearerTokenToCookie,
} from 'api/cookieWorkers'
import { authAPI, UserRegistrationRequestType } from 'api/userAPI/user-authorization-api'
import { userAccountAPI } from 'api/userAPI/userAccountAPI'
import { convertDateFormat, errorUtils, formatPhoneNumber, viewImageCollector } from 'common'
import { CheckedUserData } from 'types/user-data'

export const userRegistrationTC =
  ({
    userFullName,
    userEmail,
    userPassword,
    userPhone,
  }: UserRegistrationRequestType): AppThunkType =>
  async (dispatch, getState) => {
    const state = getState()

    dispatch(setAppStatusAC('loading'))

    const requestPayload: UserRegistrationRequestType = {
      userFullName,
      userEmail,
      userPassword,
      userPhone: formatPhoneNumber(userPhone, false),
      cart: state.user.cart,
    }

    const token = getTokenFromCookie(`invite_token`)

    if (token) {
      requestPayload['inviteToken'] = token
    }

    try {
      removeTokenFromCookie('BearerToken')

      const response = await authAPI.registration(requestPayload)

      if (response.data.statusCode === 0) {
        setBearerTokenToCookie(response.data.token)
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        removeTokenFromCookie('invite_token')
        dispatch(setAppStatusAC('succeeded'))
        dispatch(checkUserTC())
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
        dispatch(setAppStatusAC('failed'))
      }
    } catch (e) {
      const err = e as Error | AxiosError<unknown, any>

      errorUtils(err, dispatch)
      dispatch(setAppStatusAC('failed'))
    }
  }

export const userLoginTC =
  ({ userEmail, userPassword }: { userEmail: string; userPassword: string }): AppThunkType =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      dispatch(setAppStatusAC('loading'))
      removeTokenFromCookie('BearerToken')
      const response = await authAPI.login({ userEmail, userPassword, cart: state.user.cart })

      if (response.data.statusCode === 0) {
        const token = response.data.token

        setBearerTokenToCookie(token)

        const viewImage = await viewImageCollector(response.data.cart)
        const cartJsonString = JSON.stringify(response.data.cart)
        const viewCartJsonString = JSON.stringify(viewImage)

        localStorage.setItem('cart', cartJsonString)
        localStorage.setItem('view-cart', viewCartJsonString)

        const userData = {
          userId: response.data.userId,
          userName: response.data.userName,
          userPhone: formatPhoneNumber(response.data.userPhone, true),
          userEmail: response.data.userEmail,
          cart: response.data.cart,
          viewCart: viewImage,
          birthdayDate: convertDateFormat(response.data.birthdayDate, 'dd.mm.yyyy'),
        }

        dispatch(fetchUserAC(userData))
        dispatch(checkUserTC())
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else if (response.data.statusCode === 2) {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      // dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const userLogoutTC = (): AppThunkType => async dispatch => {
  try {
    dispatch(setAppStatusAC('loading'))
    const response = await authAPI.logout()

    if (response.data.statusCode === 0) {
      removeTokenFromCookie('BearerToken')
      dispatch(userLogoutAC())
      dispatch(setAppStatusAC('succeeded'))
    }
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkUserTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const checkUserTC = (): AppThunkType => async dispatch => {
  try {
    dispatch(setAppStatusAC('loading'))

    const userData: CheckedUserData = {
      userId: null,
      userName: null,
      userPhone: null,
      userEmail: null,
      birthdate: null,
      userRole: null,
      blocked: false,
      emailVerified: false,
    }

    const response = await authAPI.checkUserAuth()

    if (response.data.statusCode === 0) {
      userData.userId = response.data.userId
      userData.userName = response.data.userName
      userData.userPhone = formatPhoneNumber(response.data.userPhone, true)
      userData.userEmail = response.data.userEmail
      userData.birthdate = convertDateFormat(response.data.birthdate, 'dd.mm.yyyy')
      userData.userRole = response.data.userRole
      userData.blocked = response.data.blocked
      userData.emailVerified = response.data.emailVerified

      dispatch(updateUserDateAC(userData))
    } else if (response.data.statusCode === 2) {
      dispatch(userLogoutAC())
      removeTokenFromCookie('BearerToken')
      dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
    } else if (response.data.statusCode === 1) {
      dispatch(userLogoutAC())
      dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      removeTokenFromCookie('BearerToken')
    }
    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    // errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

//bonuses=======================================================================
export const getBonusesTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await userAccountAPI.getBonuses()

    if (response.data.statusCode === 0) {
      const bonuses: BonusesType = {
        inviteLink: response.data.inviteLink,
        discountSize: response.data.discountSize,
        discountCount: response.data.discountCount,
        invitedUsers: response.data.invitedUsers,
      }

      dispatch(setBonusesAC(bonuses))
    }
    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkUserTC())
    dispatch(setAppStatusAC('failed'))
  }
}

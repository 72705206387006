import React, { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../hooks/customHooks'

import styles from './BuyerActions.module.scss'
import { DragAndDrop } from './DragAndDrop/DragAndDrop'
import { FileList } from './FileList/FileList'
import { TrackNumbers } from './TrackNumbers/TrackNumbers'

import { PATH } from 'app/routes/AppRoutes'
import {
  addFileTC,
  AddTrackNumberPayloadType,
  addTrackNumberTC,
  BuyerOrderType,
  ChangeBuyerOrderStatusPayloadType,
  changeBuyerOrderStatusTC,
  EditTrackNumberPayloadType,
  editTrackNumberTC,
  getBuyerOrderTC,
  getMyOrdersTC,
  IFile,
  selectedAppStatus,
  selectedBuyerPageSize,
  setAdminPanelTabAC,
  setAppSuccessAC,
  TrackNumber,
  TrackNumbersType,
} from 'app/store'
import { Button } from 'common/ui'
import { AvailableBrandEnum } from 'types/available-brand-enum'

type OrderActionsPropsType = {
  orderData: BuyerOrderType
  openCancelOrderModal: () => void
  isBuyer: boolean
}

export const BuyerActions = (props: OrderActionsPropsType) => {
  const { orderData, openCancelOrderModal, isBuyer } = props
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [numbers, setNumbers] = useState<TrackNumbersType>({} as TrackNumbersType)
  const [files, setFiles] = useState<IFile[]>([])

  const pageSize = useAppSelector(selectedBuyerPageSize)
  const appStatus = useAppSelector(selectedAppStatus)
  // const numbers = useAppSelector(selectedBuyerTrackNumbers)

  const submitBtnText =
    orderData.buyerStatus === 'TakenByBuyer' ? 'Подтвердить оплату' : 'Обновить данные'

  const takeOrderHandler = async (id: number) => {
    const payload: ChangeBuyerOrderStatusPayloadType = {
      orderId: id,
      orderStatus: 'TakenByBuyer',
    }

    dispatch(changeBuyerOrderStatusTC(payload))
  }

  const handleFileUpload = (formData: FormData) => {
    const newFile: IFile = {
      fileId: files.length + 1,
      status: 'pending',
      file: '',
      fileName: 'fileName',
    }

    setFiles([...files, newFile])

    dispatch(addFileTC(orderData.orderId, formData))
  }

  const confirmOrderHandler = async () => {
    const addTrackNumsTasks: any[] = []
    const editTrackNumsTasks: any[] = []

    if (numbers && Array.isArray(Object.keys(numbers))) {
      Object.keys(numbers).map(shop => {
        if (numbers[shop as AvailableBrandEnum] && numbers[shop as AvailableBrandEnum].length > 0) {
          numbers[shop as AvailableBrandEnum].map(async num => {
            if (
              num &&
              num.trackId &&
              num.trackNum !==
                orderData.trackNumbers?.[shop as AvailableBrandEnum][num.trackId]?.trackNum
            ) {
              const payload: EditTrackNumberPayloadType = {
                trackNum: num.trackNum,
                trackNumId: num.trackId,
              }

              editTrackNumsTasks.push(dispatch(editTrackNumberTC(payload)))
            } else if (!num.trackId) {
              const payload: AddTrackNumberPayloadType = {
                orderId: orderData.orderId,
                trackNum: num.trackNum,
                shop: shop as AvailableBrandEnum,
              }

              addTrackNumsTasks.push(dispatch(addTrackNumberTC(payload)))
            }
          })
        }
      })
    }

    await Promise.allSettled(addTrackNumsTasks)
    await Promise.allSettled(editTrackNumsTasks)

    if (orderData.buyerStatus === 'TakenByBuyer') {
      const payload: ChangeBuyerOrderStatusPayloadType = {
        orderId: orderData.orderId,
        orderStatus: 'Paid',
      }

      dispatch(changeBuyerOrderStatusTC(payload))

      dispatch(setAppSuccessAC({ type: 'success', title: 'заказ успешно отправлен на ревью' }))

      isBuyer ? navigate(PATH.ADMIN_PANEL_BUYER) : navigate(PATH.ADMIN_PANEL_ADMIN)
      // navigate(-1)
      dispatch(setAdminPanelTabAC('myOrders'))
      dispatch(getMyOrdersTC({ page: 1, pageSize }))
    } else if (orderData.buyerStatus === 'Paid') {
      dispatch(getBuyerOrderTC(orderData.orderId))
      dispatch(setAppSuccessAC({ type: 'success', title: 'данные заказа обновлены' }))
    }
    //
    // dispatch(setAppSuccessAC({ type: 'success', title: 'Данные заказа обновлены' }))
    // dispatch(getOrderListTC({ page: 1, pageSize })) //todo: исправить этот момент, настроить обновление вкладок для админа
    //
    // dispatch(setAdminPanelTabAC('myOrders'))
    // navigate(PATH.ADMIN_PANEL_BUYER)

    //проверка на то, что все номера отслеживания добавлены или изменены и после этого подтверждение оплаты
  }

  const isDisabledBtn = () => {
    let hasNumbers
    let hasFiles = files && files.length > 0

    if (orderData.buyerStatus === 'TakenByBuyer') {
      hasNumbers =
        numbers &&
        Object.keys(numbers).some(
          shop =>
            numbers[shop as AvailableBrandEnum][numbers[shop as AvailableBrandEnum]?.length - 1]
              ?.trackNum.length
        )
    } else if (orderData.buyerStatus === 'Paid') {
      const newNumbersKeys = Object.keys(numbers || {})

      hasNumbers = newNumbersKeys.some(shop =>
        numbers[shop as AvailableBrandEnum]?.some(
          item => item.trackNum && item.trackNum.trim().length > 0 && !item.trackId
        )
      )
    }

    return !(hasNumbers && hasFiles)
  }

  useEffect(() => {
    orderData.trackNumbers &&
      Object.entries(orderData.trackNumbers).map(([key, value]) => {
        const updatedShop: TrackNumber[] = value.map(el => ({
          trackNum: el.trackNum,
          trackId: el.trackId,
        }))
        const updatedNumbers = { ...numbers, [key]: updatedShop }

        setNumbers(prevState => ({ ...prevState, ...updatedNumbers }))
      })

    setFiles(orderData.files?.map(file => ({ ...file, status: 'success', progress: 0 })))
  }, [orderData])

  return (
    <div className={styles.actionsBox}>
      {orderData.buyerStatus === 'TakenByBuyer' || orderData?.buyerStatus === 'Paid' ? (
        <>
          <DragAndDrop onFileUpload={handleFileUpload} />
          <FileList files={files} orderId={orderData.orderId} isEdit={true} />
          <TrackNumbers
            numbers={numbers}
            setNumbers={setNumbers}
            orderId={orderData.orderId}
            cart={orderData.cart}
          />
          <div className={styles.buttons}>
            <Button
              size="large"
              variant="transparent"
              type="button"
              onClick={openCancelOrderModal}
              // hasLoad={appStatus === 'loading'}
            >
              Проблема с заказом
            </Button>
            <Button
              size="large"
              variant="default"
              type="button"
              onClick={confirmOrderHandler}
              hasLoad={appStatus === 'loading'}
              disabled={isDisabledBtn()}
            >
              {submitBtnText}
            </Button>
          </div>
        </>
      ) : null}
      {orderData.buyerStatus === 'ForPayment' ? (
        <Button
          mod="fluid"
          size="large"
          variant="default"
          type="button"
          onClick={() => takeOrderHandler(orderData?.orderId!)}
        >
          взять в работу
        </Button>
      ) : null}
    </div>
  )
}

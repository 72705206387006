import { AxiosResponse } from 'axios'

import { BasicResponse } from '../api-type'
import { instance } from '../instance'

import { UserRolesType } from 'app/store'

export const adminAuthAPI = {
  login(data: AdminLoginRequestType) {
    return instance.post<'', AxiosResponse<AdminLoginResponseType>, AdminLoginRequestType>(
      '/admin/login',
      data
    )
  },
}

export type AdminLoginRequestType = {
  userEmail: string
  userPassword: string
}

export type AdminLoginResponseType = BasicResponse & {
  token: string
  userFullName: string
  role: UserRolesType
  blocked: boolean //for buyer
  orderListCount: number //for buyer
  myOrdersCount: number //for buyer
  userId: number
  totalOrdersCount: number // количество всех заказов
  totalInPaymentCount: number // количество заказов со статусом "inPayment"
  totalUsersCount: number // количество работников
}

import { BasicResponse, RootResponse } from '../api-type'
import { instance } from '../instance'

import { ReturnStatusType, RootStatusType, UserStatusType } from 'app/store'
import { InvitedUserType } from 'app/store/userReducer/types'
import CartType from 'types/cart-type'
import DeliveryAddress from 'types/delivery-address'

export const userAccountAPI = {
  getBonuses() {
    return instance.get<BonusesResponseType>('/user/bonuses')
  },
  getAddresses() {
    return instance.get<RootResponse<'addresses', DeliveryAddress[]>>('/user/addresses')
  },
  addAddress(payload: AddAddressPayloadType) {
    return instance.post<'', AddAddressResponseType, AddAddressPayloadType>(
      '/user/addresses',
      payload
    )
  },
  editAddress(payload: DeliveryAddress) {
    return instance.put<'', AddAddressResponseType, DeliveryAddress>('/user/addresses', payload)
  },
  removeAddress(addressId: string) {
    return instance.delete('/user/addresses/', { params: { addressId } })
  },
  getUserOrders(payload: UserOrdersRequestType) {
    return instance.get<UserOrdersResponseType>('/user/history', { params: payload })
  },
  getUserReturnOrders(payload: UserOrdersRequestType) {
    return instance.get<UserOrdersResponseType>('/user/history/returned', {
      params: payload,
    })
  },
  getUserCurrentOrder(orderId: string) {
    return instance.get<CurrentOrderResponseType>(`/user/history/order`, {
      params: { orderId },
    })
  },
  editUserSettings(payload: EditUserSettingsRequestType) {
    return instance.put<'', EditUserSettingsResponseType, EditUserSettingsRequestType>(
      '/user/settings',
      payload
    )
  },
  changeUserPassword(payload: ChangePasswordRequestType) {
    return instance.put<'', EditUserSettingsResponseType, ChangePasswordRequestType>(
      '/user/settings/password',
      payload
    )
  },
}

// types ============================================================
export type BonusesResponseType = BasicResponse & {
  discountCount: number
  discountSize: number
  inviteLink: string
  invitedUsers: InvitedUserType[]
}

export type AddAddressPayloadType = {
  address: string
  entrance: string
  floor: string | number
  flat: string | number
  intercom?: number | string | null
  note: string
  isDefaultAddress: boolean
  addressId?: string
}

export type AddAddressResponseType = {
  data: BasicResponse
}

export type UserOrdersRequestType = {
  page: number
  pageSize?: number
}

export type UserOrdersResponseType = BasicResponse & {
  returnedOrdersCount: number
  userOrderHistory: {
    data: OrderResponseType[]
    current_page: number
    last_page: number
    per_page: number
    total: number
  }
}

export type OrderResponseType = {
  orderId: string
  orderDate: string
  userStatus: RootStatusType
  orderTotalSum?: string
  cart: CartType
}

export type CurrentOrderResponseType = BasicResponse & {
  orderId: string
  dateTime: string
  totalSum: string
  userStatus: UserStatusType | ReturnStatusType
  deliveryAddress: string
  recipient: {
    userName: string
    phoneNumber: string
  }
  paymentMethod: string
  trackingNumber: string
  deliveryMethod: string
  deliveryServiceName: string
  cart: CartType
}
export type EditUserSettingsRequestType = {
  userName: string
  userEmail: string
  userPhone: string
  birthdate?: string
}
export type EditUserSettingsResponseType = {
  data: BasicResponse
}

export type ChangePasswordRequestType = {
  password: string
  newPassword: string
}

import React, { useState } from 'react'

import Tooltip from '../../../ui/Tooltip/Tooltip'

import styles from './ClipboardComponent.module.scss'

import { ReactComponent as CopyIcon } from 'assets/icons/svgIcons/copyIcon.svg'

type ClipboardComponentPropsType = {
  placeholder: string
  deliveryServiceName?: string
  tooltipText: string
}
export const ClipboardComponent = ({
  placeholder,
  deliveryServiceName,
  tooltipText,
}: ClipboardComponentPropsType) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState<boolean>(false)
  const [isDeliveryTooltipVisible, setIsDeliveryTooltipVisible] = useState<boolean>(false)

  const copyInfoHandler = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    try {
      const textToCopy = placeholder // placeholder - текст, который нужно скопировать

      const textArea = document.createElement('textarea')

      textArea.value = textToCopy
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)

      setIsTooltipVisible(true)
      const timeout = setTimeout(() => {
        setIsTooltipVisible(false)
      }, 2000)

      return () => clearTimeout(timeout)
    } catch (e) {
      console.error('Ошибка копирования в буфер:', e)
    }
  }

  const copyDeliveryServiceName = async (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()

    try {
      const textToCopy = deliveryServiceName // deliveryServiceName - текст, который нужно скопировать

      const textArea = document.createElement('textarea')

      if (typeof textToCopy === 'string') {
        textArea.value = textToCopy
      }
      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)

      setIsDeliveryTooltipVisible(true)
      const timeout = setTimeout(() => {
        setIsDeliveryTooltipVisible(false)
      }, 2000)

      return () => clearTimeout(timeout)
    } catch (e) {
      console.error('Ошибка копирования в буфер:', e)
    }
  }

  return (
    <div className={styles.clipBoardBox}>
      <div
        className={styles.linkBox}
        data-tooltip-id="clipboard-tooltip"
        onClick={event => copyInfoHandler(event)}
      >
        <span>{placeholder}</span>
        <div className={styles.copyButton}>
          <div className={styles.iconBox}>
            <CopyIcon className={styles.icon} />
          </div>
        </div>
        <div className={styles.tooltip}>
          {isTooltipVisible && (
            <Tooltip
              position={'top'}
              message={tooltipText}
              type={'clipboard'}
              isOpen={isTooltipVisible}
            />
          )}
        </div>
      </div>
      {deliveryServiceName && (
        <div
          className={styles.deliveryName}
          onClick={e => copyDeliveryServiceName(e)}
          data-tooltip-id="delivery-service"
        >
          <span>Служба доставки: </span>
          <div className={styles.name}>
            {deliveryServiceName}
            {isDeliveryTooltipVisible && (
              <Tooltip
                position={'top'}
                message={'служба доставки скопирована'}
                type={'clipboard'}
                isOpen={isDeliveryTooltipVisible}
                // tooltipClassName={styles.deliveryTooltip}
                containerClassName={styles.deliveryTooltip}
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

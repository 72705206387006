import {
  buyerLogoutAC,
  setBuyerCardsDataAC,
  setBuyerCurrentPageAC,
  setBuyerDataAC,
  setBuyerOrderAC,
  setBuyerPagesCountAC,
  setBuyerPageSizeAC,
  setBuyerRevenueAC,
  setMyOrdersAC,
  setMyOrdersCountAC,
  setOrderListAC,
  setOrderListCountAC,
} from './actions'

export const SET_DATA = 'buyer/SET-DATA'
export const SET_ORDER_LIST = 'buyer/SET-ORDER-LIST'
export const SET_MY_ORDERS = 'buyer/SET-MY-ORDERS'
export const SET_ORDER_LIST_COUNT = 'buyer/SET-ORDER-LIST-COUNT'
export const SET_MY_ORDERS_COUNT = 'buyer/SET-MY-ORDERS-COUNT'
export const SET_CURRENT_PAGE = 'buyer/SET-CURRENT-PAGE'
export const SET_PAGE_SIZE = 'buyer/SET-PAGE-SIZE'
export const SET_PAGES_COUNT = 'buyer/SET-PAGES-COUNT'
export const SET_TOTAL_REVENUE = 'buyer/SET-TOTAL-REVENUE'
export const SET_CARDS_DATA = 'buyer/SET-CARDS-DATA'
export const BUYER_LOGOUT = 'buyer/BUYER-LOGOUT'
export const SET_CURRENT_ORDER = 'buyer/SET-CURRENT-ORDER'

export type BuyerReducerActionType =
  | ReturnType<typeof setBuyerDataAC>
  | ReturnType<typeof setOrderListAC>
  | ReturnType<typeof setMyOrdersAC>
  | ReturnType<typeof setOrderListCountAC>
  | ReturnType<typeof setMyOrdersCountAC>
  | ReturnType<typeof setBuyerCurrentPageAC>
  | ReturnType<typeof setBuyerPageSizeAC>
  | ReturnType<typeof setBuyerPagesCountAC>
  | ReturnType<typeof setBuyerRevenueAC>
  | ReturnType<typeof setBuyerCardsDataAC>
  | ReturnType<typeof buyerLogoutAC>
  | ReturnType<typeof setBuyerOrderAC>

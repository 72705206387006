import React, { MouseEvent } from 'react'

import { useFormik } from 'formik'

import { Button, Input, PasswordInput } from '../../../../ui'

import styles from './AdminUserDataModal.module.scss'
import { SelectRole } from './SelectRole/SelectRole'

import {
  addNewEmployeeTC,
  AddUserPayloadType,
  ChangeAccountStatusPayloadType,
  changeEmployeeDataTC,
  CharacteristicsType,
  deleteEmployeeTC,
  EmployeeType,
  GenderType,
  ModalType,
  RangeType,
  RootAdminStatusType,
  UserRolesType,
  UsersByRoleType,
} from 'app/store'
import { DeleteUserPayloadType } from 'app/store/adminReducer/thunk-types'
import { generatePassword, getValidScheme, useAppDispatch } from 'common'

type ChangeAdminDataModalPropsType = {
  isAdmin: boolean
  selectedUser?: EmployeeType
  modalType: ModalType
  userRoles: UsersByRoleType[]
  fromDate?: Date | null
  toDate?: Date | null
  filterAccountStatus?: RootAdminStatusType | null
  filterUserRole?: UserRolesType | null
  searchValue?: string
  filteredUserCharacteristic?: CharacteristicsType | null
  filteredGender?: GenderType | null
  filteredAges?: RangeType | null
  filteredPrices?: RangeType | null
  loggedInUserId: number | null
}

export const AdminUserDataModal = (props: ChangeAdminDataModalPropsType) => {
  const {
    isAdmin,
    selectedUser,
    modalType,
    userRoles,
    fromDate,
    toDate,
    filterAccountStatus,
    filterUserRole,
    searchValue,
    filteredUserCharacteristic,
    filteredGender,
    filteredAges,
    filteredPrices,
    loggedInUserId,
  } = props
  const dispatch = useAppDispatch()

  const editModal = modalType === 'editUser'
  const modalTitle = editModal ? 'редактировать сотрудника' : 'добавить сотрудника'
  const passwordLabel = editModal ? 'новый пароль' : 'придумайте пароль'
  const confirmPasswordLabel = editModal ? 'повторите новый пароль' : 'повторите пароль'
  const roles = isAdmin ? userRoles : userRoles.filter(role => role.role !== 'Admin')

  const formik = useFormik({
    initialValues: {
      role: editModal ? selectedUser?.role : 'User',
      userName: editModal ? selectedUser?.userName : '',
      email: editModal ? selectedUser?.userEmail : '',
      password: '',
      confirmPassword: '',
      priceMax: editModal
        ? selectedUser?.priceMax?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        : null,
      priceMin: editModal
        ? selectedUser?.priceMin?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        : null,
    },
    validationSchema: getValidScheme(modalType),
    onSubmit: async values => {
      const { priceMax, priceMin } = values
      const priceMaxNumber = Number(priceMax?.toString().replace(/\s/g, '')) ?? ''
      const priceMinNumber = Number(priceMin?.toString().replace(/\s/g, '')) ?? ''

      if (editModal) {
        const payload: ChangeAccountStatusPayloadType = {
          role: values.role!,
          userName: values.userName!,
          userEmail: values.email!,
          userId: selectedUser?.userId!,
          priceMax: priceMaxNumber,
          priceMin: priceMinNumber,
          fromDate,
          toDate,
          filterAccountStatus,
          filterUserRole,
          searchValue,
          filteredUserCharacteristic,
          filteredGender,
          filteredAges,
          filteredPrices,
        }

        if (values.password !== '') {
          // Если пароль не пустой, добавляем его в payload
          payload.userPassword = values.password!
        }

        console.log('payload', payload)

        dispatch(changeEmployeeDataTC(payload))
      } else {
        const payload: AddUserPayloadType = {
          role: values.role!,
          userName: values.userName!,
          userEmail: values.email!,
          userPassword: values.password,
          priceMax: priceMaxNumber,
          priceMin: priceMinNumber,
          fromDate,
          toDate,
          filterAccountStatus,
          filterUserRole,
          searchValue,
          filteredUserCharacteristic,
          filteredGender,
          filteredAges,
          filteredPrices,
        }

        dispatch(addNewEmployeeTC(payload))
      }
    },
  })

  let editUserDisabledButton = !formik.dirty || !formik.isValid
  let addUserDisabledButton =
    !formik.isValid ||
    formik.values.password.length === 0 ||
    formik.values.confirmPassword.length === 0 ||
    formik.values.userName?.length === 0 ||
    formik.values.email?.length === 0

  const disabledDeleteUserBtn = selectedUser?.userId === loggedInUserId

  const generationPasswordHandler = () => {
    const password = generatePassword(10)

    formik.setFieldValue('password', password, true).then(() => {
      formik.setFieldValue('confirmPassword', password, true)
    })
  }

  const setRoleHandler = (role: UserRolesType) => {
    formik.setFieldValue('role', role)
  }

  const deleteUserHandler = () => {
    if (selectedUser) {
      const payload: DeleteUserPayloadType = {
        userId: selectedUser.userId,
        fromDate,
        toDate,
        filterAccountStatus,
        filterUserRole,
        searchValue,
        filteredUserCharacteristic,
        filteredGender,
        filteredAges,
        filteredPrices,
      }

      dispatch(deleteEmployeeTC(payload))
    }
  }
  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    formik.handleSubmit()
  }

  const isPriceRangeVisible = () => {
    if (modalType === 'editUser') {
      return selectedUser?.role === 'Admin' || selectedUser?.role === 'Buyer'
    } else if (modalType === 'addUser') {
      return formik.values.role === 'Buyer' || formik.values.role === 'Admin'
    }
  }

  const priceRangeHandler = (value: string, rate: string) => {
    value = value.replace(/\D/g, '') // Удаляем все нецифровые символы

    if (!/^\d*$/.test(value)) {
      return
    } else {
      // Добавляем пробелы через каждые три символа
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') // Добавляем пробелы через каждые три символа

      if (rate === 'min') {
        formik.setFieldValue('priceMin', value, true)
      } else if (rate === 'max') {
        formik.setFieldValue('priceMax', value, true)
      }
    }
  }

  return (
    <div className={styles.modalBox}>
      <div className={styles.title}>{modalTitle}</div>
      <div className={styles.contentBox}>
        <form className={styles.form} autoComplete={'off'}>
          {modalType === 'addUser' && (
            <SelectRole
              role={formik.values.role ?? 'Manager'}
              setRole={setRoleHandler}
              userRoles={roles}
            />
          )}
          <div className={styles.inputBox}>
            <Input
              value={formik.values.userName}
              name={'userName'}
              onChange={formik.handleChange}
              size="large"
              variant="default"
              type={'text'}
              label={'имя и фамилия'}
              hasError={!!formik.errors.userName && formik.touched.userName}
              errorText={formik.errors.userName}
              setFormikFieldValue={formik.setFieldTouched}
              autoComplete={'off'}
            />
            <Input
              value={formik.values.email}
              name={'email'}
              onChange={formik.handleChange}
              size="large"
              variant="default"
              type={'email'}
              label={'почта'}
              autoComplete={'off'}
              hasError={!!formik.errors.email && formik.touched.email}
              errorText={formik.errors.email}
              setFormikFieldValue={formik.setFieldTouched}
            />
            <PasswordInput
              value={formik.values.password}
              name={'password'}
              onChange={formik.handleChange}
              label={passwordLabel}
              hasError={!!formik.errors.password && formik.touched.password}
              errorText={formik.errors.password}
              autoComplete="new-password"
              setFormikFieldValue={formik.setFieldTouched}
            />
            <PasswordInput
              value={formik.values.confirmPassword}
              name={'confirmPassword'}
              onChange={formik.handleChange}
              label={confirmPasswordLabel}
              hasError={!!formik.errors.confirmPassword && formik.touched.confirmPassword}
              errorText={formik.errors.confirmPassword}
              autoComplete="new-password"
              setFormikFieldValue={formik.setFieldTouched}
            />

            <div className={styles.generatePasswordBtn} onClick={generationPasswordHandler}>
              <span>Сгенерировать пароль</span>
            </div>
          </div>
          {isPriceRangeVisible() && (
            <>
              <div className={styles.rangeBox}>
                <span className={styles.subtitle}>ценовой диапазон</span>
                <div className={styles.priceBox}>
                  <Input
                    value={formik.values.priceMin}
                    name={'priceMin'}
                    onInput={(value: string) => priceRangeHandler(value, 'min')}
                    size="large"
                    variant="default"
                    label={'min ₽'}
                    setFormikFieldValue={formik.setFieldTouched}
                    hasError={!!formik.errors.priceMin && formik.touched.priceMin}
                  />
                  <div className={styles.divider} />
                  <Input
                    value={formik.values.priceMax}
                    name={'priceMax'}
                    onInput={(value: string) => priceRangeHandler(value, 'max')}
                    size="large"
                    variant="default"
                    label={'max ₽'}
                    setFormikFieldValue={formik.setFieldTouched}
                    hasError={!!formik.errors.priceMax && formik.touched.priceMax}
                  />
                </div>
                {(!!formik.errors.priceMin || !!formik.errors.priceMax) && (
                  <span className={styles.error}>
                    {formik.errors.priceMin || formik.errors.priceMax}
                  </span>
                )}
              </div>
            </>
          )}
          <div className={styles.buttonBox}>
            <Button
              mod="fluid"
              size="large"
              variant="default"
              type={'submit'}
              onClick={e => handleSubmit(e)}
              disabled={modalType === 'editUser' ? editUserDisabledButton : addUserDisabledButton}
            >
              {modalType === 'editUser' ? 'сохранить изменения' : 'добавить'}
            </Button>
            {modalType === 'editUser' && (
              <Button
                mod="fluid"
                size="large"
                variant="transparent"
                type="button"
                onClick={deleteUserHandler}
                disabled={disabledDeleteUserBtn}
              >
                удалить сотрудника
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  )
}

import React from 'react'

import CartType from '../../../../types/cart-type'
import { Button } from '../../../ui'

import styles from './ManagerActions.module.scss'

import { AdminStatusType } from 'app/store'
import { ReactComponent as CancelOrderIcon } from 'assets/icons/svgIcons/cancelOrderIcon.svg'
import { ReactComponent as EditOrderIcon } from 'assets/icons/svgIcons/editOrderIcon.svg'

type ManagerActionsPropsType = {
  editOrderHandler: () => void
  cancelOrderHandler: () => void
  openSelectWarehouseModal: () => void
  orderStatus: AdminStatusType
  confirmPaymentHandler: () => void
  cart: CartType
}
export const ManagerActions = (props: ManagerActionsPropsType) => {
  const {
    editOrderHandler,
    cancelOrderHandler,
    openSelectWarehouseModal,
    orderStatus,
    confirmPaymentHandler,
    cart,
  } = props

  const disabledBtn = orderStatus === 'Cancelled' || orderStatus === 'Delivered'

  return (
    <>
      {orderStatus === 'Paid' ? (
        <Button
          className={styles.confirmBtn}
          mod="fluid"
          size="base"
          variant="default"
          type="button"
          onClick={confirmPaymentHandler}
          // disabled={orderStatus !== 'Paid'}
        >
          подтвердить оплату
        </Button>
      ) : (
        <Button
          className={styles.inPaymentBtn}
          mod="fluid"
          size="base"
          variant="default"
          type="button"
          onClick={openSelectWarehouseModal}
          disabled={isDisabledForPaymentBtn(orderStatus, cart)}
        >
          на оплату
        </Button>
      )}

      <Button
        className={styles.editOrderBtn}
        mod="fluid"
        size="base"
        variant="default"
        type="button"
        onClick={editOrderHandler}
        disabled={disabledBtn}
      >
        <EditOrderIcon className={`${disabledBtn ? styles.disabled : ''}`} />
        редактировать
      </Button>
      <Button
        className={styles.cancelOrderBtn}
        mod="fluid"
        size="base"
        variant="default"
        type="button"
        onClick={cancelOrderHandler}
        disabled={disabledBtn}
      >
        <CancelOrderIcon className={`${disabledBtn ? styles.disabled : ''}`} />
        отменить
      </Button>
    </>
  )
}

const isDisabledForPaymentBtn = (orderStatus: AdminStatusType, cart: CartType) => {
  return (
    orderStatus === 'Cancelled' ||
    orderStatus === 'Delivered' ||
    orderStatus === 'TakenByBuyer' ||
    orderStatus === 'ForPayment' ||
    orderStatus === 'PaymentConfirmed' ||
    orderStatus === 'SendTOSortingCenter' ||
    orderStatus === 'InSortingCenter' ||
    orderStatus === 'SendToDestination' ||
    orderStatus === 'NotPaidByUser' ||
    orderStatus === 'AwaitsUserPayment' ||
    !Object.values(cart).some(value => value.length > 0)
  )
}

import { AxiosResponse } from 'axios'

import { BasicResponse } from '../api-type'
import { instance } from '../instance'

import { UserRolesType } from 'app/store'
import { ViewProductType } from 'app/store/userReducer/types'
import CartType from 'types/cart-type'
import DeliveryAddress from 'types/delivery-address'
import UserData from 'types/user-data'
import UserPassport from 'types/user-passport'

export const authAPI = {
  login(data: UserAuthorizationRequestType) {
    return instance.post<
      '',
      AxiosResponse<UserAuthorizationResponseType>,
      UserAuthorizationRequestType
    >('/login', data)
  },
  logout() {
    return instance.post<BasicResponse>('logout')
  },
  fetch() {
    return instance.get<{ info: string; error: string }>('/cart/fetch-product')
  },
  registration(data: UserRegistrationRequestType) {
    return instance.post<UserRegistrationResponseType>('/registration', data)
  },
  checkUserAuth() {
    return instance.post<CheckUserResponseType>('/auth/check')
  },
  forgotPassword(payload: { userEmail: string }) {
    return instance.post<BasicResponse>('/forgot', payload)
  },
  setNewPassword(data: setNewPasswordType) {
    return instance.post('/forgot/reset', data)
  },
  confirmEmail(payload: ConfirmEmailPayloadType) {
    return instance.get<BasicResponse>(`/email/verify/${payload.id}/${payload.hash}`, {
      params: {
        expires: payload.expires,
        signature: payload.signature,
      },
    })
  },
  sendConfirmationEmail() {
    return instance.get<BasicResponse>('/email/send-confirmation')
  },
}

// types ============================================================

export type ConfirmEmailPayloadType = {
  id: string
  hash: string
  expires: string
  signature: string
}

export type UserAuthorizationRequestType = {
  userEmail: string
  userPassword: string
  cart: CartType
}

export type UserAuthorizationResponseType = BasicResponse & {
  userId: number
  cart: CartType
  token: string
  userName: string
  userPhone: string
  userEmail: string
  birthdayDate: string
}

export type UserRegistrationRequestType = {
  userFullName: string
  userEmail: string
  userPhone: string
  userPassword: string
  inviteToken?: string
  cart?: CartType
}

export type UserRegistrationResponseType = BasicResponse & {
  userId: number
  cart: CartType
  token: string
}
export type FetchUserResponseType = {
  userData: UserData
  userPassport?: UserPassport
  addresses: DeliveryAddress[]
  cart: CartType
  viewCart: ViewProductType[]
}

export type CheckUserResponseType = BasicResponse & {
  birthdate: string | null
  userEmail: string | null
  userId: number | null
  userName: string | null
  userPhone: string | null
  userRole: UserRolesType | null
  blocked: boolean
  emailVerified: boolean
}

export type setNewPasswordType = {
  email: string
  password: string
  token: string
}

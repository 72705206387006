import React from 'react'

import { dateConverter } from '../../../utils/dateConverter'
import { formatSum } from '../../../utils/format-sum'
import { GoodsImgMapper } from '../GoodsImgMapper/GoodsImgMapper'

import styles from './MobileOrderComponent.module.scss'

import { OrderStatusHandler } from 'common/components'
import { allOrderHistoryType } from 'types/user-history-type'

export const MobileOrderComponent = ({ orders, onClick }: MobileOrderComponentPropsType) => {
  return (
    <div className={styles.container}>
      {orders &&
        orders.map(order => {
          if (!order) {
            return null
          }

          return (
            <div
              key={order.orderId}
              className={styles.orderItem}
              onClick={() => onClick(order.orderId)}
            >
              <div className={styles.itemStatus}>
                <OrderStatusHandler status={order.userStatus} />
              </div>
              <div className={styles.itemTitle}>
                Заказ № {order.orderId} от {dateConverter(order.orderDate)}
              </div>
              <div className={styles.itemPrice}>{formatSum(order.orderTotalSum)} ₽</div>
              <GoodsImgMapper order={order} />
            </div>
          )
        })}
    </div>
  )
}

// types =======================================================================

type MobileOrderComponentPropsType = {
  orders: allOrderHistoryType[]
  onClick: (orderId: string) => void
}

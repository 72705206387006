import React, { useEffect } from 'react'

import { useFormik } from 'formik'
import TextareaAutosize from 'react-textarea-autosize'

import { useAppDispatch, useAppSelector } from '../../../../hooks/customHooks'
import { Button, Input, RadioButton, Switch } from '../../../../ui'

import styles from './EditOrderDataModal.module.scss'

import { CurrentOrderType, editOrderTC, ModalType, selectedGenders } from 'app/store'
import { convertDateFormat, formatPhoneNumber, getValidScheme } from 'common'
import { GenderRadio } from 'common/components'

type EditOrderDataModalPropsType = {
  orderData: CurrentOrderType
  modalType: ModalType
}

export const EditOrderDataModal = ({ orderData, modalType }: EditOrderDataModalPropsType) => {
  const dispatch = useAppDispatch()

  const genders = useAppSelector(selectedGenders)

  const formik = useFormik({
    initialValues: {
      deliveryAddress: {
        address: orderData.deliveryAddress.address ? orderData.deliveryAddress.address : '',
        entrance: orderData.deliveryAddress.entrance ? orderData.deliveryAddress.entrance : '',
        floor: orderData.deliveryAddress.floor ? orderData.deliveryAddress.floor : '',
        flatNumber: orderData.deliveryAddress.flatNumber
          ? orderData.deliveryAddress.flatNumber
          : '',
        intercom: orderData.deliveryAddress.intercom ? orderData.deliveryAddress.intercom : '',
        commentForCourier: orderData.deliveryAddress.commentForCourier
          ? orderData.deliveryAddress.commentForCourier
          : '',
      },
      userName: orderData.userName,
      userEmail: orderData.userEmail ? orderData.userEmail : '',
      userPhone: orderData.userPhone ? orderData.userPhone : '',
      isCallNeeded: orderData.isCallNeeded,
      communicationType: orderData.communicationType,
      communicationLink: orderData.communicationLink,
      userPassportData: {
        // country: orderData.userPassportData.country ? orderData.userPassportData.country : '',
        country: 'Российская Федерация',
        passportNumber: orderData.userPassportData.passportNumber,
        receiveDate: orderData.userPassportData.receiveDate,
        unitCode: orderData.userPassportData.unitCode,
        whoGaveOut: orderData.userPassportData.whoGaveOut,
        birthdate: orderData.userPassportData.birthdate,
        gender: orderData.userPassportData.gender,
      },
    },
    validationSchema: getValidScheme(modalType),
    onSubmit: values => {
      if (formik.dirty) {
        // Только если продукт изменился, создайте новый объект `editedProduct`
        const editedOrder: CurrentOrderType = {
          ...orderData, // Копирование остальных полей продукта
          ...values,
          userPhone: formatPhoneNumber(values.userPhone, false),
          userPassportData: {
            ...values.userPassportData,
            birthdate: convertDateFormat(values.userPassportData.birthdate, 'yyyy-mm-dd'),
          },
        }

        dispatch(editOrderTC(editedOrder))
      }
    },
  })

  useEffect(() => {
    formik.validateForm()
  }, [])

  const handleSubmit = () => {
    formik.handleSubmit()
  }

  return (
    <form className={styles.modalBox}>
      <div className={styles.header}>редактировать заказ</div>
      <div className={styles.content}>
        <div className={styles.addressBox}>
          <span className={styles.subTitle}>адрес</span>
          <div className={styles.addressInputs}>
            <div className={styles.addressName}>
              <Input
                size={'base'}
                variant={'default'}
                label={'адрес доставки'}
                name={'deliveryAddress.address'}
                value={formik.values.deliveryAddress.address}
                onChange={formik.handleChange}
                type={'text'}
                className={styles.addressInput}
                hasError={!!formik.errors.deliveryAddress?.address}
                errorText={formik.errors.deliveryAddress?.address}
              />
            </div>
            <div className={styles.addressDetails}>
              <div className={styles.inputBox}>
                <Input
                  value={formik.values.deliveryAddress.entrance}
                  name={'deliveryAddress.entrance'}
                  onChange={formik.handleChange}
                  size="large"
                  variant="default"
                  type={'text'}
                  label={'подъезд'}
                  mask={'__'}
                />
                <Input
                  value={formik.values.deliveryAddress.floor}
                  name={'deliveryAddress.floor'}
                  onChange={formik.handleChange}
                  size="large"
                  variant="default"
                  type={'text'}
                  label={'этаж'}
                  mask={'__'}
                />
              </div>
              <div className={styles.inputBox}>
                <Input
                  value={formik.values.deliveryAddress.flatNumber}
                  name={'deliveryAddress.flatNumber'}
                  onChange={formik.handleChange}
                  size="large"
                  variant="default"
                  type={'text'}
                  label={'квартира'}
                  mask={'____'}
                />
                <Input
                  value={formik.values.deliveryAddress.intercom}
                  name={'deliveryAddress.intercom'}
                  onChange={formik.handleChange}
                  size="large"
                  variant="default"
                  type={'text'}
                  label={'домофон'}
                  mask={'______'}
                />
              </div>
            </div>
            <div className={styles.addressNotice}>
              комментарий курьеру
              <TextareaAutosize
                minRows={3}
                name={'deliveryAddress.commentForCourier'}
                value={formik.values.deliveryAddress.commentForCourier}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </div>

        <div className={styles.userDataBox}>
          <span className={styles.subTitle}>контактное лицо для получения</span>
          <Input
            value={formik.values.userName}
            name={'userName'}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'имя и фамилия'}
            errorText={formik.errors.userName}
            hasError={!!formik.errors.userName}
          />
          <Input
            value={formik.values.userEmail}
            name={'userEmail'}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            label={'почта'}
            errorText={formik.errors?.userEmail}
            hasError={!!formik.errors?.userEmail}
          />
          <Input
            value={formik.values.userPhone}
            name={'userPhone'}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'phone'}
            label={'телефон'}
            mask={'+_ (___) ___-__-__'}
            errorText={formik.errors.userPhone}
            hasError={!!formik.errors.userPhone}
          />
          <div className={styles.switchBox}>
            <Switch
              name={'isCallNeeded'}
              value={formik.values.isCallNeeded}
              onFormikChange={formik.handleChange}
            />
            <span className={styles['switch-text']}>Позвонить мне для подтверждения</span>
          </div>

          <div className={styles.radioButtonsBox}>
            <span className={styles.baseTitle}>удобный способ связи</span>
            <RadioButton
              currentValue={formik.values.communicationType}
              onChange={value => formik.setFieldValue('communicationType', value)}
            />
            <Input
              value={formik.values.communicationLink}
              name={'communicationLink'}
              onChange={formik.handleChange}
              size="large"
              variant="default"
              type={'text'}
              label={'ссылка на мессенджер или почта'}
            />
          </div>
        </div>
        <div className={styles.userPassportDataBox}>
          <span className={styles.subTitle}>паспортные данные</span>
          <Input
            value={formik.values.userPassportData.country}
            name={'userPassportData.country'}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'страна'}
            hasError={!!formik.errors.userPassportData?.country}
            errorText={formik.errors.userPassportData?.country}
            disabled={true}
          />
          <Input
            value={formik.values.userPassportData.passportNumber}
            name={'userPassportData.passportNumber'}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'серия и номер'}
            mask={'____ ______'}
            hasError={!!formik.errors.userPassportData?.passportNumber}
            errorText={formik.errors.userPassportData?.passportNumber}
          />
          <Input
            value={formik.values.userPassportData.receiveDate}
            name={'userPassportData.receiveDate'}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'когда выдан'}
            mask={'__.__.____'}
            errorText={formik.errors.userPassportData?.receiveDate}
            hasError={!!formik.errors.userPassportData?.receiveDate}
          />
          <Input
            value={formik.values.userPassportData.unitCode}
            name={'userPassportData.unitCode'}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'код подразделения'}
            mask={'___-___'}
            hasError={!!formik.errors.userPassportData?.unitCode}
            errorText={formik.errors.userPassportData?.unitCode}
          />
          <Input
            value={formik.values.userPassportData.whoGaveOut}
            name={'userPassportData.whoGaveOut'}
            onChange={formik.handleChange}
            size="large"
            variant="default"
            type={'text'}
            label={'кем выдан'}
            hasError={!!formik.errors.userPassportData?.whoGaveOut}
            errorText={formik.errors.userPassportData?.whoGaveOut}
          />
          <Input
            value={formik.values.userPassportData.birthdate}
            name={'userPassportData.birthdate'}
            size={'base'}
            variant={'default'}
            label={'дата рождения'}
            onChange={formik.handleChange}
            mask={'__.__.____'}
            onBlur={() => formik.setFieldTouched('userPassport.gender', true)}
            hasError={!!formik.errors?.userPassportData?.birthdate}
            setFormikFieldValue={formik.setFieldTouched}
            errorText={formik.errors.userPassportData?.birthdate}
          />
          <GenderRadio
            genders={genders}
            onChange={value => formik.setFieldValue('userPassportData.gender', value)}
            name={'edit-order'}
            hasError={!!formik?.errors?.userPassportData?.gender}
            currentGender={formik.values.userPassportData.gender}
            errorText={formik.errors.userPassportData?.gender}
          />
        </div>
      </div>
      <div className={styles.submitBtn}>
        <Button
          mod="fluid"
          size="large"
          variant="default"
          type="submit"
          onClick={handleSubmit}
          disabled={!formik.dirty || Object.keys(formik.errors).length > 0}
        >
          сохранить
        </Button>
      </div>
    </form>
  )
}

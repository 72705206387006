import {
  APP_SET_ADMIN_PANEL_TAB,
  APP_SET_ANNEX_DOCUMENT,
  APP_SET_BUYER_ACCOUNT_TAB,
  APP_SET_COOKIE_MSG,
  APP_SET_ERROR,
  APP_SET_FAQ,
  APP_SET_FORGOT_PASSWORD_STATUS,
  APP_SET_INFO,
  APP_SET_IS_INITIALIZED,
  APP_SET_OFFER_AGREEMENT,
  APP_SET_PAYMENT_AND_REFUND,
  APP_SET_PREVIEW_STATUS,
  APP_SET_PRIVACY_POLICY,
  APP_SET_REQUISITES,
  APP_SET_ROOT_MODAL_DATA,
  APP_SET_STATUS,
  APP_SET_SUCCESS,
  APP_SET_USER_ACCOUNT_TAB,
  APP_SET_USER_AGREEMENT,
  SET_ADD_CARD_URL,
  SET_IMG_PREVIEW_DATA,
  SET_MENU_ICON_POSITION,
} from './actions-type'
import {
  AdminPanelTabsType,
  AppStatusType,
  DocumentsPageType,
  FAQType,
  FeedbackType,
  ImgPreviewType,
  PreviewStatusType,
  RequisitesType,
  RootModalDataType,
  UserAccountTabType,
} from './types'

export const setAppStatusAC = (status: AppStatusType) => ({ type: APP_SET_STATUS, status } as const)
export const setAppErrorAC = (payload: FeedbackType) => ({ type: APP_SET_ERROR, payload } as const)
export const setAppSuccessAC = (payload: FeedbackType) =>
  ({ type: APP_SET_SUCCESS, payload } as const)
export const setAppInfoAC = (payload: FeedbackType) => ({ type: APP_SET_INFO, payload } as const)
export const setAppUseCookiesAC = (payload: FeedbackType) =>
  ({ type: APP_SET_COOKIE_MSG, payload } as const)
export const setForgotPasswordStatusAC = (status: 'idle' | 'emailSent' | 'newPasswordSet') =>
  ({ type: APP_SET_FORGOT_PASSWORD_STATUS, status } as const)
export const setAppInitializedAC = (value: boolean) =>
  ({ type: APP_SET_IS_INITIALIZED, value } as const)
export const setAdminPanelTabAC = (tab: AdminPanelTabsType) =>
  ({ type: APP_SET_ADMIN_PANEL_TAB, tab } as const)
export const setBuyerAccountTabAC = (tab: AdminPanelTabsType) =>
  ({ type: APP_SET_BUYER_ACCOUNT_TAB, tab } as const)
export const setUserAccountTabAC = (tab: UserAccountTabType) =>
  ({ type: APP_SET_USER_ACCOUNT_TAB, tab } as const)
export const setRootModalDataAC = (payload: RootModalDataType) =>
  ({ type: APP_SET_ROOT_MODAL_DATA, payload } as const)
export const setPrivacyPolicyAC = (payload: DocumentsPageType) =>
  ({ type: APP_SET_PRIVACY_POLICY, payload } as const)
export const setOfferAgreementAC = (payload: DocumentsPageType) =>
  ({ type: APP_SET_OFFER_AGREEMENT, payload } as const)
export const setFaqAC = (payload: FAQType[]) => ({ type: APP_SET_FAQ, payload } as const)
export const setRequisitesAC = (payload: RequisitesType) =>
  ({ type: APP_SET_REQUISITES, payload } as const)
export const setPaymentAndRefundAC = (payload: DocumentsPageType) =>
  ({ type: APP_SET_PAYMENT_AND_REFUND, payload } as const)
export const setPreviewStatusAC = (payload: PreviewStatusType) =>
  ({ type: APP_SET_PREVIEW_STATUS, payload } as const)
export const setUserAgreementAC = (payload: DocumentsPageType) =>
  ({ type: APP_SET_USER_AGREEMENT, payload } as const)
export const setAnnexDocumentAC = (payload: DocumentsPageType) =>
  ({ type: APP_SET_ANNEX_DOCUMENT, payload } as const)
export const setImgPreviewDataAC = (payload: ImgPreviewType) =>
  ({ type: SET_IMG_PREVIEW_DATA, payload } as const)
export const setMenuIconPositionAC = (position: number) =>
  ({ type: SET_MENU_ICON_POSITION, position } as const)
export const setAddCardUrlAC = (url: string) => ({ type: SET_ADD_CARD_URL, url } as const)

import React from 'react'

import { NavLink } from 'react-router-dom'

import styles from './MobileAccountMenu.module.scss'

import { PATH } from 'app/routes/AppRoutes'
import { ReactComponent as LocationMobileIcon } from 'assets/icons/svgIcons/locationMobileIcon.svg'
import { ReactComponent as OrderMobileIcon } from 'assets/icons/svgIcons/orderMobileIcon.svg'
import { ReactComponent as PercentMobileIcon } from 'assets/icons/svgIcons/percentMobileIcon.svg'
import { ReactComponent as SettingsMobileIcon } from 'assets/icons/svgIcons/settingsMobileIcon.svg'
import { ReactComponent as Passport } from 'assets/icons/svgIcons/userPassport.svg'
import 'assets/styles/sass/variables.scss'

export const MobileAccountMenu = () => {
  return (
    <div className={styles.container}>
      <NavLink
        to={PATH.USER_ACCOUNT_HISTORY}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        {({ isActive }) => (
          <>
            <OrderMobileIcon className={isActive ? styles['icon--active'] : styles.icon} />
            <span>заказы</span>
          </>
        )}
      </NavLink>
      <NavLink
        to={PATH.USER_ACCOUNT_BONUSES}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        {({ isActive }) => (
          <>
            <PercentMobileIcon className={isActive ? styles['icon--active'] : styles.icon} />
            <span>бонусы</span>
          </>
        )}
      </NavLink>
      <NavLink
        to={PATH.USER_ACCOUNT_PASSPORT}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        {({ isActive }) => (
          <>
            <Passport className={isActive ? styles['icon--active'] : styles.icon} />
            <span>паспорта</span>
          </>
        )}
      </NavLink>
      <NavLink
        to={PATH.USER_ACCOUNT_ADDRESSES}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        {({ isActive }) => (
          <>
            <LocationMobileIcon className={isActive ? styles['icon--active'] : styles.icon} />
            <span>адреса</span>
          </>
        )}
      </NavLink>
      <NavLink
        to={PATH.USER_ACCOUNT_SETTINGS}
        className={({ isActive }) =>
          [isActive ? `${styles['menuItem--active']} ` : styles.menuItem].join(' ')
        }
      >
        {({ isActive }) => (
          <>
            <SettingsMobileIcon className={isActive ? styles['icon--active'] : styles.icon} />
            <span>настройки</span>
          </>
        )}
      </NavLink>
    </div>
  )
}

import React from 'react'

import { useAppDispatch } from '../../../../hooks/customHooks'
import { Button, Input } from '../../../../ui'

import styles from './TrackNumbers.module.scss'

import { deleteTrackNumberTC, TrackNumbersType } from 'app/store'
import { AvailableBrandEnum, ShopNameEnum } from 'types/available-brand-enum'
import CartType from 'types/cart-type'

type TrackNumbersPropsType = {
  numbers: TrackNumbersType | null
  setNumbers: (numbers: TrackNumbersType) => void
  orderId: number
  cart: CartType
}

export const TrackNumbers = (props: TrackNumbersPropsType) => {
  const { numbers, orderId, cart, setNumbers } = props
  const dispatch = useAppDispatch()

  const onInputChange = (value: string, index: number, shop: string) => {
    const updatedNumbers = numbers ? { ...numbers } : ({} as TrackNumbersType)
    const updatedShop = updatedNumbers[shop as AvailableBrandEnum] ?? []

    updatedShop[index] = { trackNum: value }
    updatedNumbers[shop as AvailableBrandEnum] = updatedShop

    setNumbers(updatedNumbers)
  }

  const addTrackNumHandler = (shop: string) => {
    const updatedNumbers = numbers ? { ...numbers } : ({} as TrackNumbersType)
    const updatedShop = updatedNumbers[shop as AvailableBrandEnum] ?? []

    updatedShop.push({ trackNum: '' })
    updatedNumbers[shop as AvailableBrandEnum] = updatedShop

    setNumbers(updatedNumbers)
  }

  const deleteTrackNumHandler = (shop: string, index: number) => {
    const updatedNumbers = numbers ? { ...numbers } : ({} as TrackNumbersType)
    const updatedShop = updatedNumbers[shop as AvailableBrandEnum] ?? []

    updatedShop.splice(index, 1)
    updatedNumbers[shop as AvailableBrandEnum] = updatedShop

    setNumbers(updatedNumbers)
  }

  return (
    <div className={styles.trackNums}>
      <div className={styles.title}>Номер отслеживания</div>
      {cart &&
        Object.entries(cart).map(([shop, value]) => {
          if (value && value.length > 0) {
            const shopKey = shop as keyof typeof ShopNameEnum

            const disabled =
              numbers &&
              numbers[shop as AvailableBrandEnum] &&
              numbers[shop as AvailableBrandEnum][numbers[shop as AvailableBrandEnum]?.length - 1]
                ?.trackNum === ''

            return (
              <div className={styles.shop} key={shop}>
                <div className={styles.name}>{ShopNameEnum[shopKey]}</div>
                <div className={styles.info}>
                  {numbers &&
                    numbers[shop as AvailableBrandEnum]?.map((num, index) => {
                      const deleteHandler = () => {
                        if (num.trackId) {
                          dispatch(deleteTrackNumberTC(num.trackId, orderId))
                          deleteTrackNumHandler(shop, index)
                        } else {
                          deleteTrackNumHandler(shop, index)
                        }
                      }

                      return (
                        <Input
                          key={index}
                          size="large"
                          variant="default"
                          type="text"
                          label={'Трек-номер'}
                          clearStyle="transparent"
                          value={num.trackNum}
                          hasClear={true}
                          changeClearIcon={true}
                          onInput={value => onInputChange(value, index, shop)}
                          onClear={deleteHandler}
                        />
                      )
                    })}
                </div>
                <div className={styles.add}>
                  <Button
                    size="small"
                    variant="transparent"
                    type="button"
                    className={styles.btn}
                    onClick={() => addTrackNumHandler(shop)}
                    disabled={disabled || false}
                  >
                    + добавить еще
                  </Button>
                </div>
              </div>
            )
          }
        })}
    </div>
  )
}

import React, { useEffect, useState } from 'react'

import { Button } from '../../../ui'

import styles from './TimeTracker.module.scss'

type TimeTrackerPropsType = {
  takeTime: string
  openCancelOrderModal: () => void
}
export const TimeTracker = (props: TimeTrackerPropsType) => {
  const { takeTime, openCancelOrderModal } = props
  const [remainingTime, setRemainingTime] = useState('00:00:00')

  useEffect(() => {
    const orderTimestamp = new Date(takeTime).getTime()
    const endTime = orderTimestamp + 24 * 60 * 60 * 1000

    const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000 // получаем смещение часового пояса в миллисекундах

    const intervalId = setInterval(() => {
      const currentTime = Date.now() + timezoneOffset // добавляем смещение часового пояса
      const timeLeft = endTime - currentTime

      if (timeLeft <= 0) {
        clearInterval(intervalId)
        setRemainingTime('00:00:00')
      } else {
        const hours = Math.floor((timeLeft / (1000 * 60 * 60)) % 24)
        const minutes = Math.floor((timeLeft / (1000 * 60)) % 60)
        const seconds = Math.floor((timeLeft / 1000) % 60)

        setRemainingTime(
          `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds
            .toString()
            .padStart(2, '0')}`
        )
      }
    }, 1000)

    // Возвращаем функцию для очистки
    return () => clearInterval(intervalId)
  }, [takeTime])

  return (
    <div className={styles.box}>
      <div className={styles.info}>
        <div className={styles.title}>Времени осталось</div>
        <div className={styles.time}>{remainingTime}</div>
      </div>
      <div className={styles.action}>
        <Button
          mod="fluid"
          size="small"
          variant="default"
          type="button"
          onClick={openCancelOrderModal}
        >
          вернуть в пул
        </Button>
      </div>
    </div>
  )
}

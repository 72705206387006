import {
  setAddCardUrlAC,
  setAdminPanelTabAC,
  setAnnexDocumentAC,
  setAppErrorAC,
  setAppInfoAC,
  setAppInitializedAC,
  setAppStatusAC,
  setAppSuccessAC,
  setAppUseCookiesAC,
  setBuyerAccountTabAC,
  setFaqAC,
  setForgotPasswordStatusAC,
  setImgPreviewDataAC,
  setMenuIconPositionAC,
  setOfferAgreementAC,
  setPaymentAndRefundAC,
  setPreviewStatusAC,
  setPrivacyPolicyAC,
  setRequisitesAC,
  setRootModalDataAC,
  setUserAccountTabAC,
  setUserAgreementAC,
} from './actions'

export const APP_SET_STATUS = 'app/SET-STATUS'
export const APP_SET_IS_INITIALIZED = 'app/SET-IS-INITIALIZED'
export const APP_SET_ADMIN_PANEL_TAB = 'app/SET-ADMIN-PANEL-TAB'
export const APP_SET_BUYER_ACCOUNT_TAB = 'app/SET-BUYER-ACCOUNT-TAB'
export const APP_SET_USER_ACCOUNT_TAB = 'app/SET-USER-ACCOUNT-TAB'
export const APP_SET_ROOT_MODAL_DATA = 'app/SET-ROOT-MODAL-DATA'
export const APP_SET_PRIVACY_POLICY = 'app/SET-PRIVACY-POLICY'
export const APP_SET_OFFER_AGREEMENT = 'app/SET-OFFER-AGREEMENT'
export const APP_SET_FAQ = 'app/SET-FAQ'
export const APP_SET_FORGOT_PASSWORD_STATUS = 'app/SET-FORGOT-PASSWORD-STATUS'
export const APP_SET_REQUISITES = 'app/SET-REQUISITES'
export const APP_SET_PAYMENT_AND_REFUND = 'app/SET-PAYMENT-AND-REFUND'
export const APP_SET_PREVIEW_STATUS = 'app/SET-PREVIEW-STATUS'
export const APP_SET_USER_AGREEMENT = 'app/SET-USER-AGREEMENT'
export const APP_SET_ANNEX_DOCUMENT = 'app/SET-ANNEX-DOCUMENT'
export const APP_SET_ERROR = 'app/SET-APP-ERROR'
export const APP_SET_SUCCESS = 'app/SET-APP-SUCCESS'
export const APP_SET_INFO = 'app/SET-APP-INFO'
export const APP_SET_COOKIE_MSG = 'app/SET-COOKIE-MSG'
export const SET_IMG_PREVIEW_DATA = 'app/SET-IMG-PREVIEW-LINK'
export const SET_MENU_ICON_POSITION = 'app/SET-MENU-ICON-POSITION'
export const SET_ADD_CARD_URL = 'app/SET-ADD-CARD-URL'

export type AppReducerActionsType =
  | ReturnType<typeof setAppStatusAC>
  | ReturnType<typeof setAppErrorAC>
  | ReturnType<typeof setAppSuccessAC>
  | ReturnType<typeof setAppInfoAC>
  | ReturnType<typeof setAppUseCookiesAC>
  | ReturnType<typeof setAppInitializedAC>
  | ReturnType<typeof setAdminPanelTabAC>
  | ReturnType<typeof setBuyerAccountTabAC>
  | ReturnType<typeof setUserAccountTabAC>
  | ReturnType<typeof setRootModalDataAC>
  | ReturnType<typeof setPrivacyPolicyAC>
  | ReturnType<typeof setOfferAgreementAC>
  | ReturnType<typeof setFaqAC>
  | ReturnType<typeof setForgotPasswordStatusAC>
  | ReturnType<typeof setRequisitesAC>
  | ReturnType<typeof setPaymentAndRefundAC>
  | ReturnType<typeof setPreviewStatusAC>
  | ReturnType<typeof setUserAgreementAC>
  | ReturnType<typeof setAnnexDocumentAC>
  | ReturnType<typeof setImgPreviewDataAC>
  | ReturnType<typeof setMenuIconPositionAC>
  | ReturnType<typeof setAddCardUrlAC>

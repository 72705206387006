import React, { useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { useAppSelector } from '../../hooks/customHooks'

import styles from './FooterGap.module.scss'

import { selectedViewCart } from 'app/store'
import { ViewProductType } from 'app/store/userReducer/types'

const getStyles = (page: string, viewCart: ViewProductType[]) => {
  switch (page) {
    case 'user-account':
      return styles.footerGapUserAccount
    case 'my-cart':
      if (viewCart.length > 0) {
        return styles['footerGapMyCart--active']
      } else {
        return styles.footerGapMyCart
      }
    case 'ordering':
      if (viewCart.length > 0) {
        return styles['footerGapOrdering--active']
      } else {
        return styles.footerGapOrdering
      }
    case 'privacy-policy':
      return ''
    case 'public-offer':
      return ''
    case 'requisites':
      return ''
    case 'payment-and-refund':
      return ''
    default:
      if (viewCart.length > 0) {
        return styles['footerGap--active']
      } else {
        return styles.footerGap
      }
  }
}

export const FooterGap = () => {
  const [FGStyles, setFGStyles] = useState<string | undefined>(undefined)
  const location = useLocation()
  const viewCart = useAppSelector(selectedViewCart)
  const page = location.pathname.split('/')[1].toString()

  useEffect(() => {
    setFGStyles(getStyles(page, viewCart))
  }, [location, viewCart, page])

  return <div className={FGStyles} id="footer-gap" />
}

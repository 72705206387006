import React from 'react'

import { Badge } from '../../../ui'

import styles from './OrdersTabComponent.module.scss'

export const OrdersTabComponent = ({
  activeTab,
  activeTabHandler,
  ordersCount,
  returnsCount,
}: OrdersTabComponentPropsType) => {
  const tabClassName = (tab: 'orders' | 'returns') => {
    return tab === activeTab ? `${styles.tabItem} ${styles.active}` : styles.tabItem
  }

  return (
    <div className={styles.orderTabBox}>
      <div className={tabClassName('orders')} onClick={() => activeTabHandler('orders')}>
        Заказы
        <Badge value={ordersCount} isActive={activeTab === 'orders'} />
      </div>
      <div className={tabClassName('returns')} onClick={() => activeTabHandler('returns')}>
        возвраты
        <Badge value={returnsCount} isActive={activeTab === 'returns'} />
      </div>
    </div>
  )
}

// types =======================================================================
type OrdersTabComponentPropsType = {
  activeTab: 'orders' | 'returns'
  activeTabHandler: (tab: 'orders' | 'returns') => void
  ordersCount: number
  returnsCount: number
}

import React from 'react'

import styles from './UsersCheckBox.module.scss'

import { UserRolesType, UsersByCharacteristicsType, UsersByRoleType } from 'app/store'
import { CharacteristicsType } from 'app/store/adminReducer/types'
import { ReactComponent as CheckedIcon } from 'assets/icons/svgIcons/checkedIcon.svg'
import { roleHandler } from 'common'
import { CheckBoxWrapper } from 'common/components'

type UsersCheckBoxPropsType = {
  resetHandler: () => void
  usersByRole: UsersByRoleType[]
  setFilterUserRole: (role: UserRolesType) => void
  setFilterUserCharacteristics: (characteristic: CharacteristicsType) => void
  filterUserRole: UserRolesType | null
  usersByCharacteristics: UsersByCharacteristicsType[]
  isReset: boolean
  selectedCharacteristic: CharacteristicsType | null
}
export const UsersCheckBox = (props: UsersCheckBoxPropsType) => {
  const {
    resetHandler,
    usersByRole,
    filterUserRole,
    setFilterUserRole,
    isReset,
    usersByCharacteristics,
    selectedCharacteristic,
    setFilterUserCharacteristics,
  } = props

  const selectRoleHandler = (role: UserRolesType) => {
    setFilterUserRole(role)
  }
  const selectCharacteristicHandler = (characteristic: CharacteristicsType) => {
    setFilterUserCharacteristics(characteristic)
  }

  return (
    <CheckBoxWrapper reset={resetHandler} className={styles.container} isReset={isReset}>
      <>
        {Array.isArray(usersByRole) &&
          usersByRole.map(user => {
            const itemStyle =
              user.role === filterUserRole ? `${styles.item} ${styles.selected}` : styles.item

            return (
              <div
                key={user.role}
                className={itemStyle}
                onClick={() => selectRoleHandler(user.role)}
              >
                <div className={styles.title}>
                  <div className={styles.role}>{roleHandler(user.role)}</div>
                  <div className={styles.count}>({user.count})</div>
                </div>
                <CheckedIcon
                  style={user.role === filterUserRole ? { opacity: 1 } : { opacity: 0 }}
                />
              </div>
            )
          })}
        {Array.isArray(usersByCharacteristics) &&
          usersByCharacteristics.map(el => {
            const itemStyle =
              el.characteristic === selectedCharacteristic
                ? `${styles.item} ${styles.selected}`
                : styles.item

            return (
              <div
                key={el.characteristic}
                className={itemStyle}
                onClick={() => selectCharacteristicHandler(el.characteristic)}
              >
                <div className={styles.title}>
                  <div className={styles.role}>{roleHandler(el.characteristic)}</div>
                  <div className={styles.count}>({el.count})</div>
                </div>
                <CheckedIcon
                  style={
                    el.characteristic === selectedCharacteristic ? { opacity: 1 } : { opacity: 0 }
                  }
                />
              </div>
            )
          })}
      </>
    </CheckBoxWrapper>
  )
}

import React from 'react'

import TextareaAutosize from 'react-textarea-autosize'

import { Button } from '../../../../ui'

import styles from './CancelOrderModal.module.scss'

type CancelOrderModalPropsType = {
  cancelOrderHandler?: () => void
  title: string
  btnText: string
  cancelNote: string
  buyerRejectNote?: string
  setCancelNote: (value: string) => void
  setBuyerRejectNote: (value: string) => void
}
export const CancelOrderModal = (props: CancelOrderModalPropsType) => {
  const {
    cancelOrderHandler,
    title,
    btnText,
    cancelNote,
    setCancelNote,
    buyerRejectNote,
    setBuyerRejectNote,
  } = props

  let disabled = !!(cancelNote === '' && buyerRejectNote && buyerRejectNote === '')

  return (
    <div className={styles.modalBox}>
      <div className={styles.modalHeader}>
        <div className={styles.title}>отменить заказ?</div>
        <div className={styles.text}>{title}</div>
      </div>
      <div className={styles.content}>
        <div className={styles.box}>
          <div className={styles.name}>клиент</div>
          <div className={styles.textareaBox}>
            причина отмены
            <TextareaAutosize
              minRows={3}
              value={cancelNote}
              onChange={event => setCancelNote(event.currentTarget.value)}
            />
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.name}>байер</div>
          <div className={styles.textareaBox}>
            причина отмены
            <TextareaAutosize
              minRows={3}
              value={buyerRejectNote}
              onChange={event => setBuyerRejectNote(event.currentTarget.value)}
            />
          </div>
        </div>
      </div>
      <div className={styles.modalFooter}>
        <Button
          mod="fluid"
          size="large"
          variant="default"
          type="button"
          onClick={cancelOrderHandler}
          disabled={disabled}
        >
          {btnText}
        </Button>
      </div>
    </div>
  )
}

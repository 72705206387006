import React from 'react'

import Avatar from '@mui/material/Avatar'

import { roleHandler } from '../../../utils/roleHandler'

import styles from './CustomAvatar.module.scss'

import { UserRolesType } from 'app/store'

type CustomAvatarPropsType = {
  name: string | undefined | null
  role: UserRolesType
}

function stringToColor(string: string) {
  let hash = 0
  let i

  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash)
  }

  let color = '#'

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff

    color += `00${value.toString(16)}`.slice(-2)
  }

  return color
}

function stringAvatar(name: string | undefined | null) {
  if (!name) {
    // Возвращаем дефолтное значение, если name отсутствует или пусто
    return {
      sx: {
        bgcolor: '#ccc', // Цвет по умолчанию
      },
      children: 'NA', // Значение по умолчанию
    }
  }

  // Разбиваем имя только если оно существует и не пустое
  const nameParts = name.split(' ').filter(Boolean)

  if (nameParts.length >= 2) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: 16,
      },
      children: `${nameParts[0][0]}${nameParts[1][0]}`,
    }
  } else if (nameParts.length === 1) {
    return {
      sx: {
        bgcolor: stringToColor(name),
        fontSize: 16,
      },
      children: nameParts[0][0],
    }
  }

  // Если имя пустое, возвращаем значения по умолчанию
  return {
    sx: {
      bgcolor: '#A833EF', // Цвет по умолчанию
      fontSize: 16, // Размер шрифта по умолчанию
    },
    children: 'NA', // Значение по умолчанию
  }
}

export const CustomAvatar = (props: CustomAvatarPropsType) => {
  const { name, role } = props

  return (
    <div className={styles.avatarBox}>
      <div className={styles.avatar}>{name && <Avatar {...stringAvatar(name)} />}</div>
      <div className={styles.nameBox}>
        <div className={styles.name}>{name}</div>
        <div className={styles.description}>{roleHandler(role)}</div>
      </div>
    </div>
  )
}

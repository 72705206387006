import React from 'react'

import styles from './order-info.module.scss'

import { CurrentOrderType } from 'app/store'
import { ReactComponent as PassportIcon } from 'assets/icons/svgIcons/passportIcon.svg'

type PassportDataComponentPropsType = {
  orderData: CurrentOrderType | null
}

export const PassportDataComponent = (props: PassportDataComponentPropsType) => {
  const { orderData } = props

  return (
    <div className={styles.orderInfoItem}>
      <div className={styles.itemTitle}>
        <PassportIcon />
        <span>паспортные данные</span>
      </div>
      <div className={styles.itemDescription}>
        <span>
          {orderData?.userPassportData?.country ? orderData?.userPassportData?.country : null}
          {orderData?.userPassportData?.passportNumber
            ? `, ${orderData?.userPassportData?.passportNumber}`
            : null}
          {orderData?.userPassportData?.unitCode
            ? `, ${orderData?.userPassportData?.unitCode}`
            : null}
          {orderData?.userPassportData?.whoGaveOut
            ? `, ${orderData?.userPassportData?.whoGaveOut}`
            : null}
          {orderData?.userPassportData?.receiveDate
            ? `, ${orderData?.userPassportData?.receiveDate}`
            : null}
        </span>
      </div>
    </div>
  )
}

export const socialMedia = [
  {
    name: 'tg',
    link: 'https://t.me/oh_really_ru',
  },
  {
    name: 'vk',
    link: 'https://vk.com/ohreally_ru',
  },
  {
    name: 'inst',
    link: 'https://www.instagram.com/oh.really.ru/',
  },
]

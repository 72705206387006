import {
  EmployeeType,
  GenderType,
  RangeType,
  UsersByCharacteristicsType,
  UsersByRoleType,
} from '../../app/store'
import { BasicResponse } from '../api-type'
import { instance } from '../instance'

export const adminEmployeeAPI = {
  getEmployee(value: GetEmployeeRequestType): Promise<GetEmployeeResponseType> {
    return instance.get('/admin/users', { params: value })
  },
  addEmployee(payload: AddEmployeeRequestType) {
    return instance.post<'', AddEditEmployeeResponseType, AddEmployeeRequestType>(
      '/admin/users',
      payload
    )
  },
  editEmployee(payload: UpdateEmployeeRequestType) {
    return instance.put<'', AddEditEmployeeResponseType, UpdateEmployeeRequestType>(
      '/admin/users',
      payload
    )
  },
  deleteEmployee(id: number) {
    return instance.delete(`/admin/users?userId=${id}`)
  },
}

// types ============================================================
export type GetEmployeeRequestType = {
  role?: string
  searchValue?: string //запрос из поиска по юзерам
  pageSize?: number // кол-во элементов на стр
  page?: number
  fromDate?: string
  toDate?: string
  blocked?: boolean
  usersWithOrders?: boolean
  prices?: RangeType
  gender?: GenderType
  age?: RangeType
}

export type GetEmployeeResponseType = {
  data: {
    statusCode: number
    message: string
    stat: StatType
    users: {
      total: number
      current_page: number
      last_page: number // используем как общее количество страниц (pagesCount)
      per_page: number // кол-во элементов на стр (pageSize)
      data: EmployeeType[]
    }
  }
}

export type AddEmployeeRequestType = {
  role: string
  userName: string
  userEmail: string
  userPassword: string
  priceMax?: number | null
  priceMin?: number | null
}
export type AddEditEmployeeResponseType = {
  data: BasicResponse
}

export type UpdateEmployeeRequestType = {
  userId: number
  role: string
  userEmail: string
  userName: string
  blocked?: boolean
  userPassword?: string
  priceMax?: number | null
  priceMin?: number | null
}

export type StatType = {
  byRoles: UsersByRoleType[]
  byCharacteristics: UsersByCharacteristicsType[]
}

import { RootResponse } from '../api-type'
import { instance } from '../instance'

import { CardType } from 'app/store'

export const adminCardsAPI = {
  getCards() {
    return instance.get<GetCardsDataResponse>('/bank-cards')
  },
  changeDefaultCard(payload: ChangeDefaultCardPayloadType) {
    return instance.put<'', CardResponseType, ChangeDefaultCardPayloadType>(`/bank-cards`, payload)
  },
  addNewCard() {
    return instance.post<'', CardResponseType, ''>('/bank-cards')
  },
  deleteCard(id: string) {
    return instance.delete(`/bank-cards`, { data: { id } })
  },
}

type GetCardsDataResponse = RootResponse<'cards', CardType[]>

type CardResponseType = {
  data: RootResponse<'url', string>
}

export type ChangeDefaultCardPayloadType = {
  id: string
  isDefault: boolean
}

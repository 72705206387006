import { CancelToken } from 'axios'

import ProductType from '../../types/product-type'
import { BasicResponse } from '../api-type'
import { instance } from '../instance'

export const fetchProductAPI = {
  get(link: string, cancelToken: CancelToken) {
    return instance.get<FetchProductResponseType>('/cart/fetch-product', {
      params: { productLink: link },
      cancelToken,
    })
  },
}

type FetchProductResponseType = ProductType & BasicResponse

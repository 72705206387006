import { AxiosError } from 'axios'

import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import { AppThunkType } from '../types'

import { setWarehousesAC } from './actions'
import { checkAdminAuthTC } from './thanks'
import { PhoneNumbersType, WarehouseType } from './types'

import { adminWarehouseAPI, WarehouseRequestType } from 'api/adminAPI/warehouse-api'
import { errorUtils, formatPhoneNumber } from 'common'

export const getWarehousesTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await adminWarehouseAPI.getWarehouses()

    if (response.data.statusCode === 0) {
      const payload = response.data.data.map((warehouse: WarehouseType) => ({
        id: warehouse.id,
        address: warehouse.address,
        numbers: warehouse.numbers.map((number: PhoneNumbersType) => ({
          telId: number.telId,
          telNum: formatPhoneNumber(number.telNum, true),
        })),
      }))

      dispatch(setWarehousesAC(payload))
      dispatch(setAppStatusAC('succeeded'))
    } else {
      dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      dispatch(setAppStatusAC('failed'))
    }
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkAdminAuthTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const addWarehouseTC =
  (payload: WarehouseRequestType): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const addPayload: WarehouseRequestType = {
        ...payload,
        numbers: payload.numbers.map(number => ({
          telId: 0,
          telNum: formatPhoneNumber(number.telNum, false),
        })),
      }

      const response = await adminWarehouseAPI.addWarehouse(addPayload)

      if (response.data.statusCode === 0) {
        console.log(response)
        dispatch(getWarehousesTC())
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
        dispatch(setAppStatusAC('succeeded'))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
        dispatch(setAppStatusAC('failed'))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const editWarehouseTC =
  (payload: WarehouseRequestType): AppThunkType =>
  async dispatch => {
    try {
      const editPayload: WarehouseRequestType = {
        ...payload,
        numbers: payload.numbers.map(number => ({
          telId: number.telId,
          telNum: formatPhoneNumber(number.telNum, false),
        })),
      }

      const response = await adminWarehouseAPI.editWarehouse(editPayload)

      if (response.data.statusCode === 0) {
        dispatch(getWarehousesTC())
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
        dispatch(setAppStatusAC('succeeded'))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
        dispatch(setAppStatusAC('failed'))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const deleteWarehouseTC =
  (id: number): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await adminWarehouseAPI.deleteWarehouse(id)

      if (response.data.statusCode === 0) {
        dispatch(getWarehousesTC())
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
        dispatch(setAppStatusAC('succeeded'))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
        dispatch(setAppStatusAC('failed'))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

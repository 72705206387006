import { AppRootState } from '../types'

export const selectedTab = (state: AppRootState) => state.app.activeAdminPanelTab
export const selectedBuyerTab = (state: AppRootState) => state.app.activeBuyerPanelTab
export const selectedAppStatus = (state: AppRootState) => state.app.appStatus
export const selectedAppError = (state: AppRootState) => state.app.appError
export const selectedAppSuccess = (state: AppRootState) => state.app.appSuccess
export const selectedAppUseCookies = (state: AppRootState) => state.app.appUseCookies
export const selectedAppInfo = (state: AppRootState) => state.app.appInfo
export const activeUserAccountTab = (state: AppRootState) => state.app.activeUserAccountTab
export const selectedRootModalData = (state: AppRootState) => state.app.rootModal
export const selectedPrivacyPolicy = (state: AppRootState) => state.app.privacyPolicy
export const selectedOfferAgreement = (state: AppRootState) => state.app.offerAgreement
export const selectedFAQ = (state: AppRootState) => state.app.faq
export const selectedForgotPasswordStatus = (state: AppRootState) => state.app.forgotPasswordStatus
export const selectedExtensionId = (state: AppRootState) => state.app.extensionId
export const selectedRequisites = (state: AppRootState) => state.app.requisites
export const selectedPaymentAndRefund = (state: AppRootState) => state.app.paymentAndRefund
export const selectedUserAgreement = (state: AppRootState) => state.app.userAgreement
export const selectedPreviewStatus = (state: AppRootState) => state.app.previewStatus
export const selectedAnnexDocument = (state: AppRootState) => state.app.annexDocument
export const selectedImgPreviewData = (state: AppRootState) => state.app.imgPreviewData
export const selectedMenuIconPosition = (state: AppRootState) => state.app.menuIconPosition
export const selectedAddCardUrl = (state: AppRootState) => state.app.addCardUrl

import React from 'react'

import { dateConverter } from '../../../utils/dateConverter'
import { formatSum } from '../../../utils/format-sum'
import { GoodsImgMapper } from '../GoodsImgMapper/GoodsImgMapper'

import styles from './OrderComponent.module.scss'

import { OrderStatusHandler } from 'common/components'
import { allOrderHistoryType } from 'types/user-history-type'

type OrderComponentPropsType = {
  orders: allOrderHistoryType[]
  onClick: (orderId: string) => void
}

export const OrderComponent = ({ orders, onClick }: OrderComponentPropsType) => {
  return (
    <div className={styles.container}>
      {orders &&
        orders.map(order => {
          if (!order) {
            return null
          }

          return (
            <div
              key={order.orderId}
              className={styles.orderItem}
              onClick={() => onClick(order.orderId)}
            >
              <div className={styles.orderInfo}>
                <div className={styles.orderTitle}>
                  Заказ № {order.orderId} от {dateConverter(order.orderDate)}
                </div>
                <GoodsImgMapper order={order} />
              </div>
              <div className={styles.orderDescription}>
                <div className={styles.orderPrice}>{formatSum(order.orderTotalSum)} ₽</div>
                <div>
                  <OrderStatusHandler className={styles.orderStatus} status={order.userStatus} />
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

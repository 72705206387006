import { AxiosError } from 'axios'

import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import { AppThunkType } from '../types'

import { setPassportDataAC } from './actions'
import { checkUserTC } from './thanks'

import { passportAPI } from 'api/userAPI/passport-api'
import { convertDateFormat, errorUtils } from 'common'
import UserPassport from 'types/user-passport'

export const getPassportDataTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))

  try {
    const response = await passportAPI.getPassportData()

    if (response.data.statusCode === 0) {
      const passports = response.data.passports.map((passport: UserPassport) => ({
        ...passport,
        issueDate: convertDateFormat(passport.issueDate, 'dd.mm.yyyy'),
        birthdate: convertDateFormat(passport.birthdate, 'dd.mm.yyyy'),
      }))

      dispatch(setPassportDataAC(passports))
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkUserTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const addPassportDataTC =
  (payload: UserPassport): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const data: UserPassport = {
        ...payload,
        issueDate: convertDateFormat(payload.issueDate, 'yyyy-mm-dd'),
        birthdate: convertDateFormat(payload.birthdate, 'yyyy-mm-dd'),
      }

      const response = await passportAPI.setPassportData(data)

      if (response.data.statusCode === 0) {
        dispatch(getPassportDataTC())

        dispatch(setAppSuccessAC({ type: 'success', title: 'паспортные данные добавлены' }))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const changePassportDataTC =
  (payload: UserPassport): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))

      const data: UserPassport = {
        ...payload,
        issueDate: convertDateFormat(payload.issueDate, 'yyyy-mm-dd'),
        birthdate: convertDateFormat(payload.birthdate, 'yyyy-mm-dd'),
      }
      const response = await passportAPI.changePassportData(data)

      if (response.data.statusCode === 0) {
        dispatch(getPassportDataTC())

        dispatch(setAppSuccessAC({ type: 'success', title: 'паспортные данные изменены' }))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const removePassportTC =
  (id: number): AppThunkType =>
  async dispatch => {
    try {
      dispatch(setAppStatusAC('loading'))
      const response = await passportAPI.removePassport(id)

      if (response.data.statusCode === 0) {
        dispatch(getPassportDataTC())
        dispatch(setAppSuccessAC({ type: 'success', title: 'паспортные данные удалены' }))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkUserTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

import React, { ReactNode, useEffect, useState } from 'react'

import { useLocation } from 'react-router-dom'

import { ContactsSection } from '../../sections'
import MainHeader from '../HeaderBlock/MainHeader'

import { PATH } from 'app/routes/AppRoutes'

type MainLayoutPropsType = {
  children: ReactNode
}

const MainLayout = ({ children }: MainLayoutPropsType) => {
  const location = useLocation()

  const [isFixedHead, setIsFixedHead] = useState<boolean>(false)

  const workingPages =
    location.pathname.includes(PATH.USER_ACCOUNT) ||
    location.pathname.includes(PATH.MY_CART) ||
    location.pathname.includes(PATH.ORDERING) ||
    location.pathname.includes(PATH.PRIVACY_POLICY) ||
    location.pathname.includes(PATH.PUBLIC_OFFER) ||
    location.pathname.includes(PATH.PASSWORD_RECOVERY) ||
    // location.pathname.includes(PATH.REQUISITES) ||
    location.pathname.includes(PATH.PAYMENT_AND_REFUND) ||
    location.pathname.includes(PATH.USER_AGREEMENT) ||
    location.pathname.includes(PATH.EMAIL_VERIFY)

  const handlerScroll = () => {
    const scroll = window.scrollY

    setIsFixedHead(scroll > 20) // minus header height
  }

  useEffect(() => {
    window.addEventListener('scroll', () => handlerScroll())

    return window.removeEventListener('scroll', handlerScroll)
  }, [])
  window.postMessage({ action: 'CHECK_BUTTON' })

  return (
    <div>
      {workingPages && <MainHeader isFixed={isFixedHead} />}
      <main style={{ position: 'relative' }}>{children}</main>
      <ContactsSection />
    </div>
  )
}

export default MainLayout

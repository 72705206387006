import { useState, useEffect } from 'react'

import styles from './OnBoardingTip.module.scss'

import { ReactComponent as CloseIcn } from 'assets/icons/close.svg'
import { Button } from 'common/ui'

const OnBoardingTip = () => {
  const [shown, setShown] = useState(false as boolean)

  const closeHandler = () => {
    setShown(false)
    localStorage.setItem('onBoarding', 'true')
  }

  useEffect(() => {
    setShown(!localStorage.getItem('onBoarding'))
  }, [])

  return (
    <div className={`${styles.block} ${shown ? styles['block--shown'] : ''}`}>
      <button className={styles.close} onClick={closeHandler}>
        <CloseIcn className={styles.closeIcn} />
      </button>

      <div className={styles.body}>
        <div className={styles.title}>
          Как заказать товары
          <br /> с Farfetch или Yoox
        </div>
        <div className={styles.text}>
          Выберите товар на Farfetch или Yoox, скопируйте на него ссылку и вставьте её в серое поле
          на этом сайте
        </div>

        <Button onClick={closeHandler} size="small" variant="white" className={styles.closeBtn}>
          понятно
        </Button>
      </div>
    </div>
  )
}

export default OnBoardingTip

import {
  ADD_PRODUCT,
  CLEAR_CART,
  EDIT_PRODUCT,
  FETCH_USER,
  FETCH_USER_CART,
  REMOVE_PRODUCT,
  SET_ADDRESSES,
  SET_ALL_ORDER_HISTORY,
  SET_ALL_ORDERS_COUNT,
  SET_BONUSES,
  SET_CURRENT_ORDER,
  SET_CURRENT_PAGE,
  SET_DELETED_PRODUCT,
  SET_DELIVERY_COST,
  SET_DISCOUNTED_CART,
  SET_IS_USE_DISCOUNT,
  SET_IS_VERIFIED_EMAIL,
  SET_ORDERING_RESPONSE,
  SET_PAGES_COUNT,
  SET_PASSPORT_DATA,
  SET_RETURNED_ORDER,
  SET_RETURNED_ORDERS_COUNT,
  SET_SERVICE_FEE,
  SET_TOTAL_RUB_SUM,
  UPDATE_USER_DATA,
  USER_LOGOUT,
  UserReducerActionType,
} from './actions-types'
import {
  FetchUserPayload,
  InitialStateType,
  InvitedUserType,
  OrderCreatedType,
  ViewProductType,
} from './types'

import { CheckUserResponseType } from 'api/userAPI/user-authorization-api'
import { getEmptyCart } from 'common'
import CartType from 'types/cart-type'
import DeliveryAddress from 'types/delivery-address'
import ProductType from 'types/product-type'

const initialState: InitialStateType = {
  userId: null,
  blocked: false,
  isUseDiscount: false,
  totalRubSum: 0,
  deliveryCost: 0,
  serviceFee: 0,
  createdOrderId: null,
  paymentUrl: null,
  userData: {
    userName: null,
    birthdate: null,
    userEmail: null,
    userPhone: null,
    isCallNeeded: false,
    communicationType: null,
    communicationLink: null,
    emailVerified: null,
  },
  userPassport: [],
  addresses: [],
  cart: getEmptyCart(),
  deletedProduct: null,
  viewCart: [],
  history: {
    allOrderHistory: [],
    allOrdersCount: 0,
    returnedOrder: [],
    returnedOrdersCount: 0,
    pageSize: 5,
    currentPage: 1,
    pagesCount: 0,
    currentOrder: null,
  },
  bonuses: {
    inviteLink: '',
    discountSize: 0,
    discountCount: 0,
    invitedUsers: [] as InvitedUserType[] | null,
  },
}

export const userReducer = (
  state = initialState,
  action: UserReducerActionType
): InitialStateType => {
  switch (action.type) {
    case FETCH_USER: {
      const { userId, cart, viewCart, userName, userPhone, userEmail, birthdayDate } =
        action.payload as FetchUserPayload

      return {
        ...state,
        userId: userId,
        userData: {
          ...state.userData,
          userName: userName,
          userPhone: userPhone,
          userEmail: userEmail,
          birthdate: birthdayDate,
        },
        cart: {
          Farfetch: cart.Farfetch,
          Yoox: cart.Yoox,
          Dior: cart.Dior,
          MrPorter: cart.MrPorter,
          NetAPorter: cart.NetAPorter,
          Prada: cart.Prada,
        },
        viewCart,
      }
    }
    case FETCH_USER_CART: {
      if (typeof action.payload === 'object' && 'cart' in action.payload) {
        const cart = (action.payload as { cart: CartType }).cart
        const viewCart = (action.payload as { viewCart: ViewProductType[] }).viewCart

        return {
          ...state,
          cart: {
            Farfetch: cart.Farfetch,
            Yoox: cart.Yoox,
            Dior: cart.Dior,
            MrPorter: cart.MrPorter,
            NetAPorter: cart.NetAPorter,
            Prada: cart.Prada,
          },
          viewCart,
        }
      }

      return state
    }
    //user data=============================================
    case UPDATE_USER_DATA: {
      const newUserData = action.payload as CheckUserResponseType

      return {
        ...state,
        userId: newUserData.userId,
        blocked: newUserData.blocked,
        userData: {
          ...state.userData,
          userName: newUserData.userName,
          birthdate: newUserData.birthdate,
          userEmail: newUserData.userEmail,
          userPhone: newUserData.userPhone,
          emailVerified: newUserData.emailVerified,
        },
      }
    }
    // case UPDATE_USER_PASSPORT: {
    //   const newUserPassport = action.payload as UserPassport
    //
    //   return {
    //     ...state,
    //     userPassport: {
    //       ...state.userPassport,
    //       ...newUserPassport,
    //     },
    //   }
    // }
    //cart==========================================
    case ADD_PRODUCT: {
      const productToAdd = action.payload as ProductType
      const brandKey = productToAdd.brandType

      if (!state.cart[brandKey]) {
        state.cart[brandKey] = []
      }

      const updatedCart = {
        ...state.cart,
        [brandKey]: [...state.cart[brandKey], productToAdd],
      }

      const productForViewCart = {
        productId: productToAdd.productId,
        productImg: productToAdd.productImg,
      }

      const updatedViewCart = Array.isArray(state.viewCart)
        ? [...state.viewCart, productForViewCart]
        : [productForViewCart]

      return {
        ...state,
        cart: updatedCart,
        viewCart: updatedViewCart,
      }
    }

    case EDIT_PRODUCT: {
      const editedProduct = action.payload as ProductType
      const { productId, brandType } = editedProduct
      const key = brandType as keyof CartType

      const updatedCart = state.cart[key].map(product =>
        product.productId === productId ? editedProduct : product
      )

      return {
        ...state,
        cart: {
          ...state.cart,
          [key]: updatedCart,
        },
      }
    }
    case REMOVE_PRODUCT: {
      const { productId, brandType } = action.payload as ProductType
      const key = brandType as keyof CartType

      const updatedCart = {
        ...state.cart,
        [key]: state.cart[key].filter(product => product.productId !== productId),
      }

      const updatedViewCart = state.viewCart.filter(product => product.productId !== productId)

      return {
        ...state,
        cart: updatedCart,
        viewCart: updatedViewCart,
      }
    }
    case CLEAR_CART: {
      localStorage.removeItem('cart')
      localStorage.removeItem('view-cart')

      return {
        ...state,
        cart: getEmptyCart(),
        viewCart: [],
      }
    }

    //address------------------------------------
    case SET_ADDRESSES: {
      return {
        ...state,
        addresses: action.payload as DeliveryAddress[],
      }
    }
    //--------------------------------------------

    case SET_ALL_ORDER_HISTORY: {
      return {
        ...state,
        history: {
          ...state.history,
          allOrderHistory: action.payload,
        },
      } as InitialStateType
    }
    case SET_ALL_ORDERS_COUNT: {
      return {
        ...state,
        history: {
          ...state.history,
          allOrdersCount: action.payload,
        },
      } as InitialStateType
    }
    case SET_RETURNED_ORDER: {
      return {
        ...state,
        history: {
          ...state.history,
          returnedOrder: action.payload,
        },
      } as InitialStateType
    }
    case SET_RETURNED_ORDERS_COUNT: {
      return {
        ...state,
        history: {
          ...state.history,
          returnedOrdersCount: action.payload,
        },
      } as InitialStateType
    }
    case SET_CURRENT_PAGE: {
      return {
        ...state,
        history: {
          ...state.history,
          currentPage: action.payload,
        },
      } as InitialStateType
    }
    case SET_PAGES_COUNT: {
      return {
        ...state,
        history: {
          ...state.history,
          pagesCount: action.payload,
        },
      } as InitialStateType
    }
    case SET_CURRENT_ORDER: {
      return {
        ...state,
        history: {
          ...state.history,
          currentOrder: action.payload,
        },
      } as InitialStateType
    }
    case SET_BONUSES: {
      return {
        ...state,
        bonuses: action.payload,
      } as InitialStateType
    }
    case SET_IS_USE_DISCOUNT: {
      return {
        ...state,
        isUseDiscount: action.payload,
      } as InitialStateType
    }
    case SET_TOTAL_RUB_SUM: {
      return {
        ...state,
        totalRubSum: action.payload,
      } as InitialStateType
    }
    case SET_DELIVERY_COST: {
      return {
        ...state,
        deliveryCost: action.payload,
      } as InitialStateType
    }
    case SET_SERVICE_FEE: {
      return {
        ...state,
        serviceFee: action.payload,
      } as InitialStateType
    }
    case SET_DELETED_PRODUCT: {
      return {
        ...state,
        deletedProduct: action.payload,
      } as InitialStateType
    }
    case SET_DISCOUNTED_CART: {
      return {
        ...state,
        cart: action.payload,
      } as InitialStateType
    }
    case SET_ORDERING_RESPONSE: {
      return {
        ...state,
        createdOrderId: (action.payload as OrderCreatedType).orderId,
        paymentUrl: (action.payload as OrderCreatedType).paymentUrl,
        cart: (action.payload as OrderCreatedType).cart,
        // cart: getCartWithGoods((action.payload as OrderCreatedType).cart),
      } as InitialStateType
    }
    case USER_LOGOUT: {
      return {
        ...initialState,
        viewCart: state.viewCart,
      }
    }
    case SET_PASSPORT_DATA: {
      return {
        ...state,
        userPassport: action.payload,
      } as InitialStateType
    }
    case SET_IS_VERIFIED_EMAIL: {
      return {
        ...state,
        userData: {
          ...state.userData,
          emailVerified: action.payload,
        },
      } as InitialStateType
    }
    default:
      return state
  }
}

import React from 'react'

import { useFormik } from 'formik'
import { useLocation } from 'react-router-dom'

import { useAppDispatch, useAppSelector } from '../../../../hooks/customHooks'
import { Button, Input } from '../../../../ui'

import styles from './AuthorizationModalsStyle.module.scss'

import { PATH } from 'app/routes/AppRoutes'
import { forgotPasswordTC, ModalType, selectedAppStatus, setRootModalDataAC } from 'app/store'
import { getValidScheme } from 'common'

type PropsType = {
  modalType: ModalType
}

export const RecoveryPasswordModal = ({ modalType }: PropsType) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const appStatus = useAppSelector(selectedAppStatus)

  const formik = useFormik({
    initialValues: {
      userEmail: '',
    },
    validationSchema: getValidScheme(modalType),
    onSubmit: values => {
      dispatch(forgotPasswordTC(values))
    },
  })

  const handleSubmit = () => {
    formik.handleSubmit()
  }
  let disabledButton = !formik.isValid || formik.values.userEmail.length === 0

  return (
    <div className={styles.wrapper}>
      <div className={styles.passwordRecoveryForm}>
        <form className={styles.modalBox}>
          <div className={styles.header}>
            <b>Введите ваш email-адрес, мы вышлем на него ссылку для восстановления доступа</b>
            {location.pathname !== PATH.ADMIN_PANEL && (
              <span
                onClick={() =>
                  dispatch(
                    setRootModalDataAC({ isOpen: true, modalType: 'login', direction: 'right' })
                  )
                }
              >
                войти в личный кабинет
              </span>
            )}
          </div>

          <div className={styles.content}>
            <form className={styles.form} autoComplete={'off'}>
              <Input
                name={'userEmail'}
                value={formik.values.userEmail}
                size={'base'}
                variant={'default'}
                label={'почта'}
                type={'email'}
                onChange={formik.handleChange}
                hasError={formik.touched.userEmail && !!formik.errors.userEmail}
                errorText={formik.errors.userEmail}
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                    handleSubmit()
                  }
                }}
                setFormikFieldValue={formik.setFieldTouched}
              />
            </form>

            <div className={styles.submitBtn}>
              <Button
                mod="fluid"
                size="large"
                variant="default"
                type="submit"
                onClick={handleSubmit}
                disabled={disabledButton}
                hasLoad={appStatus === 'loading'}
              >
                восстановить доступ
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

import { BasicResponse } from '../api-type'
import { instance } from '../instance'

import {
  AddTrackNumberPayloadType,
  ChangeBuyerOrderStatusPayloadType,
  EditTrackNumberPayloadType,
  GetOrdersPayloadType,
  MyOrdersType,
} from 'app/store'
import { BuyerOrderType } from 'app/store/buyerReducer/types'

export const buyerOrdersAPI = {
  getOrderList(payload: GetOrdersPayloadType) {
    return instance.get<GetOrdersResponseType>('/buyer/orders', { params: payload })
  },
  getMyOrders(payload: GetOrdersPayloadType) {
    return instance.get<GetOrdersResponseType>('/buyer/my-orders', { params: payload })
  },
  getCurrentOrder(orderId: number) {
    return instance.get<CurrentOrderResponseType>(`/buyer/orders/id`, { params: { orderId } })
  },
  changeOrderStatus(payload: ChangeBuyerOrderStatusPayloadType) {
    return instance.put<BasicResponse>('/buyer/orders/status', payload)
  },
  addTrackNumber(payload: AddTrackNumberPayloadType) {
    return instance.post<'', AddTrackNumberResponseType, AddTrackNumberPayloadType>(
      '/buyer/tracking-number',
      payload
    )
  },
  editTrackNumber(payload: EditTrackNumberPayloadType) {
    return instance.patch<'', BasicResponse, EditTrackNumberPayloadType>(
      '/buyer/tracking-number',
      payload
    )
  },
  deleteTrackNumber(trackNumId: number) {
    return instance.delete<BasicResponse>(`/buyer/tracking-number?trackNumId=${trackNumId}`)
  },
  addFile(orderId: number, formData: FormData) {
    return instance.post<AddFileResponseType>(`/buyer/photo`, formData, {
      params: { orderId, image: formData.get('image') },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  deleteFile(fileId: number) {
    return instance.delete<BasicResponse>(`/buyer/photo?fileId=${fileId}`)
  },
}

// types ============================================================
export type GetOrdersResponseType = BasicResponse & {
  revenue: number
  orders: {
    current_page: number
    last_page: number // используем как общее количество страниц (pagesCount)
    per_page: number // кол-во элементов на стр (pageSize)
    total: number // кол-во заказов с фильтром/поиском
    data: MyOrdersType[]
  }
}

export type CurrentOrderResponseType = BasicResponse & BuyerOrderType

export type AddTrackNumberResponseType = BasicResponse & {
  trackId: number
}
export type AddFileResponseType = BasicResponse & {
  fileId: number
  filePath: string
  fileName: string
}

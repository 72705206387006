import { AxiosError } from 'axios'

import { setAppErrorAC, setAppStatusAC, setAppSuccessAC } from '../appReducer/actions'
import { AppThunkType } from '../types'

import { setIsVerifiedEmailAC } from './actions'

import { authAPI, ConfirmEmailPayloadType } from 'api/userAPI/user-authorization-api'
import { errorUtils } from 'common'

export const confirmEmailTC =
  (payload: ConfirmEmailPayloadType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await authAPI.confirmEmail(payload)

      if (response.data.statusCode === 0) {
        dispatch(setIsVerifiedEmailAC(true))
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
      } else {
        dispatch(setIsVerifiedEmailAC(false))
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      dispatch(setIsVerifiedEmailAC(false))

      errorUtils(err, dispatch)
      dispatch(setAppStatusAC('failed'))
    }
  }

export const sendConfirmationEmailTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const response = await authAPI.sendConfirmationEmail()

    if (response.data.statusCode === 0) {
      dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
      dispatch(setAppStatusAC('succeeded'))
    } else {
      dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      dispatch(setAppStatusAC('failed'))
    }
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

import { AxiosError } from 'axios'

import { setCurrentPageAC } from '../adminReducer/actions'
import { checkAdminAuthTC } from '../adminReducer/thanks'
import { PhoneNumbersType } from '../adminReducer/types'
import {
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import { AppRootState, AppThunkType } from '../types'

import {
  setBuyerCurrentPageAC,
  setBuyerOrderAC,
  setBuyerPagesCountAC,
  setBuyerPageSizeAC,
  setBuyerRevenueAC,
  setMyOrdersAC,
  setMyOrdersCountAC,
  setOrderListAC,
  setOrderListCountAC,
} from './actions'
import {
  AddTrackNumberPayloadType,
  ChangeBuyerOrderStatusPayloadType,
  EditTrackNumberPayloadType,
  GetOrdersPayloadType,
} from './thunk-types'
import { BuyerOrderType, MyOrdersType, OrderListType } from './types'

import { buyerOrdersAPI } from 'api/buyerAPI/buyer-order-api'
import { errorUtils, formatPhoneNumber } from 'common'

export const getOrderListTC =
  (payload: GetOrdersPayloadType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.getOrderList(payload)

      if (response.data.statusCode === 0) {
        const orders: OrderListType[] = response.data.orders.data.map((order: OrderListType) => {
          return {
            ...order,
            warehouse: {
              ...order.warehouse,
              numbers: order.warehouse.numbers.map((num: PhoneNumbersType) => {
                return {
                  ...num,
                  telNum: formatPhoneNumber(num.telNum, true),
                }
              }),
            },
          }
        })

        dispatch(setOrderListAC(orders))
        dispatch(setOrderListCountAC(response.data.orders.total))
        dispatch(setBuyerCurrentPageAC(response.data.orders.current_page))
        dispatch(setBuyerPagesCountAC(response.data.orders.last_page))
        dispatch(setBuyerPageSizeAC(response.data.orders.per_page))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getMyOrdersTC =
  (payload: GetOrdersPayloadType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.getMyOrders(payload)

      if (response.data.statusCode === 0) {
        const orders: MyOrdersType[] = response.data.orders.data.map((order: MyOrdersType) => {
          return {
            ...order,
            warehouse: order.warehouse
              ? {
                  ...order.warehouse,
                  numbers: order.warehouse.numbers.map((num: PhoneNumbersType) => {
                    return {
                      ...num,
                      telNum: formatPhoneNumber(num.telNum, true),
                    }
                  }),
                }
              : order.warehouse,
          }
        })

        dispatch(setMyOrdersAC(orders))
        dispatch(setMyOrdersCountAC(response.data.orders.total))
        dispatch(setBuyerCurrentPageAC(response.data.orders.current_page))
        dispatch(setCurrentPageAC(response.data.orders.current_page)) // for pagination in admin panel ( admin - 'my orders' )
        dispatch(setBuyerPagesCountAC(response.data.orders.last_page))
        dispatch(setBuyerPageSizeAC(response.data.orders.per_page))
        dispatch(setBuyerRevenueAC(response.data.revenue))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const getBuyerOrderTC =
  (orderId: number): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.getCurrentOrder(orderId)

      if (response.data.statusCode === 0) {
        const order: BuyerOrderType = {
          ...response.data,
          warehouse: {
            ...response.data.warehouse,
            numbers: response.data.warehouse.numbers.map((num: PhoneNumbersType) => {
              return {
                ...num,
                telNum: formatPhoneNumber(num.telNum, true),
              }
            }),
          },
        }

        dispatch(setBuyerOrderAC(order))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const changeBuyerOrderStatusTC =
  (payload: ChangeBuyerOrderStatusPayloadType): AppThunkType =>
  async (dispatch, getState: () => AppRootState) => {
    const pageSize = getState().buyer.pageSize

    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.changeOrderStatus(payload)

      if (response.data.statusCode === 0) {
        if (payload.orderStatus === 'TakenByBuyer') {
          dispatch(getBuyerOrderTC(payload.orderId))
          dispatch(getOrderListTC({ page: 1, pageSize }))
        }
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
        dispatch(setAppStatusAC('failed'))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)

      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

//track numbers ===================================================================================================

export const addTrackNumberTC =
  (payload: AddTrackNumberPayloadType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))

    try {
      const response = await buyerOrdersAPI.addTrackNumber(payload)

      if (response.statusCode === 0) {
        // dispatch(getBuyerOrderTC(payload.orderId))
        // dispatch(setRootModalDataAC({ isOpen: false, modalType: null , direction: null}))
        // dispatch(setAppSuccessAC({ type: 'success', title: 'Трек номер добавлен' }))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)

      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

export const editTrackNumberTC =
  (payload: EditTrackNumberPayloadType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.editTrackNumber(payload)

      if (response.statusCode === 0) {
        // dispatch(getBuyerOrderTC(payload.orderId))
        // dispatch(setAppSuccessAC({ type: 'success', title: 'Трек номер изменен' }))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(setAppStatusAC('failed'))
    }
  }

export const deleteTrackNumberTC =
  (trackId: number, orderId: number): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.deleteTrackNumber(trackId)

      if (response.data.statusCode === 0) {
        dispatch(getBuyerOrderTC(orderId))
        dispatch(setAppSuccessAC({ type: 'success', title: 'трек номер удален' }))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

//===================================================================================================

//files ===================================================================================================
export const addFileTC =
  (orderId: number, formData: FormData): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.addFile(orderId, formData)

      if (response.data.statusCode === 0) {
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    } finally {
      dispatch(getBuyerOrderTC(orderId))
    }
  }

export const deleteFileTC =
  (orderId: number, fileId: number): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.deleteFile(fileId)

      if (response.data.statusCode === 0) {
        dispatch(getBuyerOrderTC(orderId))
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }
//===================================================================================================

export const returnBuyerOrderTC =
  (payload: ChangeBuyerOrderStatusPayloadType): AppThunkType =>
  async (dispatch, getState: () => AppRootState) => {
    const pageSize = getState().buyer.pageSize

    dispatch(setAppStatusAC('loading'))
    try {
      const response = await buyerOrdersAPI.changeOrderStatus(payload)

      if (response.data.statusCode === 0) {
        dispatch(getBuyerOrderTC(payload.orderId))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        dispatch(setAppSuccessAC({ type: 'success', title: 'заказ возвращен в общий пул' }))
        dispatch(getMyOrdersTC({ page: 1, pageSize }))
      }
      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

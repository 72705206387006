import { AxiosError } from 'axios'

import {
  setAdminPanelTabAC,
  setAppErrorAC,
  setAppStatusAC,
  setAppSuccessAC,
  setBuyerAccountTabAC,
  setForgotPasswordStatusAC,
  setRootModalDataAC,
} from '../appReducer/actions'
import {
  buyerLogoutAC,
  setBuyerDataAC,
  setMyOrdersCountAC,
  setOrderListCountAC,
} from '../buyerReducer/actions'
import { clearFilterAC } from '../filterReducer/actions'
import { AppThunkType } from '../types'

import {
  adminLogoutAC,
  setAdminDataAC,
  setTotalEmployeeCountAC,
  setTotalInPaymentCountAC,
  setTotalOrdersCountAC,
} from './actions'
import { UserDataType } from './types'

import { adminAuthAPI, AdminLoginRequestType } from 'api/adminAPI/adminAuthAPI'
import {
  checkTokenInCookie,
  removeTokenFromCookie,
  setBearerTokenToCookie,
} from 'api/cookieWorkers'
import { authAPI, setNewPasswordType } from 'api/userAPI/user-authorization-api'
import { errorUtils } from 'common'

//login =========================================================================
export const adminLoginTC =
  (payload: AdminLoginRequestType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    if (checkTokenInCookie('BearerToken')) {
      removeTokenFromCookie('BearerToken', '/admin-panel')
    }

    try {
      const userData: UserDataType = {
        userId: null,
        userFullName: null,
        role: null,
        blocked: false,
      }
      const response = await adminAuthAPI.login(payload)

      if (response.data.statusCode === 0) {
        setBearerTokenToCookie(response.data.token, '/admin-panel')
        dispatch(clearFilterAC())

        userData.userId = response.data.userId
        userData.userFullName = response.data.userFullName
        userData.role = response.data.role

        if (response.data.role === 'Buyer') {
          dispatch(setBuyerDataAC(userData))
          dispatch(setOrderListCountAC(response.data.orderListCount))
          dispatch(setMyOrdersCountAC(response.data.myOrdersCount))
          dispatch(setBuyerAccountTabAC('ordersList'))
        }
        if (response.data.role === 'Admin' || response.data.role === 'Manager') {
          dispatch(setAdminDataAC(userData))
          dispatch(setAdminPanelTabAC('orders'))
          dispatch(setTotalOrdersCountAC(response.data.totalOrdersCount))
          dispatch(setTotalEmployeeCountAC(response.data.totalUsersCount))
          dispatch(setTotalInPaymentCountAC(response.data.totalInPaymentCount))
        }
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      if (response.data.statusCode === 2) {
        userData.blocked = true

        removeTokenFromCookie('BearerToken', '/admin-panel')
        dispatch(setBuyerDataAC(userData))
        dispatch(setAdminDataAC(userData))
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      }

      dispatch(setAppStatusAC('succeeded'))
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(setAppStatusAC('failed'))
    }
  }

export const adminLogoutTC = (): AppThunkType => async dispatch => {
  try {
    dispatch(setAppStatusAC('loading'))
    const response = await authAPI.logout()

    if (response.data.statusCode === 0) {
      removeTokenFromCookie('BearerToken', '/admin-panel')
      dispatch(adminLogoutAC())
      dispatch(buyerLogoutAC())
      dispatch(setAppStatusAC('succeeded'))
    } else {
      dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))
      dispatch(setAppStatusAC('failed'))
    }
  } catch (e) {
    const err = e as Error | AxiosError<{ error: string }>

    errorUtils(err, dispatch)
    dispatch(checkAdminAuthTC())
    dispatch(setAppStatusAC('failed'))
  }
}

export const checkAdminAuthTC = (): AppThunkType => async dispatch => {
  dispatch(setAppStatusAC('loading'))
  try {
    const userData: UserDataType = {
      userId: null,
      userFullName: null,
      role: null,
      blocked: false,
    }

    const response = await authAPI.checkUserAuth()

    if (response.data.statusCode === 0) {
      userData.userId = response.data.userId
      userData.userFullName = response.data.userName
      userData.role = response.data.userRole

      if (response.data.userRole === 'Buyer') {
        dispatch(setBuyerDataAC(userData))
      }

      if (response.data.userRole === 'Admin' || response.data.userRole === 'Manager') {
        dispatch(setAdminDataAC(userData))
      }
    } else if (response.data.statusCode === 2) {
      userData.blocked = true

      removeTokenFromCookie('BearerToken', '/admin-panel')
      dispatch(setBuyerDataAC(userData))
      dispatch(setAdminDataAC(userData))
    } else if (response.data.statusCode === 1) {
      removeTokenFromCookie('BearerToken', '/admin-panel')
      dispatch(adminLogoutAC())
      dispatch(buyerLogoutAC())
    }

    dispatch(setAppStatusAC('succeeded'))
  } catch (e) {
    // const err = e as Error | AxiosError<{ error: string }>
    // errorUtils(err, dispatch)
    dispatch(setAppStatusAC('failed'))
  }
}

//=========================================================================

export const forgotPasswordTC =
  (payload: { userEmail: string }): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await authAPI.forgotPassword(payload)

      if (response.data.statusCode === 0) {
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))

        dispatch(setForgotPasswordStatusAC('emailSent'))
        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        dispatch(setAppStatusAC('succeeded'))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
        dispatch(setAppStatusAC('failed'))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setRootModalDataAC({ isOpen: false, modalType: null, direction: null }))
      dispatch(setAppStatusAC('failed'))
    }
  }

export const setNewPasswordTC =
  (data: setNewPasswordType): AppThunkType =>
  async dispatch => {
    dispatch(setAppStatusAC('loading'))
    try {
      const response = await authAPI.setNewPassword(data)

      if (response.data.statusCode === 0) {
        dispatch(setAppSuccessAC({ type: 'success', title: response.data.message }))

        dispatch(setForgotPasswordStatusAC('newPasswordSet'))
        dispatch(setAppStatusAC('succeeded'))
      } else {
        dispatch(setAppErrorAC({ type: 'error', title: response.data.message }))

        dispatch(setAppStatusAC('failed'))
      }
    } catch (e) {
      const err = e as Error | AxiosError<{ error: string }>

      errorUtils(err, dispatch)
      dispatch(checkAdminAuthTC())
      dispatch(setAppStatusAC('failed'))
    }
  }

import {
  clearFilterAC,
  setAccountIdToChangeAC,
  setFilterAccountStatusAC,
  setFilteredGenderAC,
  setFilteredUserRoleAC,
  setFilteredUsersAgesAC,
  setFilteredUsersCharacteristicsAC,
  setFilteredUsersPricesAC,
  setFromDateAC,
  setSearchValueAC,
  setSelectedAccountIdAC,
  setSelectedOrderIdAC,
  setSelectStatusAC,
  setStatusForSearchAC,
  setToDateAC,
  setUsersByCharacteristicsAC,
  setUsersByRoleAC,
} from './actions'

export const SET_SEARCH_VALUE = 'filter/SET-SEARCH-VALUE'
export const SET_SELECTED_STATUS = 'filter/SET-SELECTED-STATUS'
export const SET_SELECTED_ORDER_ID = 'filter/SET-SELECTED-ORDER-ID'
export const SET_FILTERED_USER_ROLE = 'filter/SET-FILTERED-USER-ROLE'
export const SET_FILTER_ACCOUNT_STATUS = 'filter/SET-FILTER-ACCOUNT-STATUS'
export const SET_STATUS_FOR_SEARCH = 'filter/SET-STATUS-FOR-SEARCH'
export const SET_SELECTED_ACCOUNT_ID = 'filter/SET-SELECTED-ACCOUNT-ID'
export const SET_ACCOUNT_ID_TO_CHANGE = 'filter/SET-ACCOUNT-ID-TO-CHANGE'
export const SET_FROM_DATE = 'filter/SET-FROM-DATE'
export const SET_TO_DATE = 'filter/SET-TO-DATE'
export const SET_SELECTED_DATE_CHANGED = 'filter/SET-SELECTED-DATE-CHANGED'
export const SET_FILTERED_GENDER = 'filter/SET_FILTERED_GENDER'
export const SET_FILTERED_USERS_AGES = 'filter/SET_FILTERED_USERS_AGES'
export const SET_FILTERED_USERS_PRICES = 'filter/SET_FILTERED_USERS_PRICES'
export const SET_USERS_BY_ROLE = 'filter/SET_USERS_BY_ROLE'
export const SET_USERS_BY_CHARACTERISTICS = 'filter/SET_USERS_BY_CHARACTERISTICS'
export const SET_FILTERED_USERS_CHARACTERISTICS = 'filter/SET_FILTERED_USERS_BY_CHARACTERISTICS'
export const CLEAR_FILTER = 'filter/CLEAR-FILTER'

export type FilterActionsType =
  | ReturnType<typeof setSearchValueAC>
  | ReturnType<typeof setSelectStatusAC>
  | ReturnType<typeof setSelectedOrderIdAC>
  | ReturnType<typeof setFilteredUserRoleAC>
  | ReturnType<typeof setFilterAccountStatusAC>
  | ReturnType<typeof setStatusForSearchAC>
  | ReturnType<typeof setSelectedAccountIdAC>
  | ReturnType<typeof setAccountIdToChangeAC>
  | ReturnType<typeof setFromDateAC>
  | ReturnType<typeof setToDateAC>
  // | ReturnType<typeof setSelectedDateChangedAC>
  | ReturnType<typeof setFilteredGenderAC>
  | ReturnType<typeof setFilteredUsersAgesAC>
  | ReturnType<typeof setFilteredUsersPricesAC>
  | ReturnType<typeof setUsersByRoleAC>
  | ReturnType<typeof setUsersByCharacteristicsAC>
  | ReturnType<typeof setFilteredUsersCharacteristicsAC>
  | ReturnType<typeof clearFilterAC>

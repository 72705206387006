import React, { MouseEvent } from 'react'

import styles from './AccountStatusHandler.module.scss'

import { ReactComponent as Arrow } from 'assets/icons/svgIcons/arrowDownIcon.svg'
import { statusIdentifier } from 'common'
import { PopupCheckBoxType } from 'types/popup-types'

type AccountStatusHandlerPropsType = {
  isBlocked: boolean
  userId?: number
  selectedAccountId?: number | null
  className?: string
  openCheckboxHandler: (
    e: MouseEvent<HTMLDivElement>,
    type: PopupCheckBoxType,
    id: number | null
  ) => void
}
export const AccountStatusHandler = (props: AccountStatusHandlerPropsType) => {
  const { isBlocked, userId, selectedAccountId, className, openCheckboxHandler } = props

  const accountStatus = isBlocked ? 'blocked' : 'active'

  const currentStatus = statusIdentifier(accountStatus)

  let isActive = false

  if (userId && selectedAccountId) {
    isActive = userId === selectedAccountId
  }

  const containerClassName = `${className}  ${styles.container} ${isActive ? styles.active : ''}`

  return (
    <div
      className={containerClassName}
      onClick={e => openCheckboxHandler(e, 'setAccountStatus', userId ?? null)}
    >
      <div className={styles.color} style={{ background: currentStatus.background }} />
      <span>{currentStatus.name}</span>
      <Arrow className={`${styles.icon} ${isActive ? styles['is-active'] : ''}`} />
    </div>
  )
}

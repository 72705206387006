import styles from './AddressItem.module.scss'

import { ReactComponent as EditIcon } from 'assets/icons/svgIcons/editAdminUser.svg'
import { Checkbox } from 'common/ui'
import DeliveryAddress from 'types/delivery-address'

type Props = {
  address: DeliveryAddress
  setDefaultAddress: (address: DeliveryAddress) => void
  updateAddress: (address: DeliveryAddress) => void
}
export const AddressItem = (props: Props) => {
  const { address, setDefaultAddress, updateAddress } = props

  const setDefaultAddressHandler = (value: boolean) => {
    setDefaultAddress({ ...address, isDefaultAddress: value })
  }

  return (
    <div className={styles.block}>
      <div className={styles.main}>
        <div className={styles.address}>
          <h5 className={styles.title}>
            {address.address ?? 'Адрес не указан'}
            <EditIcon onClick={() => updateAddress(address)} />
          </h5>
          <p>
            {address.entrance ? `подъезд ${address.entrance}, ` : ''}
            {address.flat ? `квартира ${address.flat}, ` : ''}
            {address.floor ? `этаж ${address.floor}` : ''}
          </p>
        </div>

        <div className={styles.actions}>
          {address.isDefaultAddress && <div className={styles.badge}>по умолчанию</div>}

          <Checkbox
            id={address.addressId}
            value={address.isDefaultAddress}
            onChange={value => setDefaultAddressHandler(value)}
          />
        </div>
      </div>

      <div className={styles.mobileActions}>
        <div className={styles.edit}>
          <EditIcon className={styles.editIcon} onClick={() => updateAddress(address)} />
          редактировать
        </div>

        {address.isDefaultAddress && <div className={styles.mobileBadge}>по умолчанию</div>}
      </div>
    </div>
  )
}
